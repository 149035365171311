import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "timeInput",
    "hoursInput",
    "departmentInput",
    "startTime",
    "finishTime",
    "timeTotal",
    "hours",
    "hoursText",
    "useHours",
    "useTimes",
  ]
  static values = { viewInDays: Boolean, avgDayLength: Number }

  handleEditHoursToggle(event) {
    this.toggleTimeMethod(event.params.method)
  }

  toggleTimeMethod(method) {
    if (method === "times") {
      this.hideElement(this.hoursInputTarget)
      this.showElement(this.timeInputTarget)
      this.hideElement(this.useTimesTarget)
      this.showElement(this.useHoursTarget)
      this.showElement(this.hoursTextTarget)
      this.startTimeTarget.removeAttribute("disabled")
      this.finishTimeTarget.removeAttribute("disabled")
      this.hoursTarget.setAttribute("readonly", true)
      this.calculateHours()
    } else {
      this.hideElement(this.timeInputTarget)
      this.showElement(this.hoursInputTarget)
      this.hideElement(this.useHoursTarget)
      this.showElement(this.useTimesTarget)
      this.hideElement(this.hoursTextTarget)
      this.startTimeTarget.setAttribute("disabled", true)
      this.finishTimeTarget.setAttribute("disabled", true)
      this.hoursTarget.removeAttribute("readonly")
    }
  }

  hideElement(target) {
    target.classList.add("hidden")
    target.classList.add("disabled")
  }

  showElement(target) {
    target.classList.remove("hidden")
    target.classList.remove("disabled")
  }

  calculateHours() {
    if (!this.startTimeTarget.value || !this.finishTimeTarget.value) {
      this.hoursTarget.value = "0"
      this.timeTotalTarget.innerText = "0"
      return
    }

    const startTime = new Date()
    startTime.setHours(this.startTimeTarget.value.split(":")[0])
    startTime.setMinutes(this.startTimeTarget.value.split(":")[1])
    const finishTime = new Date()
    finishTime.setHours(this.finishTimeTarget.value.split(":")[0])
    finishTime.setMinutes(this.finishTimeTarget.value.split(":")[1])

    const millisecondsInHour = 3600000.0

    let differenceInHours = (finishTime - startTime) / millisecondsInHour
    if (this.startTimeTarget.value > this.finishTimeTarget.value) {
      differenceInHours += 24.0
    }

    if (Number.isNaN(differenceInHours)) {
      return
    }

    if (this.viewInDaysValue) {
      const differenceInDays = differenceInHours / this.avgDayLengthValue
      this.hoursTarget.value = differenceInDays.toFixed(2).toString()
    } else {
      this.hoursTarget.value = differenceInHours.toFixed(2).toString()
    }

    this.timeTotalTarget.innerText = this.hoursTarget.value
  }
}
