import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.passwordValidation = document.getElementById("password-check") // wrapper
    this.confirmValidation = document.getElementById("confirm-password-check") // wrapper

    this.passwordInput = document.getElementById("password") // the password user input
    this.confirmInput = document.getElementById("confirm") // the confirm password user input

    this.pwValid = document.getElementById("password-valid-icon")
    this.confirmValid = document.getElementById("confirm-valid-icon")

    this.pwText = document.getElementById("password-check-text")
    this.confirmText = document.getElementById("confirm-password-check-text")

    this.hide(this.passwordValidation)
    this.hide(this.confirmValidation)
    this.hide(this.pwValid)
    this.hide(this.confirmValid)
  }

  validatePassword() {
    // if there is NOT user input already in the password input field
    if (this.passwordInput.value.length === 0) {
      // hide the password validation wrapper
      this.hide(this.passwordValidation)
    } else {
      this.show(this.passwordValidation)
      if (this.passwordInput.value.length >= 9) {
        this.pwInvalid = document.getElementById("password-invalid-icon")

        this.hide(this.pwInvalid)
        this.show(this.pwValid)
        this.useGreen(this.pwText)
      } else {
        // in case they backspace to less than 9 characters
        this.show(this.pwInvalid)
        this.hide(this.pwValid)
        this.useRed(this.pwText)
      }
    }
  }

  validateConfirmationPassword() {
    // if there is NOT user input already in the confirmation input field
    if (this.confirmInput.value.length === 0) {
      // hide the confirmation validation wrapper
      this.hide(this.confirmValidation)
    } else {
      this.show(this.confirmValidation)
      if (this.confirmInput.value === this.passwordInput.value) {
        this.confirmInvalid = document.getElementById("confirm-invalid-icon")

        this.hide(this.confirmInvalid)
        this.show(this.confirmValid)
        this.useGreen(this.confirmText)
      } else {
        // in case they edit the confirmation input or password input which makes them no longer match
        this.show(this.confirmInvalid)
        this.hide(this.confirmValid)
        this.useRed(this.confirmText)
      }
    }
  }

  show(element) {
    element.classList.remove("hidden")
    element.classList.display = "block"
  }

  hide(element) {
    element.classList.add("hidden")
    element.classList.display = "none"
  }

  useRed(element) {
    element.classList.remove("text-green-500")
    element.classList.add("text-red-500")
  }

  useGreen(element) {
    element.classList.remove("text-red-500")
    element.classList.add("text-green-500")
  }
}
