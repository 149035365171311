import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["leaveLink"]

  connect() {
    this.appendLeaveFiltersToLink()
  }

  appendLeaveFiltersToLink() {
    setTimeout(() => {
      const leaveParams = localStorage.getItem("leave_filters")
      if (leaveParams) {
        this.leaveLinkTargets.forEach((link) => {
          link.href = `${link.href}?${leaveParams}`
        })
      }
    }, 250)
  }
}
