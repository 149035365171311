import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"
import Request from "helpers/request"

/*
  - Submits a password reset (to `resetPasswordPath`) to all regions provided by `baseUrls`
  - On successful submission, navigates to `loginPath`
*/

export default class extends Controller {
  static targets = ["form", "email"]

  static values = {
    baseUrls: Array,
    loginPath: String,
    resetPasswordPath: String,
  }

  resetPassword = (urlBase) =>
    Request.post(new URL(this.resetPasswordPathValue, urlBase), new FormData(this.formTarget)).catch(() => {}) // we do not care if user is not in region

  attemptSubmit = (e) => {
    e.preventDefault()

    // update submit button
    if (e.target.dataset.disableWith) {
      e.target.disabled = true
      e.target.value = e.target.dataset.disableWith
    }

    // Submit all reset password requests then go to login path
    Promise.all(this.baseUrlsValue.map(this.resetPassword)).then(() => {
      Turbo.visit(this.loginPathValue)
    })
  }
}
