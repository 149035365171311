import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    original: String,
  }
  static targets = ["buttonWrapper"]

  emailChanged(event) {
    if (event.target.value === this.originalValue) {
      this.enableEmailFunctionality()
    } else {
      this.disableEmailFunctionality()
    }
  }

  disableEmailFunctionality() {
    this.buttonWrapperTargets.forEach((wrapper) => {
      const disabledText = wrapper.dataset.buttonDisabledText
      const link = wrapper.querySelector("a")
      link.classList.add("cursor-not-allowed", "opacity-50")
      link.href = "javascript:void(0)"
      link.innerText = disabledText
    })
  }

  enableEmailFunctionality() {
    this.buttonWrapperTargets.forEach((wrapper) => {
      const originalText = wrapper.dataset.buttonText
      const link = wrapper.querySelector("a")
      link.classList.remove("cursor-not-allowed", "opacity-50")
      link.href = link.dataset.href
      link.innerText = originalText
    })
  }
}
