import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    condition: String,
    hideToggle: {
      type: Boolean,
      default: false,
    },
  }
  static targets = ["content"]

  toggle(event) {
    if (this.hideToggleValue) {
      event.target.style.display = "none"
    }
    this.contentTargets.forEach((t) => {
      const isHidden = t.style.display === "none"
      t.style.display = isHidden ? "" : "none"
      this.dispatch(isHidden ? "shown" : "hidden", { detail: { element: t } })
    })
    event.currentTarget.setAttribute &&
      event.currentTarget.setAttribute("aria-pressed", this.contentTarget.style.display !== "none")
  }

  show(event) {
    this.contentTargets.forEach((t) => {
      t.style.display = ""
      this.dispatch("shown", { detail: { element: t } })
    })
    event.currentTarget.setAttribute && event.currentTarget.setAttribute("aria-pressed", true)
  }

  hide(event) {
    this.contentTargets.forEach((t) => {
      t.style.display = "none"
      this.dispatch("hidden", { detail: { element: t } })
    })
    event.currentTarget.setAttribute && event.currentTarget.setAttribute("aria-pressed", false)
  }

  showIfGreaterThan(event) {
    if (event.currentTarget.value > this.conditionValue) {
      this.toggle(event)
    }
  }

  showIfGreaterOrEqual(event) {
    if (event.currentTarget.value >= this.conditionValue) {
      this.toggle(event)
    }
  }

  showIfLessThan(event) {
    if (event.currentTarget.value && this.conditionValue && event.currentTarget.value < this.conditionValue) {
      this.show(event)
    } else {
      this.hide(event)
    }
  }

  showIfLessOrEqual(event) {
    if (event.currentTarget.value <= this.conditionValue) {
      this.toggle(event)
    }
  }
}
