import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "value"]

  connect() {
    this.showValue()
  }

  showValue() {
    this.valueTarget.innerText = this.inputTarget.value
  }
}
