import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    departments: Object,
    isNew: Boolean,
  }

  static targets = ["locationSelect", "teamSelect", "teamWrapper"]

  connect() {
    if (!this.isNewValue) {
      this.teamWrapperTarget.classList.remove("hidden")
    }
  }

  onLocationChange(event) {
    if (this.teamWrapperTarget.classList.contains("hidden")) {
      this.teamWrapperTarget.classList.remove("hidden")
    }
    const teams = this.departmentsValue[event.target.value]
    this.teamSelectTarget.length = 0
    this.teamSelectTarget.add(new Option("All Teams", "nil"))
    teams.forEach(([team, id]) => {
      this.teamSelectTarget.add(new Option(team, id))
    })
    this.teamSelectTarget.focus()
  }
}
