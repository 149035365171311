import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "modelInput", "hiddenForm", "hiddenInput"]

  selectAll(event) {
    event.preventDefault()
    const modelName = event.target.dataset.modelName
    const severity = event.target.dataset.severity

    this.hiddenInputTargets.forEach((input) => {
      if (input.name.startsWith(`organisation[workforce_report_pay_check_settings][${modelName}]`)) {
        input.value = severity
      }
    })

    this.hiddenFormTargets.forEach((form) => {
      if (form.querySelector(`[name^="organisation[workforce_report_pay_check_settings][${modelName}]"]`)) {
        form.requestSubmit()
      }
    })
  }

  submitForm(event) {
    this.formTarget.requestSubmit()
  }
}
