import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

// Programmatically requests a URL that is expected to return a Turbo Stream response.
// Executes the Turbo Stream response when it's received.
//
// Courtesy of https://github.com/hotwired/turbo/issues/34#issuecomment-821881266
export default class extends Controller {
  static targets = ["progress"]

  connect() {
    this.progress = 0
    const intervalID = setInterval(this.fetchProgress.bind(this), 3000)
    this.intervalID = intervalID
  }

  fetchProgress() {
    const url = this.progressTarget.dataset.url
    const loadedUrl = this.progressTarget.dataset.loadedUrl
    fetch(`${url}/check_status?cache_key=${this.progressTarget.dataset.cacheKey}`)
      .then((res) => res.json())
      .then((res) => {
        const data = res.data
        if (data === "processing_error") {
          clearInterval(this.intervalID)
          Turbo.visit(loadedUrl + "?processing_error=true")
        }
        const loadPercentage = parseInt(res.data)

        if (loadPercentage === 100) {
          clearInterval(this.intervalID)
          // set timeout to avoid race conditions
          setTimeout(() => {
            Turbo.visit(loadedUrl)
          }, 2000)
        }
      })
  }
}
