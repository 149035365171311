import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    visibleToEmployee: Boolean,
  }

  static targets = ["acknowledgementRequired"]

  connect() {
    if (this.visibleToEmployeeValue) {
      this.acknowledgementRequiredTarget.removeAttribute("disabled")
    } else {
      this.acknowledgementRequiredTarget.checked = false
      this.acknowledgementRequiredTarget.setAttribute("disabled", "true")
    }
  }

  onVisibilityChange(event) {
    if (event.target.checked) {
      this.acknowledgementRequiredTarget.removeAttribute("disabled")
    } else {
      this.acknowledgementRequiredTarget.checked = false
      this.acknowledgementRequiredTarget.setAttribute("disabled", "true")
    }
  }
}
