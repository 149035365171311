// To use this controller you need to:
// 1. Add "data-controller": "sort-params" to the wrapper element of the entire section (filters and items)
// 2. Add "data-sort-params-sort-column-param": <column name> to your submit button element
// 3. Add "data-sort-params-sort-direction-param": <sort direction> to your submit button element

/**
 * Usage:
 * This controller will create two hidden input fields sort_column and sort_direction on the form that the submit button belongs to.
 * The values for sort_column and sort_direction will be passed via the event params
 * The event params will be set from data-sort-params-sort-column-param and data-sort-params-sort-direction-param fields.
 * These appended search params will be submitted with the existing form params.
 */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Sort Params controller connected.", this.element)
  }

  addSortParams(event) {
    const sort_column_input = document.createElement("input")
    sort_column_input.setAttribute("type", "hidden")
    sort_column_input.setAttribute("form", event.target.form.id)
    sort_column_input.setAttribute("name", "sort_column")
    sort_column_input.setAttribute("value", event.params["sortColumn"])

    event.target.form.appendChild(sort_column_input)

    if (event.params["sortDirection"] !== undefined) {
      const sort_direction_input = document.createElement("input")
      sort_direction_input.setAttribute("type", "hidden")
      sort_direction_input.setAttribute("form", event.target.form.id)
      sort_direction_input.setAttribute("name", "sort_direction")
      sort_direction_input.setAttribute("value", event.params["sortDirection"])

      event.target.form.appendChild(sort_direction_input)
    }
  }
}
