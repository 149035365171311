import { Controller } from "@hotwired/stimulus"
import Request from "helpers/request"
import { parseHTMLFragment } from "helpers/dom"

export default class extends Controller {
  static targets = ["chats"]
  static values = { url: String }

  connect() {
    this.timeout = setTimeout(this.poll.bind(this), 30 * 1000)
  }

  disconnect() {
    clearTimeout(this.timeout)
    delete this.initialTitle
  }

  async poll() {
    const resp = await Request.get(this.urlValue)

    this.chatsTarget.replaceChildren(parseHTMLFragment(resp.data.chats))
    this.updateTitle(resp.data.unread_count)

    this.timeout = setTimeout(this.poll.bind(this), 30 * 1000)
  }

  updateTitle(count) {
    if (!this.initialTitle) {
      this.initialTitle = document.title
    }

    // It might be good if this was set on page load. To do this we'd want to lift this controller up (so it's not just sidebar)
    // and then pass in a value on load. I'm not sure if it's worth the extra complexity, at least until we get a dedicated layout file.
    // The only reason this is done here is to reuse the same fetch call already being used.
    if (count === 0) {
      document.title = this.initialTitle
    } else {
      document.title = `(${count}) ${this.initialTitle}`
    }
  }
}
