import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "reportingLocation", "payGroup"]
  static classes = ["field"]

  connect(event) {
    this.fieldTargets.forEach((t) => {
      if (t.checked) {
        if (t.value === "reporting_location") {
          this.reportingLocationTarget.classList.remove("hidden")
        } else if (t.value === "pay_group") {
          this.payGroupTarget.classList.remove("hidden")
        }
      }
    })
  }

  select(event) {
    this.fieldTargets.forEach((t) => t.classList.remove(...this.fieldClasses))
    event.currentTarget.classList.add(...this.fieldClasses)
    if (event.target.value === "reporting_location") {
      this.reportingLocationTarget.classList.remove("hidden")
      this.payGroupTarget.classList.add("hidden")
    } else if (event.target.value === "pay_group") {
      this.reportingLocationTarget.classList.add("hidden")
      this.payGroupTarget.classList.remove("hidden")
    } else {
      this.payGroupTarget.classList.add("hidden")
      this.reportingLocationTarget.classList.add("hidden")
    }
  }
}
