import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"
import * as Routes from "helpers/routes"

export default class extends Controller {
  static targets = ["form"]

  updateHours(e) {
    const formParams = new FormData(e.target.form)
    const queryString = new URLSearchParams(formParams).toString()
    fetch(`${Routes.leave_hours_update_leave_requests_path()}?${queryString}`, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }
}
