import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    itemType: String,
    condition: String,
  }

  static targets = ["inputTriggerValueWrapper"]

  item_types_with_text_input = ["characters", "number", "rating", "temperature", "number_slider"]

  connect() {
    if (this.item_types_with_text_input.includes(this.itemTypeValue)) {
      if (this.conditionValue !== "out_of_range") {
        this.inputTriggerValueWrapperTarget.classList.remove("hidden")
      } else {
        this.inputTriggerValueWrapperTarget.classList.add("hidden")
      }
    }
  }

  onConditionChange(event) {
    if (event.target.value !== "out_of_range") {
      this.inputTriggerValueWrapperTarget.classList.remove("hidden")
      this.inputTriggerValueWrapperTarget.attributes.required = true
    } else {
      this.inputTriggerValueWrapperTarget.classList.add("hidden")
      this.inputTriggerValueWrapperTarget.attributes.required = false
    }
  }
}
