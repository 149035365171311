import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

// Programmatically requests a URL that is expected to return a Turbo Stream response.
// Executes the Turbo Stream response when it's received.
//
// Courtesy of https://github.com/hotwired/turbo/issues/34#issuecomment-821881266
export default class extends Controller {
  static values = {
    src: String,
    method: { type: String, default: "GET" },
    body: Object,
    onConnect: { type: Boolean, default: true },
  }

  connect() {
    if (this.onConnectValue) {
      this.requestFrame()
    }
  }

  onConnectValueChanged(current, previous) {
    if (current && previous === false) {
      this.requestFrame()
    }
  }

  requestFrame() {
    const requestOptions = {
      method: this.methodValue,
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    }
    if (this.methodValue !== "GET" && this.hasBodyValue) {
      requestOptions.body = JSON.stringify(this.bodyValue)
      requestOptions.headers = {
        ...requestOptions.headers,
        "Content-Type": "application/json",
      }
    }
    fetch(this.srcValue, requestOptions)
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }
}
