import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image"]
  static values = {
    url: String,
  }

  connect() {
    this.imageTargets.forEach((image) => {
      // if the image fails to load, set the default image
      image.addEventListener("error", this.handleImageError.bind(this))
    })
  }

  handleImageError(event) {
    const image = event.target
    if (image.src !== this.urlValue) {
      image.src = this.urlValue
      image.removeEventListener("error", this.handleImageError)
    }
  }
}
