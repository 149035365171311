import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["limit", "container", "containerTemplate", "list", "item", "itemTemplate", "addButton"]
  static values = { sellingPoints: Array }

  get limit() {
    return parseInt(this.limitTarget.value) || 0
  }

  connect() {
    this.render()
  }

  render() {
    if (this.limit === 0 && this.sellingPointsValue.length === 0) {
      this.element.classList.add("hidden")
      if (this.hasContainerTarget) {
        this.containerTarget.remove()
      }
    } else {
      this.element.classList.remove("hidden")
      if (!this.hasContainerTarget) {
        this.element.appendChild(this.containerTemplateTarget.content.cloneNode(true))
      }
    }
    this.initializeSellingPoints()

    const existingItemCount = this.itemTargets.length

    if (this.limit < existingItemCount) {
      const extraItems = this.itemTargets.slice(this.limit)
      extraItems.forEach((item) => item.remove())
    }

    if (this.hasAddButtonTarget) {
      this.addButtonTarget.disabled = existingItemCount >= this.limit
    }
  }

  addSellingPoint() {
    if (this.itemTargets.length >= this.limit) return

    this.listTarget.appendChild(this.itemTemplateTarget.content.cloneNode(true))
    this.addButtonTarget.disabled = this.itemTargets.length >= this.limit
  }

  initializeSellingPoints() {
    if (this.sellingPointsValue.length === 0) return

    this.limitTarget.value = this.sellingPointsValue.length
    this.sellingPointsValue.forEach((sellingPoint) => {
      this.listTarget.appendChild(this.itemTemplateTarget.content.cloneNode(true))
      const item = this.itemTargets[this.itemTargets.length - 1]
      item.querySelector("input").value = sellingPoint
    })
  }

  deleteSellingPoint(ev) {
    ev.currentTarget.parentElement.remove()
    this.addButtonTarget.disabled = this.itemTargets.length >= this.limit
  }
}
