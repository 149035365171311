// @flow

/**
 * takes the user to a new location in tanda
 *
 * @param {string} url to visit
 * @param {Object} $1 options
 * @param {boolean} $1.force reload current location
 */
export const goto = (url: string, { force }: { force: boolean } = {}): void => {
  if (!force && isCurrentLocation(url)) {
    return
  }

  return window.location.assign(url)
}

/**
 * Helpers
 */

/**
 * checks if url is current location
 */
const isCurrentLocation = (url) =>
  window.location.href === url || window.location.pathname + window.location.search === url
