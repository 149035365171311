import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["name", "legalFirstName", "legalLastName"]

  connect() {
    this.initialNameValue = this.nameTarget.value
  }

  updateName() {
    if (this.initialNameValue !== "") return

    const legalFirstName = this.legalFirstNameTarget.value
    const legalLastName = this.legalLastNameTarget.value

    const fullName = [legalFirstName, legalLastName].filter((name) => name).join(" ")

    this.nameTarget.value = fullName
  }
}
