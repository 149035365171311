import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    idMapping: Object,
  }

  static targets = ["categoryField"]

  #previousSubCategory

  connect() {
    this.updateVisible()
  }

  updateVisible() {
    const category = this.categoryFieldTarget.value
    const subCategoryId = this.idMappingValue[category]

    if (this.#previousSubCategory) {
      this.#previousSubCategory.classList.add("hidden")
      this.#previousSubCategory.disabled = true
    }
    const subCategorySelect = document.getElementById(subCategoryId)

    if (subCategorySelect) {
      subCategorySelect.classList.remove("hidden")
      subCategorySelect.disabled = false
      this.#previousSubCategory = subCategorySelect
    }
  }
}
