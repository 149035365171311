import { Controller } from "@hotwired/stimulus"

export default class IFrameController extends Controller {
  connect() {
    this.tellParentWindowWhatOurHeightIs()
  }

  static dismiss() {
    window.parent.postMessage({ dismissIframe: true })
  }

  tellParentWindowWhatOurHeightIs() {
    window.parent.postMessage({ height: document.body.offsetHeight }, "*")
  }

  handleCloseButton(event) {
    if (event.target.matches(".close-btn")) {
      IFrameController.dismiss()
    }
  }
}
