import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = ["accordion"]

  toggle() {
    if (this.hasAccordionOutlet) {
      this.accordionOutlet.toggle()
    }
  }
}
