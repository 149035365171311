import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["incomeType", "taxScaleType", "homeCountry", "tfn", "tfnExemption", "seniorMaritalStatus"]

  toggletfnFields(event) {
    if (event.target.value === "true") {
      this.tfnExemptionTarget.classList.add("hidden")
      this.tfnTarget.classList.remove("hidden")
    } else {
      this.tfnExemptionTarget.classList.remove("hidden")
      this.tfnTarget.classList.add("hidden")
    }
  }

  toggleTaxFields(event) {
    if (
      event.currentTarget.name === this.incomeTypeTarget.name ||
      event.currentTarget.name === this.taxScaleTypeTarget.name
    ) {
      if (event.target.value === "working_holiday_maker") {
        this.homeCountryTarget.classList.remove("hidden")
        this.incomeTypeTargets.map((target) => {
          if (target.value === "working_holiday_maker") {
            target.checked = true
          }
        })
        this.taxScaleTypeTargets.map((target) => {
          if (target.value === "working_holiday_maker") {
            target.checked = true
          }
        })
      } else {
        this.homeCountryTarget.classList.add("hidden")
      }
      if (event.currentTarget.name === this.taxScaleTypeTarget.name) {
        if (event.target.value === "senior_or_pensioner") {
          this.seniorMaritalStatusTarget.classList.remove("hidden")
        } else {
          this.seniorMaritalStatusTarget.classList.add("hidden")
        }
      }
    }
  }
}
