import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "selectAll", "count", "submission"]

  connect() {
    this.updateCount()
  }

  toggleAll() {
    const isChecked = this.selectAllTarget.checked
    this.checkboxTargets.forEach((checkbox) => {
      if (!checkbox.disabled) {
        checkbox.checked = isChecked
      }
    })
    this.updateCount()
  }

  updateCount() {
    const selectedCount = this.checkboxTargets.filter((checkbox) => checkbox.checked).length
    this.countTarget.textContent = selectedCount
  }

  requestSubmit(e) {
    e.preventDefault()
    e.target.form.submit()
  }

  stopEnterSubmit(e) {
    if (e.type === "keydown" && e.keyCode === 13) {
      e.preventDefault()
    }
  }
}
