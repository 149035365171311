import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "dateInputs",
    "selectInputs",
    "dateFilter",
    "dateInputsStart",
    "dateInputsFinish",
    "payPeriodFilter",
  ]

  showSelectInputs() {
    if (this.selectInputsTarget) {
      this.selectInputsTarget.classList.remove("hidden")
      this.selectInputsTarget.removeAttribute("disabled")
    }
    if (this.dateInputsTarget) {
      this.dateInputsTarget.classList.add("hidden")
      this.dateInputsTarget.setAttribute("disabled", "true")
    }
  }

  showDateInputs() {
    if (this.dateInputsTarget) {
      this.dateInputsTarget.classList.remove("hidden")
      this.dateInputsTarget.removeAttribute("disabled")
    }
    if (this.selectInputsTarget) {
      this.selectInputsTarget.classList.add("hidden")
      this.selectInputsTarget.setAttribute("disabled", "true")
    }
  }

  toggleDateFields() {
    if (this.dateFilterTarget.options[this.dateFilterTarget.options.selectedIndex].value === "payment_date") {
      this.showDateInputs()
    } else {
      this.showSelectInputs()
      this.resetPayPeriodFilter()
    }
  }

  resetPayPeriodFilter() {
    if (this.hasPayPeriodFilterTarget) {
      this.payPeriodFilterTarget.options.selectedIndex = 0
    }
  }

  setDateFilterByToPaymentDate() {
    this.dateFilterTarget.value = "payment_date"
    this.toggleDateFields()
  }

  matchDatesToSelectedPeriod(event) {
    this.setDateFilterByToPaymentDate()

    const option = event.target.options[event.target.options.selectedIndex]
    this.dateInputsStartTarget.value = option.dataset.start
    this.dateInputsFinishTarget.value = option.dataset.finish
  }
}
