import { Controller } from "@hotwired/stimulus"
import I18n from "helpers/i18n"

export default class extends Controller {
  static targets = ["hidden", "add", "option", "buttonText"]

  toggledPoll() {
    // We use this hidden for the server to know if we should create a poll or not.

    if (this.hiddenTarget.value === "0") {
      // 0 means we don't have a poll yet.
      this.hiddenTarget.value = "1"
      this.buttonTextTarget.innerText = I18n.t("js.communications.polls.remove_poll")
      this.optionTargets.forEach((option) => this.cannotBeBlank(option)) // This makes all option input fields required.
    } else if (this.hiddenTarget.value === "1") {
      this.hiddenTarget.value = "0"
      this.buttonTextTarget.innerText = I18n.t("js.communications.polls.add_poll")
      this.optionTargets.forEach((option) => this.canBeBlank(option)) // We make the options not required.
    }

    if (!this.hasOptionTarget) {
      // The first time that you click "add poll", we prepopulate the form with two options.
      // We don't do this again if there's already any options on the form.
      this.addTarget.click()
      this.addTarget.click()
      this.optionTargets.forEach((option) => this.cannotBeBlank(option)) // We make the options required.
    }
  }

  cannotBeBlank(option) {
    option.querySelector("input").required = true
  }

  canBeBlank(option) {
    option.querySelector("input").required = false
  }
}
