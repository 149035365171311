import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "item", "multipleChoice", "linkToPayroll", "range"]
  static values = {
    item: Object,
  }

  item_types_with_range = ["temperature", "rating", "number_slider"]

  connect() {
    this.inputTarget.style.resize = "none"
    this.inputTarget.style.minHeight = `${this.inputTarget.scrollHeight}px`

    if (this.itemValue.item_type === "multiple_choice") {
      this.showAll(this.multipleChoiceTargets)
    } else {
      this.hideAll(this.multipleChoiceTargets)
    }

    if (this.item_types_with_range.includes(this.itemValue.item_type)) {
      this.rangeTarget.classList.remove("hidden")
    } else {
      this.rangeTarget.classList.add("hidden")
    }
  }

  onItemTypeChange = (e) => {
    if (e.target.value === "multiple_choice") {
      this.showAll(this.multipleChoiceTargets)
    } else {
      this.hideAll(this.multipleChoiceTargets)
    }

    if (this.item_types_with_range.includes(e.target.value)) {
      this.rangeTarget.classList.remove("hidden")
    } else {
      this.rangeTarget.classList.add("hidden")
    }
  }

  showAll = (targets) => {
    targets.forEach((target) => {
      target.classList.remove("hidden")
    })
  }

  hideAll = (targets) => {
    targets.forEach((target) => {
      target.classList.add("hidden")
    })
  }

  resize(event) {
    event.target.style.height = "5px"
    event.target.style.height = `${event.target.scrollHeight}px`
  }
}
