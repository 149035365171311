import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitForm"]

  submitSelectForm() {
    this.submitForm(this.submitFormTarget)
  }

  submitForm(form) {
    return new Promise((resolve) => {
      form.addEventListener("turbo:submit-end", resolve, { once: true })
      form.requestSubmit()
    })
  }
}
