import { Controller } from "@hotwired/stimulus"
import * as Routes from "helpers/routes"

export default class extends Controller {
  static targets = ["userId"]

  redirect(e) {
    const value = this.userIdTarget?.value || e.currentTarget?.value || e.currentTarget?.dataset?.value
    const profilePath = `${Routes.profile_personal_user_path(value)}`
    window.location = profilePath
  }
}
