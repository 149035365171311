import { Controller } from "@hotwired/stimulus"

// When the user clicks the final submit button on a form we need to perform some actions
// on success. These are 1) close the modal, 2) reload the frame containing the modal content.
// This controller listens for the form subit end event, and on success and if the submitter
// was a commit/submit button, it will close the modal and reload the frame.
//
// Usage:
// Add this controller to a wf-modal.
// Add a turbo:submit-end action to the modal to invoke the submitEnd method here.
// Add a data value to specify a reload id.
export default class extends Controller {
  static values = { reloadId: String }

  submitEnd(event) {
    if (
      event.detail.success &&
      event.detail.formSubmission.submitter.type === "submit" &&
      event.detail.formSubmission.submitter.name === "commit"
    ) {
      this.element.close()
      if (this.reloadIdValue) {
        document.getElementById(this.reloadIdValue)?.reload()
      }
    }
  }
}
