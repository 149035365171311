import { Controller } from "@hotwired/stimulus"
import { parseHTMLFragment } from "helpers/dom"

export default class extends Controller {
  static targets = ["teamFilter", "dailyShiftSummary", "refreshWidgetButton"]

  static values = {
    widgetUrl: String,
    filterUrl: String,
    teamLocationLocalStorageKey: String,
    defaultIds: Object,
    currentDepartmentId: String,
    currentLocationId: String,
  }

  connect() {
    this.loadWidget()
    this.loadTeamFilter()
  }

  loadWidget() {
    const teamLocationLocalStorageItem = localStorage.getItem(this.teamLocationLocalStorageKeyValue)
    let storedIds
    if (teamLocationLocalStorageItem) {
      const departmentOrLocation = teamLocationLocalStorageItem.match(/^[^=]*/)
      const teamId = teamLocationLocalStorageItem.match(/=(.*)/)[1]
      storedIds = JSON.parse(`{"${departmentOrLocation}":${teamId}}`)
    }
    window.Widget.DailyShiftSummary.init({
      interval: 3600000,
      url: this.widgetUrlValue,
      filter_url: this.filterUrlValue,
      default_ids: storedIds || this.defaultIdsValue,
    })
  }

  async loadTeamFilter() {
    const response = await fetch(this.filterUrlValue)
    const body = await response.text()
    this.teamFilterTarget.append(parseHTMLFragment(body))
  }

  refreshWidget({ detail: { selectedLocationId, selectedDepartmentId } }) {
    this.currentLocationIdValue = selectedLocationId
    this.currentDepartmentIdValue = selectedDepartmentId

    this.refreshWidgetButtonTarget.dataset["location_id"] = this.currentLocationIdValue
    this.refreshWidgetButtonTarget.dataset["department_id"] = this.currentDepartmentIdValue
    this.refreshWidgetButtonTarget.click() // cheeky jQuery refresh_widget call
  }
}
