import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["iconWrapper", "input", "currentRating"]

  applyActiveStyling(icon) {
    icon.classList.replace("text-gray-300", "text-accent-500")
  }

  applyInactiveStyling(icon) {
    icon.classList.replace("text-accent-500", "text-gray-300")
  }

  setStyling(rating) {
    this.iconWrapperTargets.forEach((iconWrapper) => {
      const icon = iconWrapper.querySelector("svg")
      if (Number(iconWrapper.dataset.value) <= rating) {
        this.applyActiveStyling(icon)
      } else {
        this.applyInactiveStyling(icon)
      }
    })
  }

  updateRating(e) {
    this.setStyling(e.target.value)
    this.currentRatingTarget.innerText = e.target.value
  }

  hoverStar(e) {
    const thisElement = e.target.closest("label")
    const thisRating = thisElement.dataset.value
    this.setStyling(thisRating)
  }

  resetStyling() {
    // Since it's a radio button it's safe to assume that there's only one checked, so we grab the first item
    const currentValue = this.inputTargets.filter((target) => target.checked)[0].value
    this.setStyling(currentValue)
  }
}
