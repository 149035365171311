import { Controller } from "@hotwired/stimulus"
import { flash } from "mobile_app/helpers/flash"
import { t } from "mobile_app/helpers/mobile_app_i18n"

export default class extends Controller {
  static values = { url: String }

  async toClipboard() {
    navigator.clipboard.writeText(this.urlValue)
    flash(t("copied"))
  }
}
