/* global VanillaCalendar */

import { Controller } from "@hotwired/stimulus"
import { escapeHTML } from "helpers/dom"
import I18n from "helpers/i18n"

export default class extends Controller {
  static targets = [
    "menuItem",
    "content",
    "commentsCount",
    "stepMenu",
    "stepContent",
    "handleSchedulesStrategyHiddenField",
  ]
  static values = {
    id: String,
    data: Object,
  }

  connect() {
    const calendarSettings = {
      settings: {
        visibility: {
          theme: "light",
          weekend: false,
        },
        selection: {
          day: false,
        },
        selected: {
          dates: [`${this.dataValue.start}:${this.dataValue.finish}`],
          month: this.dataValue.month,
          year: this.dataValue.year,
        },
      },
      popups: this.formatEvents(),
      actions: {
        getDays(day, date, HTMLElement, HTMLButtonElement, self) {
          if (Object.keys(eventsForDots).includes(date)) {
            HTMLButtonElement.style.flexDirection = "column"

            // eslint-disable-next-line no-unsanitized/property
            HTMLButtonElement.innerHTML = `
              <span>${day}</span>
              <div class="flex space-x-1 items-center justify-center">${eventsForDots[date]
                .map((event) => `<span class="calendar-indicator ${escapeHTML(event)}"></span>`)
                .join("")}</div>
            `
          }
        },
      },
    }

    const eventsForDots = this.dotsData(this.eventsByDate())
    const eventsCalendar = new VanillaCalendar(`#calendar-events-${this.idValue}`, calendarSettings)

    eventsCalendar.init()
  }

  updateHandleSchedulesStrategyHiddenField(event) {
    this.handleSchedulesStrategyHiddenFieldTarget.value = event.target.value
  }

  eventsByDate() {
    const holidays = this.dataValue.holidays.map((obj) => ({ ...obj, type: "holiday" }))
    const schedules = this.dataValue.schedules.map((obj) => ({ ...obj, date: obj.date, type: "schedule" }))

    // Creates an array for events with all dates between start and finish
    const getDatesInRange = (startDate, endDate) => {
      const dateArray = []
      const currentDate = new Date(startDate)
      while (currentDate <= new Date(endDate)) {
        dateArray.push(new Date(currentDate).toISOString().split("T")[0])
        currentDate.setDate(currentDate.getDate() + 1)
      }
      return dateArray
    }

    const events = this.dataValue.events.flatMap((obj) => {
      const datesInRange = getDatesInRange(obj.start, obj.finish)
      return datesInRange.map((date) => ({ ...obj, date, type: "event" }))
    })

    // Combine all events into a single array
    const allEvents = [...holidays, ...events, ...schedules]

    // Group events by date
    const eventsByDate = {}
    allEvents.forEach((event) => {
      const eventDate = event.date
      if (eventsByDate[eventDate]) {
        eventsByDate[eventDate].push(event)
      } else {
        eventsByDate[eventDate] = [event]
      }
    })

    return eventsByDate
  }

  dotsData(eventsByDate) {
    const eventsArrayByDay = {}
    Object.keys(eventsByDate).forEach((date) => {
      const typesArray = [...new Set(eventsByDate[date].map((thisDate) => thisDate.type))]
      eventsArrayByDay[date] = typesArray
    })
    return eventsArrayByDay
  }

  formatEvents() {
    const eventsByDate = this.eventsByDate()
    const formattedEvents = {}
    Object.keys(eventsByDate).forEach((date) => {
      const events = eventsByDate[date]
      formattedEvents[date] = {
        html: events
          .map(
            (event) =>
              `<div class="text-xs whitespace-nowrap">${
                event.type === "schedule"
                  ? `${I18n.t("js.leave.shift")}: ${escapeHTML(event.time)}`
                  : escapeHTML(event.name)
              }</div>`
          )
          .join(""),
      }
    })
    return formattedEvents
  }

  changeTab(e) {
    const target = e ? e.currentTarget : { dataset: { id: "request" } }
    const isMenuClick = !!e
    this.menuItemTargets.forEach((menuItem) => {
      if (menuItem.dataset.id !== "decline") {
        const isCurrentMenuItem = menuItem.dataset.id === target.dataset.id
        if (isCurrentMenuItem) {
          menuItem.classList.add("active", "border-b-blue-950", "opacity-100")
          menuItem.classList.remove("border-b-transparent", "opacity-60")
        } else {
          menuItem.classList.remove("active", "border-b-blue-950", "opacity-100")
          menuItem.classList.add("border-b-transparent", "opacity-60")
        }
      }
    })
    this.contentTargets.forEach((content) => {
      content.classList.add("hidden")
    })
    const contentTarget = this.contentTargets.find((content) => content.id === target.dataset.id)
    contentTarget.classList.remove("hidden")
    if (target.dataset.id === "request" && isMenuClick) {
      // resets the leave step to the first step
      this.changeLeaveStep()
    }
  }

  changeLeaveStep(e) {
    const target = e ? e.currentTarget : { dataset: { id: "date" } }
    this.stepMenuTargets.forEach((menuItem) => {
      const isCurrentMenuItem = menuItem.dataset.id === target.dataset.id
      if (isCurrentMenuItem) {
        menuItem.classList.add("bg-white", "shadow-md")
      } else {
        menuItem.classList.remove("bg-white", "shadow-md")
      }
    })
    this.stepContentTargets.forEach((content) => {
      content.classList.add("hidden")
    })
    const contentTarget = this.stepContentTargets.find((content) => content.id === target.dataset.id)
    contentTarget.classList.remove("hidden")
    contentTarget.scrollTop = 0
    // Moves the tab back to the request when a leave step is selected
    if (this.element.querySelector("#request").classList.contains("hidden")) {
      this.changeTab()
    }
  }

  incrementCommentCount() {
    const target = this.commentsCountTarget.querySelector("span")
    const currentCount = parseInt(target.innerText)
    const newCount = currentCount + 1
    if (newCount >= 1) {
      this.commentsCountTarget.classList.remove("hidden")
    }
    target.innerText = newCount
  }

  decrementCommentCount() {
    const target = this.commentsCountTarget.querySelector("span")
    const currentCount = parseInt(target.innerText)
    const newCount = currentCount - 1
    if (newCount <= 0) {
      this.commentsCountTarget.classList.add("hidden")
    }
    target.innerText = newCount
  }
}
