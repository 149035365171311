// @flow

import { type Axios } from "axios"

// $FlowFixMe axios is any
const addInterceptor = (
  axios: Axios
): any => // eslint-disable-line flowtype/no-weak-types
  axios.interceptors.request.use(
    (config) => {
      const tokenTag = document.getElementsByName("csrf-token")[0]
      const token = tokenTag ? tokenTag.getAttribute("content") : null

      if (token) {
        config.headers = config.headers || {}
        config.headers["X-CSRF-Token"] = token
      }

      return config
    },
    // $FlowFixMe[method-unbinding] https://github.com/facebook/flow/issues/8689
    Promise.reject
  )

export default addInterceptor
