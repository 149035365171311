import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["option"]

  selectOption(event) {
    const targetId = event.target.getAttribute("value")
    this.optionTargets.forEach((el) => {
      const elementTargetId = el.getAttribute("data-target-id")
      if (elementTargetId === targetId) {
        el.classList.remove("hidden")
      } else {
        el.classList.add("hidden")
      }

      if (elementTargetId === "new_group") {
        if (targetId === "new_group") {
          el.querySelector("input").setAttribute("required", true)
        } else {
          el.querySelector("input").removeAttribute("required")
        }
      }
    })
  }
}
