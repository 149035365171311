import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    isNew: Boolean,
    type: String,
  }

  static targets = ["itemTypeSelect", "rangeWrapper"]
  item_types_with_range = ["temp", "scale"]

  connect() {
    if (!this.isNewValue && this.item_types_with_range.includes(this.typeValue)) {
      this.rangeWrapperTarget.classList.remove("hidden")
    }
  }

  onItemTypeChange(event) {
    if (this.item_types_with_range.includes(event.target.value)) {
      this.rangeWrapperTarget.classList.remove("hidden")
    } else {
      this.rangeWrapperTarget.classList.add("hidden")
    }
  }
}
