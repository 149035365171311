import { Controller } from "@hotwired/stimulus"

// THIS IS DEPRECATED. PLEASE USE THE WF MODAL.
export default class extends Controller {
  static targets = ["content"]
  static values = {
    width: Boolean,
    title: String,
    backdrop: Boolean, // true if clicking backdrop closes modal
  }

  toggle(event) {
    const modalWrapper = document.querySelector("#peekModalWrapper")
    const isClosed = modalWrapper.classList.contains("hidden")
    if (isClosed) {
      this.open()
    } else {
      const peekModal = this.application.getControllerForElementAndIdentifier(modalWrapper, "peek-modal")
      peekModal.forceClose()
    }
  }

  open() {
    // Initialises _peek_modal.
    const modalOuterWrapper = document.querySelector("#peekModalWrapper")
    const modalContentWrapper = document.querySelector("#peekModalContentWrapper")
    const modalHeading = document.querySelector("#peekModalTitle")
    const modalContent = document.querySelector("#peekModalContent")
    const content = this.contentTarget.innerHTML

    if (this.titleValue) {
      modalHeading.innerText = this.titleValue
      modalHeading.classList.remove("hidden")
    } else {
      modalHeading.classList.add("hidden")
    }

    if (this.widthValue) {
      modalContentWrapper.classList.add("md:w-[35rem]")
      modalContentWrapper.classList.remove("md:w-auto")
    } else {
      modalContentWrapper.classList.add("md:w-auto")
      modalContentWrapper.classList.remove("md:w-[35rem]")
    }

    modalOuterWrapper.dataset.action = this.backdropValue
      ? "click->peek-modal#close keyup@window->peek-modal#close"
      : ""

    // we need to use innerHTML here to make the modal work, but worth confirming XSS risks
    // eslint-disable-next-line no-unsanitized/property
    modalContent.innerHTML = content

    modalOuterWrapper.classList.remove("hidden")
    modalContentWrapper.classList.remove("hidden")
  }
}
