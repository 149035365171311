import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["integrationCard", "disconnectButton", "loadingSpinner"]

  setCardLoading() {
    this.integrationCardTarget.inert = true
    this.disconnectButtonTarget.classList.add("opacity-80")
    this.loadingSpinnerTarget.classList.remove("hidden")
  }
}
