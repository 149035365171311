import { Controller } from "@hotwired/stimulus"
import Request from "helpers/request"

export default class extends Controller {
  static values = {
    validationUrl: String,
  }

  static targets = ["seekHirerIdValidation", "seekHirerIdValidationSpinner", "seekHirerIdNameDisplay", "submitButton"]

  getField(name) {
    return this.element.elements[name]
  }

  validateHirerId = debounce({
    delay: 1000,
    onStart: () => {
      this.seekHirerIdValidationSpinnerTarget.classList.remove("hidden")
      this.seekHirerIdValidationTarget.classList.add("hidden")
      this.seekHirerIdNameDisplayTarget.classList.add("hidden")
      this.submitButtonTarget.disabled = true
    },
    onFinish: async () => {
      const $seekHirerId = this.getField("seek_hirer_id")

      if ($seekHirerId.value) {
        const { data } = await Request.get(this.validationUrlValue, {
          params: {
            seek_hirer_id: $seekHirerId.value,
          },
        })

        if (data.exists) {
          this.seekHirerIdNameDisplayTarget.classList.remove("hidden")
          this.seekHirerIdNameDisplayTarget.textContent = data.name
          $seekHirerId.setCustomValidity("")
        } else {
          this.seekHirerIdValidationTarget.classList.remove("hidden")
          $seekHirerId.setCustomValidity(this.seekHirerIdValidationTarget.textContent)
        }
      }

      this.seekHirerIdValidationSpinnerTarget.classList.add("hidden")
      this.submitButtonTarget.disabled = false
    },
  })
}

function debounce({ delay, onStart, onFinish }) {
  let timeout

  return (...params) => {
    if (!timeout) onStart()

    clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = undefined
      onFinish(...params)
    }, delay)
  }
}
