import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["category", "resultsMessage", "messageBody", "searchTerm"]

  search(e) {
    const searchValue = e.target.value.toLowerCase()

    if (searchValue === "") {
      this.resultsMessageTarget.classList.add("hidden")
    } else {
      this.resultsMessageTarget.classList.remove("hidden")
    }

    const allMenuItems = this.element.querySelectorAll("li a.search-item")
    this.categoryTargets.forEach((category) => {
      // Hiding individual settings if no search results
      const menuItems = category.querySelectorAll("li a.search-item")
      menuItems.forEach((menuItem) => {
        if (menuItem.innerText.toLowerCase().includes(searchValue)) {
          menuItem.classList.remove("hidden")
        } else {
          menuItem.classList.add("hidden")
        }
      })

      // Hiding category if all results are hidden
      if (Array.from(menuItems).every((menuItem) => menuItem.classList.contains("hidden"))) {
        category.classList.add("hidden")
      } else {
        category.classList.remove("hidden")
      }
    })

    // Get a count of the number of visible menu items and display
    const visibleMenuItemsCount = Array.from(allMenuItems).filter(
      (menuItem) => !menuItem.classList.contains("hidden")
    ).length
    this.messageBodyTarget.innerText = `${visibleMenuItemsCount} ${
      // eslint-disable-next-line no-undef
      visibleMenuItemsCount === 1 ? I18n.t("js.settings.show.search.result") : I18n.t("js.settings.show.search.results")
    }`
    this.searchTermTarget.innerText = searchValue
  }
}
