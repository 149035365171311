import { Controller } from "@hotwired/stimulus"
import I18n from "helpers/i18n"

// This controller is for changing leave checklist content based on selected "handle_schedules_strategy".
export default class extends Controller {
  static targets = ["checklistText"]
  static values = { schedulesCount: Number }

  updateChecklistText(event) {
    this.checklistTextTarget.textContent = I18n.t(`js.leave.leave_checklist.schedules_to_${event.target.value}`, {
      count: this.schedulesCountValue,
    })
  }
}
