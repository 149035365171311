import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    id: String,
  }
  connect() {
    this.element
      .closest(".leave-request-modal")
      .querySelector(".insufficient-hours-indicator")
      .classList.remove("hidden")
  }
}
