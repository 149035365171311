import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["startTime", "finishTime", "frame"]

  static values = {
    updateUrl: String,
    summaryId: String,
  }

  update() {
    const url = new URL(this.updateUrlValue)

    url.searchParams.set("summary_id", this.summaryIdValue)
    url.searchParams.set("start", this.startTimeTarget.value)
    url.searchParams.set("finish", this.finishTimeTarget.value)

    this.frameTarget.src = url.toString()
  }
}
