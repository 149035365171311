import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["minPay", "maxPay"]

  static values = {
    minGreaterThanMaxMessage: String,
  }

  initialize() {
    this.validate()
  }

  update() {
    this.validate()
  }

  validate() {
    if (Number(this.minPayTarget.value) > Number(this.maxPayTarget.value)) {
      this.minPayTarget.setCustomValidity(this.minGreaterThanMaxMessageValue)
    } else {
      this.minPayTarget.setCustomValidity("")
    }
  }
}
