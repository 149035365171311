import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dailyBreakdown", "saveButton", "editButton"]

  startEditTable(e) {
    this.dailyBreakdownTarget.removeAttribute("inert")
    this.dailyBreakdownTarget.classList.remove("opacity-75")
    this.dailyBreakdownTarget.classList.remove("cursor-not-allowed")

    this.editButtonTarget.classList.add("hidden")
    this.saveButtonTarget.classList.remove("hidden")
  }
}
