import { Controller } from "@hotwired/stimulus"
import I18n from "helpers/i18n"

export default class extends Controller {
  static targets = [
    "desktopTeam",
    "desktopLocation",
    "teamValues",
    "locationValues",
    "dropdownText",
    "locationSubtitle",
    "locationLabel",
    "selectAll",
    "checkboxContainer",
    "search",
    "customChangeTrigger",
    "allCheckedHelper",
  ]
  static values = {
    selectedText: String,
    selectedTextSingle: String,
    type: String,
    lazyLoadedTeams: Boolean,
    selectAllChecked: Boolean,
  }

  connect() {
    if (!this.lazyLoadedTeamsValue) {
      this.updateText()
    }
    if (this.hasSelectAllTarget && this.selectAllTarget.checked) {
      this.checkboxContainerTarget.hidden = true
      this.searchTarget.hidden = true
      this.updateText()
    }
    this.dispatchSelectedDepartments()
    this.allCheckedHelperTarget.hidden = !this.selectAllCheckedValue
  }

  selectAll(e) {
    e.preventDefault()
    this.selectAllTarget.checked = !this.selectAllTarget.checked
    this.checkboxContainerTarget.hidden = this.selectAllTarget.checked
    this.searchTarget.hidden = this.selectAllTarget.checked
    const checkboxes = [...this.desktopTeamTargets, ...this.desktopLocationTargets]
    checkboxes.forEach((checkbox) => {
      checkbox.checked = ""
    })
    this.updateText()
    this.customChangeTriggerTarget.click()
  }

  mobileChange(e) {
    const values = [...e.target.selectedOptions].map((option) => option.value)
    const targets = this.isTeamSelector ? this.desktopTeamTargets : this.desktopLocationTargets

    targets.forEach((checkbox) => {
      checkbox.checked = false
    })
    values.forEach((value) => {
      const targetCheckbox = targets.find((checkbox) => checkbox.value === value)
      targetCheckbox.checked = true
    })
  }

  updateLocationSubtitles() {
    const arrayOfLocations = [...this.desktopTeamTargets]
      .filter((checkbox) => checkbox.checked)
      .map((item) => item.dataset.checkboxLocation)
    const locationTotals = arrayOfLocations.reduce((acc, curr) => {
      if (acc[curr]) {
        acc[curr] += 1
      } else {
        acc[curr] = 1
      }
      return acc
    }, {})
    const locationSubtitles = [...this.locationSubtitleTargets]
    locationSubtitles.forEach((subtitle) => {
      const location = subtitle.dataset.location
      if (locationTotals[location] && locationTotals[location] > 0) {
        subtitle.classList.remove("hidden")
        subtitle.innerText = I18n.t(`js.generic_components.team_selector.${this.typeValue}_selected`, {
          count: locationTotals[location],
        })
      } else {
        subtitle.classList.add("hidden")
      }
    })
  }

  handleParentLocation(e) {
    const location = e.target.dataset.checkboxLocation
    const parentCheckbox = this.element.querySelector(`[data-location-checkbox="${location}"]`)
    const allInLocation = [...this.desktopTeamTargets].filter(
      (checkbox) => checkbox.dataset.checkboxLocation === location
    )
    const locationCount = allInLocation.length
    const checkedCount = allInLocation.filter((checkbox) => checkbox.checked).length
    const allAreChecked = locationCount === checkedCount
    parentCheckbox.checked = allAreChecked
    this.dispatchSelectedDepartments()
  }

  updateText() {
    if (this.hasSelectAllTarget && this.selectAllTarget.checked) {
      this.allCheckedHelperTarget.hidden = false
      this.dropdownTextTarget.innerText = I18n.t(`js.generic_components.team_selector.all_${this.typeValue}_selected`)
      return
    } else if (this.hasAllCheckedHelperTarget) {
      this.allCheckedHelperTarget.hidden = true
    }

    const checkboxes = this.isTeamSelector ? this.desktopTeamTargets : this.desktopLocationTargets
    const checkedCount = [...checkboxes].filter((checkbox) => checkbox.checked).length

    this.dropdownTextTarget.innerText = I18n.t(`js.generic_components.team_selector.${this.typeValue}_selected`, {
      count: checkedCount,
    })

    if (this.isTeamSelector) {
      this.updateLocationSubtitles()
    }
  }

  dispatchSelectedDepartments() {
    if (this.isTeamSelector) {
      const allCheckboxes = [...this.element.querySelectorAll(`[data-checkbox-location`)]
      const allCheckedDepartments = allCheckboxes
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.value)
      if (allCheckboxes.length > 0) {
        this.dispatch("selected", {
          detail: {
            [allCheckboxes[0].name]: allCheckedDepartments,
          },
        })
      }
    }
  }

  locationSelect(e) {
    if (this.isTeamSelector) {
      this.expandLocation(e)

      const isChecked = e.target.checked
      const location = e.target.value
      const checkboxesToToggle = [
        ...this.element.querySelectorAll(
          `[data-checkbox-location="${location}"]:not([disabled]):not([style*="hidden"])`
        ),
      ]
      checkboxesToToggle.forEach((checkbox) => {
        checkbox.checked = isChecked
      })
      this.dispatchSelectedDepartments()
    }

    this.updateText()
  }

  search(e) {
    if (this.isTeamSelector) {
      this.locationLabelTargets.forEach((locationLabel) => {
        const teamsArray = JSON.parse(locationLabel.dataset.teamsArray)
        const teamsWithSearchTerm = teamsArray.filter((team) =>
          team.name.toLowerCase().includes(e.target.value.toLowerCase())
        )
        const numTeams = teamsWithSearchTerm.length
        if (numTeams > 0) {
          locationLabel.querySelector(".count").innerText = `(${numTeams})`
        }
      })
    }
  }

  get isTeamSelector() {
    return this.typeValue === "teams"
  }

  expandLocation(e) {
    if (!this.isTeamSelector) return

    const location = e.currentTarget.dataset.location
    const dropdown = this.element.querySelector(`[data-team-dropdown="${location}"]`)
    const caret = this.element.querySelector(`[data-caret="${location}"]`)
    const listItem = this.element.querySelector(`[data-list-item="${location}"]`)
    if (dropdown.style.gridTemplateRows === "1fr") {
      dropdown.style.setProperty("grid-template-rows", "0fr")
      caret.classList.remove("rotate-180")
      listItem.classList.remove("bg-gray-50")
    } else {
      dropdown.style.gridTemplateRows = "1fr"
      caret.classList.add("rotate-180")
      listItem.classList.add("bg-gray-50")
    }
  }
}
