import { Controller } from "@hotwired/stimulus"
import I18n from "helpers/i18n"
import { scrollToBottom } from "helpers/dom"

export default class extends Controller {
  static targets = ["preview", "header", "body", "form", "scroller"]
  static values = { messageId: String, messageContent: String, url: String }

  disconnect() {
    this.removeHiddenField()
    this.removePreview()
    this.changeFormToDirectMessage()
  }

  setReply(e) {
    this.addHiddenField(e.params)
    this.changeFormToReply(e.params)
    this.addPreview(e.params)
    scrollToBottom(this.scrollerTarget, true)
  }

  unsetReply() {
    this.changeFormToDirectMessage()
    this.removeHiddenField()
    if (!this.previewTarget.classList.contains("hidden")) {
      this.removePreview()
    }
  }

  changeFormToReply(params) {
    const url = params.messageUrl
    const form = this.formTarget
    form.setAttribute("action", url)
  }

  changeFormToDirectMessage() {
    const form = this.formTarget
    form.setAttribute("action", this.urlValue)
  }

  addHiddenField(params) {
    const id = params.messageId
    const form = this.formTarget
    const hiddenField = document.createElement("input")
    hiddenField.setAttribute("type", "hidden")
    hiddenField.setAttribute("name", "message_id")
    hiddenField.setAttribute("value", id)
    form.appendChild(hiddenField)
  }

  removeHiddenField() {
    const form = this.formTarget
    const hiddenField = form.querySelector("input[name='message_id']")
    hiddenField && hiddenField.remove()
  }

  addPreview(params) {
    let content = params.messageContent
    if (content.length > 50) {
      content = content.slice(0, 50) + "..."
    } else if (content.length === 0) {
      content = I18n.t("js.communications.preview_reply.attachment")
    }
    const preview = this.previewTarget
    preview.classList.remove("hidden")
    const name = this.headerTarget
    const text = this.bodyTarget
    text.innerText = content
    name.innerText = I18n.t("js.communications.preview_reply.replying_to", { name: params.messageName })
  }

  removePreview() {
    const preview = this.previewTarget
    preview.classList.add("hidden")
    const query = this.headerTarget
    query.innerText = ""
    const text = this.bodyTarget
    text.innerText = ""
  }
}
