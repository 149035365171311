import { Controller } from "@hotwired/stimulus"
import { t as globalT } from "helpers/i18n"

// This controller is used to update the help text for benefits based on the cash benefit checkbox state
export default class extends Controller {
  static targets = ["cashBenefitCheckbox", "cashBenefitHelpText"]

  updateHelpText() {
    const helpTextParent = this.cashBenefitHelpTextTarget
    const employeeHelpText = helpTextParent.children[0]
    const employerHelpText = helpTextParent.children[1]

    if (this.cashBenefitCheckboxTarget.checked) {
      employeeHelpText.textContent = globalT("js.payroll.uk.benefits.cash_benefit_employee_help_text")
      employerHelpText.textContent = globalT("js.payroll.uk.benefits.cash_benefit_employer_help_text")
    } else {
      employeeHelpText.textContent = globalT("js.payroll.uk.benefits.non_cash_benefit_employee_help_text")
      employerHelpText.textContent = globalT("js.payroll.uk.benefits.non_cash_benefit_employer_help_text")
    }
  }
}
