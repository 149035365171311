import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["departmentInput", "totalHours", "hoursInput"]

  setDepartments({ detail: { content } }) {
    const event = {
      currentTarget: {
        dataset: {
          ...content,
        },
      },
    }

    for (const departmentInputTarget of this.departmentInputTargets) {
      const searchController = this.application.getControllerForElementAndIdentifier(
        departmentInputTarget.parentElement,
        "design-components--search-component"
      )
      searchController.selectItem(event)
    }
  }

  totalHoursTargetConnected(element) {
    this.calculateTotalHours(element)
  }

  handleCalculateTotalHours() {
    this.calculateTotalHours(this.totalHoursTarget)
  }

  calculateTotalHours(element) {
    const totalHours = this.hoursInputTargets.reduce((sum, el) => {
      const value = parseFloat(el.querySelector("input").value)
      if (!Number.isNaN(value)) {
        sum += value
      }
      return sum
    }, 0)

    element.innerText = parseFloat(totalHours.toFixed(4))
  }

  setTotalHours(event) {
    const total = parseFloat(event.target.value)
    const numInputs = this.hoursInputTargets.length
    const hours = (total / numInputs).toFixed(2)

    this.hoursInputTargets.forEach((hoursInput) => {
      const input = hoursInput.querySelector("input")
      input.value = hours
    })

    this.handleCalculateTotalHours()
  }
}
