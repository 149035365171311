// @flow
import axios, { type AxiosPromise, type $AxiosXHR } from "axios"
import addInterceptor from "helpers/axiosIntercepter"

addInterceptor(axios)

// $FlowFixMe[method-unbinding] https://github.com/facebook/flow/issues/8689
const del = axios.delete
// $FlowFixMe[method-unbinding] https://github.com/facebook/flow/issues/8689
const get = axios.get
// $FlowFixMe[method-unbinding] https://github.com/facebook/flow/issues/8689
const post = axios.post
// $FlowFixMe[method-unbinding] https://github.com/facebook/flow/issues/8689
const put = axios.put

export type Result = AxiosPromise<mixed>
// $FlowFixMe untyped
export type Response = $AxiosXHR<mixed>

export { del, get, post, put }

export default axios
