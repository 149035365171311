import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "leaveAveragingSlider",
    "leaveAveragingCompatabilityText",
    "leaveInDaysSlider",
    "leaveInDaysCompatabilityText",
  ]

  connect() {
    if (this.leaveInDaysSliderTarget.checked && this.leaveAveragingSliderTarget.checked) {
      this.leaveAveragingCompatabilityTextTarget.classList.remove("hidden")
      this.leaveInDaysCompatabilityTextTarget.classList.remove("hidden")
      this.leaveAveragingSliderTarget.classList.add("warning")
      this.leaveInDaysSliderTarget.classList.add("warning")
    } else {
      this.toggleLeaveAveragingCompatabilityWarning()
    }
  }

  toggleLeaveAveragingCompatabilityWarning() {
    if (this.leaveInDaysSliderTarget.checked) {
      this.leaveInDaysCompatabilityTextTarget.classList.add("hidden")
      this.leaveAveragingCompatabilityTextTarget.classList.remove("hidden")
      this.leaveAveragingSliderTarget.checked = false
      this.leaveAveragingSliderTarget.setAttribute("disabled", true)
    } else if (this.leaveAveragingSliderTarget.checked) {
      this.leaveInDaysCompatabilityTextTarget.classList.remove("hidden")
      this.leaveAveragingCompatabilityTextTarget.classList.add("hidden")
      this.leaveInDaysSliderTarget.checked = false
      this.leaveInDaysSliderTarget.setAttribute("disabled", true)
    } else if (!this.leaveInDaysSliderTarget.checked && !this.leaveAveragingSliderTarget.checked) {
      this.leaveInDaysCompatabilityTextTarget.classList.add("hidden")
      this.leaveAveragingCompatabilityTextTarget.classList.add("hidden")
      this.leaveAveragingSliderTarget.removeAttribute("disabled")
      this.leaveInDaysSliderTarget.removeAttribute("disabled")
    }
  }
}
