import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["currentPayConditionsFieldset", "scheduledPayConditionsFieldset", "pastPayConditionsFieldset"]

  toggleNonCurrentPayConditionsFieldset() {
    this.#toggleFieldsetOpen(this.scheduledPayConditionsFieldsetTarget)
    this.#toggleFieldsetOpen(this.pastPayConditionsFieldsetTarget)
  }

  toggleNonScheduledPayConditionsFieldset() {
    this.#toggleFieldsetOpen(this.currentPayConditionsFieldsetTarget)
    this.#toggleFieldsetOpen(this.pastPayConditionsFieldsetTarget)
  }

  toggleNonPastPayConditionsFieldset() {
    this.#toggleFieldsetOpen(this.currentPayConditionsFieldsetTarget)
    this.#toggleFieldsetOpen(this.scheduledPayConditionsFieldsetTarget)
  }

  #toggleFieldsetOpen(target) {
    const targetDisabled = target.disabled

    target.classList.toggle("bg-gray-50", !targetDisabled)
    target.disabled = !targetDisabled
  }
}
