import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["newQuestionBtn", "newQuestionForm"]

  showForm() {
    this.newQuestionBtnTarget.classList.add("hidden")
    this.newQuestionFormTarget.hidden = false
  }

  hideForm() {
    this.newQuestionBtnTarget.classList.remove("hidden")
    this.newQuestionFormTarget.hidden = true
    this.newQuestionFormTarget.querySelector("turbo-frame").reload()
  }
}
