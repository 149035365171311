import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // show a target element if an input is selected
  // e.g f.select {data: {controller: "select-show"}, select-show-when-selected-value: '["option1", "option2"]'}
  //
  // .div{data: {select-show-target: "show"} <-- show this div if option1 or option2 are selected

  static targets = ["show"]
  static values = {
    whenSelected: Array,
  }

  connect(event) {
    this.showTarget.querySelectorAll("[hidden] input").forEach((input) => (input.disabled = true))
  }

  toggleShow(event) {
    const showElement =
      event.target.type === "checkbox" ? event.target.checked : this.whenSelectedValue.includes(event.target.value)

    this.showTarget.hidden = !showElement
    this.showTarget.querySelectorAll("input:not([hidden] input)").forEach((input) => (input.disabled = !showElement))
  }
}
