import { Controller } from "@hotwired/stimulus"
import { navigator } from "@hotwired/turbo"

export default class extends Controller {
  static targets = ["tab"]
  static values = {
    active: String,
    theme: String,
    tabs: Array,
  }

  activeClasses = {
    mobile: [
      "active",
      "!border-blue-500",
      "!text-blue-600",
      "bg-[#dcf6f9]",
      "dark:bg-darkmode-blue-500",
      "dark:!text-white",
    ],
    payroll: ["active", "!border-blue-500", "!text-blue-500", "dark:bg-darkmode-blue-500"],
    wfm: ["active", "!border-blue-800", "!text-blue-800", "dark:bg-darkmode-blue-500"],
    operations: ["active", "!border-blue-950", "!text-blue-950", "dark:bg-darkmode-blue-500", "dark:!border-blue-500"],
    leave: ["active"],
  }[this.themeValue]

  connect() {
    this.boundInitializeTabs = this.initializeTabs.bind(this)

    document.addEventListener("turbo:morph", this.boundInitializeTabs)

    this.initializeTabs()
  }

  disconnect() {
    document.removeEventListener("turbo:morph", this.boundInitializeTabs)
  }

  initializeTabs() {
    const urlParams = new URLSearchParams(window.location.search)
    this.contentTargets = this.tabsValue.map((tab) => tab.id)
    const activeTab = urlParams.get("tab") || this.activeValue

    if (activeTab !== null && this.contentTargets.includes(activeTab)) {
      this.tabTargets.forEach((tab) => {
        this.removeActiveStyling(tab)
      })
      this.activeValue = activeTab
      this.setActiveContent(activeTab)
      this.setActiveStyling(this.element.querySelector(`[aria-controls='${activeTab}']`))
    }
  }

  changeTab(e) {
    if (e.currentTarget.ariaPressed === "true") return
    this.tabTargets.forEach((tab) => {
      this.removeActiveStyling(tab)
    })
    this.setActiveStyling(e.currentTarget)
    const activeContentId = e.currentTarget.getAttribute("aria-controls")
    this.setActiveContent(activeContentId)

    navigator.history.replace(new URL(window.location.origin + window.location.pathname + `?tab=${activeContentId}`))
  }

  setActiveContent(activeContentId) {
    this.contentTargets.forEach((contentTabId) => {
      if (contentTabId) {
        const tabContent = this.element.querySelector(`#${contentTabId}`)
        // In some cases, tabs are shown but do not have multiple tabs on the page.
        if (tabContent) {
          tabContent.style.display = contentTabId === activeContentId ? "" : "none"
        }
      }
    })
  }

  removeActiveStyling(target) {
    target.classList.remove(...this.activeClasses)
    target.ariaPressed = "false"
  }

  setActiveStyling(target) {
    target.classList.add(...this.activeClasses)
    target.ariaPressed = "true"
  }
}
