import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"
import * as Routes from "helpers/routes"
import Request from "helpers/request"

// This controller is used to trigger a turbo frame to update the benefit details when assigning one to an employee
export default class extends Controller {
  static targets = ["benefitSelect"]

  loadDetails() {
    this.setEmployeeBenefitDetailsLoading(true)

    const benefitId = this.benefitSelectTarget.value
    const userId = document.getElementById("userId").value
    Request.get(Routes.details_payroll_uk_user_employee_benefit_path(Number(userId), benefitId), {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((response) => {
        Turbo.renderStreamMessage(response.data)
        this.setEmployeeBenefitDetailsLoading(false)
      })
      .catch((_error) => {
        this.setEmployeeBenefitDetailsLoading(false)
      })
  }

  setEmployeeBenefitDetailsLoading(loading) {
    const submitButton = document.getElementById("employee-benefits-submit-button")
    submitButton.disabled = loading

    const loadingDetails = document.getElementById("loading-details")
    if (loading) {
      loadingDetails.classList.remove("hidden")
    } else {
      loadingDetails.classList.add("hidden")
    }

    if (loading) {
      const employeeBenefitDetails = document.getElementById("employee-benefit-details")
      employeeBenefitDetails.innerHTML = ""
    }
  }
}
