import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["cycleLengthField", "anchorDateField"]
  static values = { selectedCadence: String }

  connect() {
    if (this.selectedCadenceValue !== "other") {
      this.hideCustomFields()
    }
  }

  toggle(event) {
    if ((event.target.value || event.target.closest("li").dataset.value) === "other") {
      this.showCustomFields()
    } else {
      this.hideCustomFields()
    }
  }

  showCustomFields() {
    this.cycleLengthFieldTarget.classList.remove("hidden")
    this.anchorDateFieldTarget.classList.remove("hidden")
  }

  hideCustomFields() {
    this.cycleLengthFieldTarget.classList.add("hidden")
    this.anchorDateFieldTarget.classList.add("hidden")
  }
}
