import { Controller } from "@hotwired/stimulus"

// Allows for refreshing a form when a form param is changed.
//
// To set up:
//
// - Add the controller to the form
// - Wrap the controller in a turbo frame, and set this as the formFrame target.
// - Set the form target as the form if you want the form's params to be included in the refresh request
// - Provide the URL that should be reloaded using the formEditUrl value.
// - Call the refreshForm or refreshFormAndScrollIntoView action on change.
// - Your server should reload the form with all inputs sets based on URL params, unless POST is required.
//
// You can also refresh a sidebar alongside the form by providing a sidebarUrl value and the refreshSidebar action.
export default class extends Controller {
  static targets = ["form", "formFrame", "sidebarFrame", "hiddenPostButton"]

  static values = {
    requireValid: Boolean,
    formEditUrl: String,
    sidebarUrl: String,
  }

  get loadingClasses() {
    return ["opacity-50", "transition-opacity", "pointer-events-none"]
  }

  getFormData() {
    if (this.hasFormTarget) {
      return new FormData(this.formTarget)
    } else {
      return new FormData()
    }
  }

  getFrameUrl(baseUrl) {
    const url = new URL(baseUrl)

    for (const [name, value] of this.getFormData()) {
      url.searchParams.append(name, value)
    }

    return url.toString()
  }

  isRefreshable() {
    if (this.requireValidValue && this.hasFormTarget) {
      return this.formTarget.checkValidity()
    } else {
      return true
    }
  }

  refreshForm() {
    if (!this.isRefreshable()) return

    // refresh via POST request
    if (this.hasHiddenPostButtonTarget) {
      return this.refreshViaPost(this.formFrameTarget)
    } else {
      // refresh via GET request
      return this.refresh(this.formFrameTarget, this.formEditUrlValue)
    }
  }

  async refresh(element, baseUrl, includeFormData = true) {
    element.src = includeFormData ? this.getFrameUrl(baseUrl) : baseUrl
    await this.handleLoadingState(element)
  }

  async refreshViaPost(element) {
    this.hiddenPostButtonTarget.click()
    await this.handleLoadingState(element)
  }

  async handleLoadingState(element) {
    element.inert = true
    element.classList.add(...this.loadingClasses)

    await new Promise((resolve) => {
      element.addEventListener("turbo:frame-load", resolve, {
        once: true,
        capture: true,
        passive: true,
      })
    })

    element.inert = false
    element.classList.remove(...this.loadingClasses)
  }

  async refreshFormAndScrollIntoView() {
    if (!this.isRefreshable()) return

    await this.refreshForm()

    this.formFrameTarget.scrollIntoView({
      block: "start",
      inline: "nearest",
    })
  }

  refreshSidebar() {
    if (this.hasSidebarFrameTarget && this.hasSidebarUrlValue) {
      return this.refresh(this.sidebarFrameTarget, this.sidebarUrlValue)
    }
  }
}
