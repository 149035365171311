import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  static targets = ["input", "menu", "selectedItem", "searchWrapper", "selectedWrapper", "formValue"]
  static values = {
    url: String,
    searchResultsList: String,
    broadcast: Boolean,
    userFilter: Number,
  }

  input(e) {
    if (e.currentTarget.value.length > 2) {
      this.menuTarget.classList.remove("hidden")
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        this.searchForItem()
      }, 500)
    } else {
      this.menuTarget.classList.add("hidden")
    }
  }

  searchForItem() {
    const url = new URL(window.location.origin + this.urlValue)
    url.searchParams.append("search_term", this.inputTarget.value)
    url.searchParams.append("search_results_list", this.searchResultsListValue)
    url.searchParams.append("user_filter", this.userFilterValue)

    fetch(url.href, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  selectItem(e) {
    const recordId = e.currentTarget.dataset.id
    this.formValueTarget.value = recordId
    const recordName = e.currentTarget.dataset.name
    this.selectedItemTarget.innerText = recordName
    this.searchWrapperTarget.classList.add("hidden")
    this.selectedWrapperTarget.classList.remove("hidden")

    if (this.hasBroadcastValue && this.broadcastValue) {
      this.dispatch("selectItem", { detail: { content: { id: recordId, name: recordName } } })
    }
  }

  clearItem(e) {
    this.searchWrapperTarget.classList.remove("hidden")
    this.selectedWrapperTarget.classList.add("hidden")
    this.inputTarget.value = ""
    this.inputTarget.focus()
    this.menuTarget.classList.add("hidden")
  }
}
