// @flow
import { Controller } from "@hotwired/stimulus"
import Request from "helpers/request"
import { t as globalT } from "helpers/i18n"

export default class extends Controller {
  static targets: Array<string> = [
    "checkbox",
    "typeSelector",
    "minField",
    "settingsSection",
    "hideFormLink",
    "editRuleSetForm",
    "manualNameField",
    "sideBarTab",
  ]
  static classes: Array<string> = ["hide", "show"]

  async hide_feature() {
    const req = {
      setting: {
        enable_occurrence_points: this.checkboxTarget.checked,
      },
    }
    const response = await Request.put("/occurrence_points/occurrence_point_rules/update_setting", req)

    if (response.status === 204) {
      if (this.checkboxTarget.checked) {
        this.settingsSectionTarget.classList.remove(this.hideClasses)
        this.settingsSectionTarget.classList.add(this.showClasses)
      } else {
        this.settingsSectionTarget.classList.remove(this.showClasses)
        this.settingsSectionTarget.classList.add(this.hideClasses)
      }
    }
  }

  hideRuleSetForm(event: Event) {
    event.preventDefault()
    if (this.editRuleSetFormTarget.hidden === true) {
      this.editRuleSetFormTarget.hidden = false
      this.hideFormLinkTarget.innerText = globalT("js.settings.occurrence_points.hide_rule_set_form")
    } else {
      this.editRuleSetFormTarget.hidden = true
      this.hideFormLinkTarget.innerText = globalT("js.settings.occurrence_points.show_rule_set_form")
    }
  }

  handleTypeSelection() {
    if (["no_show", "manual"].includes(this.typeSelectorTarget.value)) {
      this.minFieldTarget.disabled = true
      this.minFieldTarget.value = null
    } else {
      this.minFieldTarget.disabled = false
    }

    if (this.typeSelectorTarget.value === "manual") {
      this.manualNameFieldTarget.classList.remove(this.hideClass)
      this.manualNameFieldTarget.classList.add(this.showClass)
    } else {
      this.manualNameFieldTarget.classList.remove(this.showClass)
      this.manualNameFieldTarget.classList.add(this.hideClass)
    }
  }

  selectTab() {
    this.removeActiveTab()
    this.sideBarTabTarget.classList.add("active")
  }

  removeActiveTab() {
    const tab = document.getElementsByClassName("rule-set-tab active")[0]
    tab.classList.remove("active")
  }
}
