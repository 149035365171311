import { Controller } from "@hotwired/stimulus"
import "form-request-submit-polyfill"

export const disableSubmit = (submitTarget) => {
  submitTarget.disabled = true
  if (submitTarget.dataset.disableWith) {
    if (submitTarget.tagName === "INPUT") {
      // input[type=submit]
      submitTarget.dataset.enableWith = submitTarget.value
      submitTarget.value = submitTarget.dataset.disableWith
    } else {
      // button[type=submit]
      submitTarget.dataset.enableWith = submitTarget.innerText
      submitTarget.innerText = submitTarget.dataset.disableWith
    }
  }
}

export const enableSubmit = (submitTarget) => {
  submitTarget.disabled = false
  if (submitTarget.dataset.disableWith && submitTarget.dataset.enableWith) {
    if (submitTarget.tagName === "INPUT") {
      // input[type=submit]
      submitTarget.value = submitTarget.dataset.enableWith
    } else {
      // button[type=submit]
      submitTarget.innerText = submitTarget.dataset.enableWith
    }
  }
}

const setInputDisabled = (element, setting) => {
  element.disabled = setting
}

const disableEnterKey = (form) => {
  form.onkeydown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur()
      e.preventDefault()
    }
  }
}

export default class extends Controller {
  static targets = ["submit"]
  static values = {
    submit: String,
  }

  disconnect() {
    this.enableSubmits()
    this.element.toggleAttribute("data-submitting", false)
  }

  connect() {
    if (this.submitValue === "change") {
      disableEnterKey(this.element)
    }

    this.detectNestedForms()
  }

  disableSubmits() {
    this.submitTargets.forEach((submitTarget) => disableSubmit(submitTarget))
  }

  disableInputs() {
    this.element.querySelectorAll("input,select,button").forEach((ele) => {
      if (!ele.hasAttribute("data-form-input-disable")) {
        ele.toggleAttribute("data-form-input-disable", true)
        setInputDisabled(ele, true)
      }
    })
  }

  enableInputs() {
    this.element.querySelectorAll("input,select,button").forEach((ele) => {
      if (ele.hasAttribute("data-form-input-disable")) {
        ele.toggleAttribute("data-form-input-disable", false)
        setInputDisabled(ele, false)
      }
    })
  }

  enableSubmits() {
    this.submitTargets.forEach((submitTarget) => enableSubmit(submitTarget))
  }

  submitStart() {
    const form = this.element
    if (form && form.reportValidity()) {
      form.toggleAttribute("data-submitting", true)
      this.disableSubmits()
    }
  }

  submitEnd() {
    const form = this.element
    if (form && form.reportValidity()) {
      form.toggleAttribute("data-submitting", false)
      this.enableSubmits()
    }
  }

  requestSubmit(e) {
    const form = this.element
    if (form.reportValidity()) {
      form.requestSubmit()
      this.dispatch("requestedSubmit")
    }
  }

  detectNestedForms() {
    if (!(this.element instanceof HTMLFormElement)) return

    if (
      !!this.element.querySelector("form") || // has a child form
      !!this.element.parentElement.closest("form") // has a parent form
    ) {
      const msg =
        "Nested <form> detected. Forms cannot be nested in HTML, and this breaks form interactions in Turbo! Remember to use `turbo_form_with`, and be wary of nested forms lazily loaded via Turbo Frames. See dev tools console for more details."

      console.error(msg)
      console.error(this.element)

      if (process.env.NODE_ENV !== "production") {
        alert(msg)
      }
    }
  }
}
