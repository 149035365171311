import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["wrapper", "content"]

  initialize() {
    this.hideClass = "hidden"
    this.fadeInUpClass = "animate__fadeInUp"
    this.fadeInClass = "animate__fadeIn"
    this.fadeOutDownClass = "animate__fadeOutDown"
    this.fadeOutClass = "animate__fadeOut"

    this.resetClasses = (classToRemove, classesToAdd) => (event) => {
      if (event.target.classList.contains(classToRemove)) {
        classesToAdd.forEach((className) => {
          event.target.classList.add(className)
        })
        event.target.classList.remove(classToRemove)
      }
    }

    this.resetContentClasses = this.resetClasses(this.fadeOutDownClass, [this.hideClass, this.fadeInUpClass])
    this.resetWrapperClasses = this.resetClasses(this.fadeOutClass, [this.hideClass, this.fadeInClass])

    this.contentTarget.addEventListener("animationend", this.resetContentClasses)
    this.wrapperTarget.addEventListener("animationend", this.resetWrapperClasses)
  }

  disconnect() {
    this.contentTarget.removeEventListener("animationend", this.resetContentClasses)
    this.wrapperTarget.removeEventListener("animationend", this.resetWrapperClasses)
  }

  forceClose() {
    this.contentTarget.classList.replace(this.fadeInUpClass, this.fadeOutDownClass)
    this.wrapperTarget.classList.replace(this.fadeInClass, this.fadeOutClass)
  }

  close(event) {
    // Prevent event bubbling down. Only close on clicks to the backdrop, close-btn, or Escape key.
    const isFocusEvent = event.keyCode === 9
    const isEscBtn = event.type === "keyup" && event.keyCode === 27
    const isBackdropClick = !isFocusEvent && event.target.classList.contains("backdrop")
    const isCloseBtnClick = !isFocusEvent && event.target.classList.contains("close-btn")

    if (isEscBtn || isBackdropClick || isCloseBtnClick) {
      this.forceClose()
    }
  }
}
