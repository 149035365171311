import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    departments: Object,
    frequency: String,
    location: String,
  }

  static targets = [
    "locationSelect",
    "teamSelect",
    "teamWrapper",
    "frequencySelect",
    "repeatsOnWrapper",
    "intervalWrapper",
    "repeatsOnDateWrapper",
  ]

  frequency_with_day_select = ["weekly", "custom"]
  frequency_with_date_select = ["monthly"]
  frequency_with_interval_select = ["custom"]

  connect() {
    if (this.locationValue) {
      this.teamWrapperTarget.classList.remove("hidden")
    } else {
      this.teamWrapperTarget.classList.add("hidden")
    }

    if (this.frequencyValue && this.frequency_with_day_select.includes(this.frequencyValue)) {
      this.repeatsOnWrapperTarget.classList.remove("hidden")
    } else {
      this.repeatsOnWrapperTarget.classList.add("hidden")
    }

    if (this.frequencyValue && this.frequency_with_interval_select.includes(this.frequencyValue)) {
      this.intervalWrapperTarget.classList.remove("hidden")
    } else {
      this.intervalWrapperTarget.classList.add("hidden")
    }

    if (this.frequencyValue && this.frequency_with_date_select.includes(this.frequencyValue)) {
      this.repeatsOnDateWrapperTarget.classList.remove("hidden")
    } else {
      this.repeatsOnDateWrapperTarget.classList.add("hidden")
    }
  }

  onLocationChange(event) {
    if (event.target.value) {
      this.teamWrapperTarget.classList.remove("hidden")
    } else {
      this.teamWrapperTarget.classList.add("hidden")
    }
    const teams = this.departmentsValue[event.target.value]
    this.teamSelectTarget.length = 0
    this.teamSelectTarget.add(new Option("Please select", "nil"))
    teams.forEach(([team, id]) => {
      this.teamSelectTarget.add(new Option(team, id))
    })
    this.teamSelectTarget.focus()
  }

  onFrequencyChange(event) {
    if (this.frequency_with_day_select.includes(event.target.value)) {
      this.repeatsOnWrapperTarget.classList.remove("hidden")
    } else {
      this.repeatsOnWrapperTarget.classList.add("hidden")
    }

    if (this.frequency_with_interval_select.includes(event.target.value)) {
      this.intervalWrapperTarget.classList.remove("hidden")
    } else {
      this.intervalWrapperTarget.classList.add("hidden")
    }

    if (this.frequency_with_date_select.includes(event.target.value)) {
      this.repeatsOnDateWrapperTarget.classList.remove("hidden")
    } else {
      this.repeatsOnDateWrapperTarget.classList.add("hidden")
    }
  }
}
