import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["edit", "modal", "open", "close", "startDate", "finishDate", "validationError", "select"]
  static values = { current: String, label: String }

  open() {
    this.openTarget.click()
  }

  close() {
    this.closeTarget.click()
  }

  reset() {
    this.startDateTarget.value = new Date().toISOString().split("T")[0]
    this.finishDateTarget.value = new Date().toISOString().split("T")[0]
    this.validationErrorTarget.hidden = true
    this.editTarget.hidden = true
    this.selectTarget.querySelector("option[value='custom']").innerText = this.labelValue
  }

  cancel() {
    this.reset()
    this.selectTarget.value = this.currentValue
  }

  selectDate(e) {
    if (e.target.value === "custom") {
      this.open()
    } else {
      this.reset()
    }
  }

  validate() {
    const startDate = new Date(this.startDateTarget.value)
    const finishDate = new Date(this.finishDateTarget.value)
    const hasError = startDate.getTime() > finishDate.getTime()
    this.validationErrorTarget.hidden = !hasError
    if (!hasError) {
      this.close()
      this.editTarget.hidden = false
      this.selectTarget.querySelector(
        "option[value='custom']"
      ).innerText = `${startDate.toLocaleDateString()} - ${finishDate.toLocaleDateString()}`
    }
  }
}
