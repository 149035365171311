/* 
Based https://www.stimulus-components.com/docs/stimulus-rails-nested-form/ 🙏

TODO: use this in ATS/jobs/new and other places with nested attributes.
*/

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["target", "template"]
  static values = {
    wrapperSelector: {
      type: String,
      default: ".nested-form-wrapper",
    },
  }

  add(e) {
    e.preventDefault()
    // This is a random string that we use to identify new records in the DOM.
    // It's the date and time plus a random number, so that it's very unlikely to have collisions.
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, this.dateTimeWithRandomNumberString())
    // this is fine: it's server-side-escaped template, and we don't add any user-provided text here
    // eslint-disable-next-line no-unsanitized/method
    this.targetTarget.insertAdjacentHTML("beforebegin", content)
  }

  remove(e) {
    e.preventDefault()

    const wrapper = e.target.closest(this.wrapperSelectorValue)

    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove()
    } else {
      wrapper.style.display = "none"

      const input = wrapper.querySelector("input[name*='_destroy']")
      input.value = "1"
    }
  }

  dateTimeWithRandomNumberString() {
    return new Date().getTime().toString() + Math.floor(Math.random() * 1000).toString()
  }
}
