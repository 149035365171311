/**
 * Set up Turbo
 *
 * https://github.com/hotwired/turbo is the package, but https://github.com/hotwired/turbo-rails has slightly better docs.
 * We don't use Action Cable, so don't use stream elements!
 */
import * as Turbo from "@hotwired/turbo"

/**
 * Set up Stimulus
 */
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import { application as Stimulus } from "stimulus/load"
import "turbo/custom_stream_actions"

/**
 * Disable turbo drive by default so there's no risk of links in existing pages
 * being hijacked by turbo. Instead, we can enable it on a per-page basis when
 * using turbo on a new page by either:
 *   - Adding `before_action :enable_turbo_drive!` to your controller.
 *       This enables turbo drive for all links/forms on the entire page, it
 *       can still be disabled on a per link/form basis.
 *       eg: app/controllers/occurrence_points/occurrence_point_rule_sets_controller.rb
 *   - Setting the attribute data-turbo="true" on a link or form.
 *       This enables turbo drive on a per link/form basis.
 *       eg: app/views/occurrence_points/_occurrence_points_config.html.haml
 */
Turbo.session.drive = false

/**
 * Load our Stimulus controllers
 */
Stimulus.load(definitionsFromContext(require.context("stimulus/shared_controllers", true, /\.js$/)))
Stimulus.load(definitionsFromContext(require.context("./stimulus_controllers", true, /\.js$/)))

export default function run() {
  // anything we need to do once this bundle has loaded
}

window.SPA = { ...window.SPA, Desktop: { run } }
