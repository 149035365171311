import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { condition: String }
  static targets = ["content"]

  show(event) {
    this.contentTarget.style.display = ""
    event.currentTarget.setAttribute("aria-pressed", true)
  }

  hide(event) {
    this.contentTarget.style.display = "none"
    event.currentTarget.setAttribute("aria-pressed", false)
  }
}
