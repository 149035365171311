import { Application } from "@hotwired/stimulus"
import * as Sentry from "@sentry/browser"

const application = Application.start()
application.warnings = true
application.debug = process.env.NODE_ENV === "development" // https://github.com/hotwired/stimulus/pull/354
application.handleError = (error, message, detail) => {
  Sentry.captureException(error)
}
window.Stimulus = application

export { application }
