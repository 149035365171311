import { Controller } from "@hotwired/stimulus"

// Intention:
// When the user changes the award we are going to update the dependent
// employment type, classification level, allowance and additional tag
// fields.
//
// The controller provides an update method that when invoked will
// click on a specified element. The element is expected to be a
// hidden button that submits the form to a refresh_form endpoint.
// The section of the form that you want updated should be wrapped
// in a turbo frame and the button should target that frame.
export default class extends Controller {
  // updateButton is the element (singular) to be clicked
  static targets = ["updateButton", "checkboxes"]

  connect() {
    document.addEventListener("turbo:before-fetch-request", this.disableFields)
    document.addEventListener("turbo:submit-end", this.enableFields)
  }

  disconnect() {
    document.removeEventListener("turbo:before-fetch-request", this.disableFields)
    document.removeEventListener("turbo:submit-end", this.enableFields)
  }

  disableFields = (e) => {
    if (e.target.id.includes("form_position")) {
      this.checkboxesTarget.querySelector("fieldset").disabled = true

      // Since the turbo frame is lazy loaded I can't use a target
      const submitBtn = this.element.querySelector("#refresh-form-submit")
      if (submitBtn) {
        submitBtn.disabled = true
      }
    }
  }

  enableFields = (e) => {
    if (e.target.id.includes("form_position")) {
      this.checkboxesTarget.querySelector("fieldset").disabled = false
      // No need to enable the submit button again since the turbo frame will refresh and reset the submit button.
    }
  }

  update() {
    this.updateButtonTarget.click()
  }
}
