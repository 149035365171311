import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "teamList",
    "defaultTeamMarker",
    "worksInCheckbox",
    "secondaryTeamsCheckbox",
    "managesCheckbox",
    "defaultTeamOption",
    "defaultTeamField",
  ]

  connect() {
    this.updateDefaultTeamOptions()

    this.boundUpdateDefaultTeamOptionsOnLazyLoad = this.updateDefaultTeamOptionsOnLazyLoad.bind(this)
    // The locations/teams selector is lazy loaded, so we need to populate the default team selector with values after it loads.
    document.addEventListener("turbo:frame-load", this.boundUpdateDefaultTeamOptionsOnLazyLoad)
  }

  disconnect() {
    document.removeEventListener("turbo:frame-load", this.boundUpdateDefaultTeamOptionsOnLazyLoad)
  }

  updateDefaultTeamOptionsOnLazyLoad(e) {
    if (e.target.id.includes("locations_teams_selector")) {
      this.updateDefaultTeamOptions()
    }
  }

  updateTeamListAndSelector() {
    this.updateDefaultTeamOptions()
    this.updateWorksInCheckboxes()
    this.reloadFrame()
  }

  updateDefaultTeamOptions() {
    this.defaultTeamOptionTargets.forEach((optionTarget) => {
      const optionValue = optionTarget.value || optionTarget.dataset.value
      optionTarget.style.display = this.teamIds().includes(optionValue) ? "" : "none"
    })
  }

  reloadFrame() {
    const defaultTeamId = this.defaultTeamFieldTarget.value
    const teamIdString = this.teamIds().join()
    const secondaryTeamIdString = this.secondaryTeamIds().join()
    const managedTeamString = this.managesTeamIds().join()
    const teamQueryString = `?user[teams]=${teamIdString}&user[secondary_department_ids]=${secondaryTeamIdString}&user[managed_teams]=${managedTeamString}&report_department_id=${defaultTeamId}`
    if (this.teamListTarget.src.match(/\?.*/)) {
      this.teamListTarget.src = this.teamListTarget.src.replace(/\?.*/, teamQueryString)
    } else {
      this.teamListTarget.src = this.teamListTarget.src + teamQueryString
    }
  }

  updateWorksInCheckboxes() {
    const defaultTeamId = this.defaultTeamFieldTarget.value
    this.worksInCheckboxTargets.forEach((checkboxTarget) => {
      if (defaultTeamId === checkboxTarget.value) {
        checkboxTarget.checked = true
        checkboxTarget.readonly = true
      } else {
        checkboxTarget.readonly = false
      }
    })
    this.defaultTeamMarkerTargets.forEach((markerTarget) => {
      if (defaultTeamId === markerTarget.getAttribute("data-value")) {
        markerTarget.classList.remove("hidden")
      } else {
        markerTarget.classList.add("hidden")
      }
    })
  }

  managesTeamIds() {
    return this.managesCheckboxTargets
      .filter((managesCheckbox) => managesCheckbox.checked)
      .map((managesCheckbox) => managesCheckbox.value)
  }

  teamIds() {
    return this.worksInCheckboxTargets
      .filter((worksInCheckbox) => worksInCheckbox.checked)
      .map((worksInCheckbox) => worksInCheckbox.value)
  }

  secondaryTeamIds() {
    return this.secondaryTeamsCheckboxTargets
      .filter((secondaryTeamsCheckbox) => secondaryTeamsCheckbox.checked)
      .map((secondaryTeamsCheckbox) => secondaryTeamsCheckbox.value)
  }
}
