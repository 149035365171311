import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"
import { Embed, MESSAGE_KIND } from "@super-api/super-api-embed"
import { flash } from "mobile_app/helpers/flash"

// A controller to assist with the use of https://github.com/supersimplesuper/super-api-embed
export default class extends Controller {
  static values = { url: String, afterFinished: String }

  connect() {
    this.embed = new Embed({
      element: this.element,
      url: this.urlValue,
    })
    if (this.hasAfterFinishedValue) {
      this.embed.on(MESSAGE_KIND.ONBOARDING_SESSION_FINISHED, () => {
        Turbo.visit(this.afterFinishedValue)
      })
    }
    this.embed.on(MESSAGE_KIND.TOAST, (data) => {
      let type = "notice"
      switch (data.kind) {
        case "warning":
        case "error":
          type = "error"
          break
      }
      flash(data.message, type)
    })
  }
}

// on "onboardingSessionFinished", redirect somewhere else
