import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dependency"]

  toggle(event) {
    if (event.target.checked) {
      this.enable()
    } else {
      this.disable()
    }
  }

  enable() {
    this.dependencyTargets.forEach((fieldset) => (fieldset.disabled = false))
  }

  disable() {
    this.dependencyTargets.forEach((fieldset) => {
      fieldset.querySelectorAll("input").forEach((input) => (input.checked = false))
      fieldset.disabled = true
    })
  }
}
