import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  static targets = ["progress"]

  connect() {
    this.progress = 0
    const intervalID = setInterval(this.fetchProgress.bind(this), 1000)
    this.intervalID = intervalID
  }

  fetchProgress() {
    const url = this.progressTarget.dataset.url
    const loadedUrl = this.progressTarget.dataset.loadedUrl
    fetch(`${url}/check_status?cache_key=${this.progressTarget.dataset.cacheKey}`)
      .then((res) => res.json())
      .then((res) => {
        const data = res.data
        if (data === "processing_error") {
          clearInterval(this.intervalID)
          Turbo.visit(loadedUrl + "?processing_error=true")
        }

        const loadPercentage = parseInt(res.data)
        this.progressTarget.style.width = `${loadPercentage}%`
        this.progressTarget.innerText = `${loadPercentage}%`

        if (loadPercentage === 100) {
          clearInterval(this.intervalID)
          if (loadedUrl.includes("?")) {
            Turbo.visit(loadedUrl + "&state=finished")
          } else {
            Turbo.visit(loadedUrl + "?state=finished")
          }
        }
      })
  }
}
