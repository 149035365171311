import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"
import axios from "axios"

export default class extends Controller {
  redirect(event) {
    const id = event.params.id
    const src = event.params.src
    const frame = document.getElementById(id)
    frame.setAttribute("src", src)
    frame.reload()
  }

  // use this when working with a POST request and turbo frames
  // basically forces the frame to reload with the new content
  // using turbo streams, because turbo frames don't support POST requests
  // so we refresh the frame with the new content manually instead
  reportRedirect(event) {
    const src = event.params.src
    const req = event.params.params
    // have to manually set the accept header to get the turbo stream response
    const headers = {
      "Content-Type": "application/json",
      Accept: "text/vnd.turbo-stream.html",
    }
    axios({
      method: "post",
      url: src,
      data: req,
      headers: headers,
    }).then((res) => {
      Turbo.renderStreamMessage(res.data)
    })
  }
}
