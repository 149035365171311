import Compressor from "compressorjs"
import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import { escapeHTML } from "helpers/dom"
import I18n from "helpers/i18n"
import { flash } from "mobile_app/helpers/flash"

export default class extends Controller {
  static targets = ["upload", "dropzone", "fileList", "hiddenField", "removeAll"]
  static values = {
    multiple: Boolean,
    documentIcon: String,
    maxSize: Number,
    items: Array,
  }

  currentFiles = []
  activeClasses = ["animate-pulse", "-outline-offset-8"]

  fileUpload(event) {
    if (event.type === "change") {
      // Event triggered by clicking the button
      this.handleFiles(event.target.files, { multipleAllowed: this.multipleValue })
    }

    if (event.type === "drop") {
      // Event triggered by dropping files on the dropzone
      event.stopPropagation()
      event.preventDefault()
      this.displayInactiveUI()

      const dt = event.dataTransfer
      const files = dt.files

      this.handleFiles(files, { multipleAllowed: this.multipleValue })
    }
  }

  connect() {
    this.reconnect()
  }

  disconnect() {
    this.resetElements()
  }

  reconnect() {
    if (this.itemsValue.length > 0) {
      this.itemsValue.forEach((item) => {
        // eslint-disable-next-line no-unsanitized/method
        this.fileListTarget.insertAdjacentHTML(
          "beforeend",
          this.uploadedFileUI({
            icon: this.documentIconValue,
            image: item.content_type.startsWith("image") ? item.remote_url : null,
            url: item.remote_url,
            fileName: item.name,
            sgid: item.sgid,
            fileSize: this.bytesToSize(item.size),
            noRemove: this.itemsValue.length > 1,
          })
        )
      })
    }
    if (this.itemsValue.length > 1) {
      this.removeAllTarget.classList.remove("hidden")
    }

    // if using AS, we don't want to submit any files on the upload target itself.
    // instead all files will be directed uploaded, and hidden fields referencing their SGIDs will be uploaded.
    if (this.useActiveStorageDirectUpload) {
      this.itemsValue.forEach((item) => {
        if (item.sgid) {
          this.appendActiveStorageHiddenField(item.sgid)
        }
      })

      this.uploadTarget.disabled = true
    }
  }

  resetElements() {
    this.uploadTarget.value = null
    this.fileListTarget.innerHTML = ""
    this.removeAllTarget.classList.add("hidden")
  }

  removeAll() {
    this.resetElements()
    this.currentFiles.forEach((f) => this.removeFile(f))
    this.element.querySelectorAll("input[type=hidden][data-active-storage]").forEach((e) => e.remove())
    this.hiddenFieldTarget.disabled = false
  }

  openFileSelector() {
    // if using AS, temporarily enable the upload target so we can click it
    // then disable it again, since we don't want to submit via it.
    if (this.useActiveStorageDirectUpload) {
      this.uploadTarget.disabled = false
    }

    this.uploadTarget.click()

    if (this.useActiveStorageDirectUpload) {
      this.uploadTarget.disabled = true
    }
  }

  dragEnter(event) {
    event.stopPropagation()
    event.preventDefault()
  }

  dragLeave(event) {
    this.displayInactiveUI()
    event.stopPropagation()
    event.preventDefault()
  }

  dragOver(event) {
    this.displayActiveUI()
    event.stopPropagation()
    event.preventDefault()
  }

  uploadedFileUI(options) {
    const img = escapeHTML(options.image)
    const url = escapeHTML(options.url)
    const filename = escapeHTML(options.fileName)
    const size = escapeHTML(options.fileSize)
    const sgid = options.sgid

    return `
      <li class="flex space-x-3 mt-2">
      ${
        options.image
          ? `<img src="${img}" class="w-10 h-10 object-contain object-center rounded-md" />`
          : `<div class="btn-secondary flex items-center justify-center">${options.icon}</div>`
      }
        <div>
        ${
          options.url
            ? `<a href="${url}" target="_blank" class="link font-bold leading-tight break-all" rel="noopener noreferrer">${filename}</a>`
            : `<p class="font-bold leading-tight break-all">${filename}</p>`
        }
          <p class="m-0 text-sm text-gray-700">
            <span class="after:content-['|'] after:inline-block after:px-1 text-xs">${size}</span>
            ${
              options.noRemove
                ? ""
                : `<span class="link" role="button" data-action="click->design-components--file-uploader#removeFile" data-filename="${filename}" data-sgid="${sgid}">Remove</span>`
            }
          </p>
        </div>
      </li>
    `
  }

  bytesToSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    if (bytes === 0) return "n/a"
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
    if (i === 0) return `${bytes} ${sizes[i]}`
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
  }

  displayActiveUI() {
    this.activeClasses.forEach((className) => {
      this.dropzoneTarget.classList.add(className)
    })
  }

  displayInactiveUI() {
    this.activeClasses.forEach((className) => {
      this.dropzoneTarget.classList.remove(className)
    })
  }

  handleFiles(files, options = {}) {
    if (this.multipleValue === false && files.length > 1) {
      flash(I18n.t("js.mobile_app.file_upload.single_file"), "error")
      return
    }

    const fileIsTooBig = (file) => file.size > this.maxSizeValue

    const fileListArray = Array.from(files)
    if (fileListArray.some(fileIsTooBig)) {
      flash(`${I18n.t("js.mobile_app.file_upload.file_too_large")} ${this.bytesToSize(this.maxSizeValue)}`, "error")
    }
    const filteredFileListArray = fileListArray.filter((file) => file.size <= this.maxSizeValue)

    // if multiple files are not allowed, and the new file is not too big, then clear the file list
    if (options.multipleAllowed === false && !fileListArray.some(fileIsTooBig)) {
      this.fileListTarget.innerHTML = ""
      this.currentFiles = []
    }

    filteredFileListArray.forEach((item) => {
      if (item.type.startsWith("image/")) {
        const reader = new FileReader()
        reader.readAsDataURL(item)
        reader.onload = async (e) => {
          const resultFile = await this.compressFile(item)
          // eslint-disable-next-line no-unsanitized/method
          this.fileListTarget.insertAdjacentHTML(
            "beforeend",
            this.uploadedFileUI({
              icon: this.documentIconValue,
              image: e.target.result,
              fileName: resultFile.name,
              fileSize: this.bytesToSize(resultFile.size),
            })
          )
        }
      } else {
        // eslint-disable-next-line no-unsanitized/method
        this.fileListTarget.insertAdjacentHTML(
          "beforeend",
          this.uploadedFileUI({
            icon: this.documentIconValue,
            fileName: item.name,
            fileSize: this.bytesToSize(item.size),
          })
        )
      }
    })

    this.addFiles(filteredFileListArray)
  }

  // It turns out that the FileReader isn't very flexible and doesn't allow you to easily
  // add and remove files from the list. So instead, we're going to use the DataTransfer object,
  // which is what is used when you drag and drop files into the browser.

  // What is happening here is:
  // 1. We keep track of the current files in the currentFiles variable
  // 2. We create a new DataTransfer object
  // 3. We add the current files to the DataTransfer object
  // 4. We add the new files to the DataTransfer object
  // 5. We add all the files to the input element (called uploadTarget)

  // https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer
  // https://stackoverflow.com/questions/16943605/remove-a-filelist-item-from-a-multiple-inputfile

  addFiles(newFiles) {
    this.dispatch("disableForm")

    this.removeAllTarget.classList.add("hidden")
    this.hiddenFieldTarget.disabled = true
    const _this = this
    const oldFiles = _this.currentFiles
    const fileBuffer = new DataTransfer()
    const allFilesPromises = []

    oldFiles?.forEach((file) => {
      allFilesPromises.push(
        new Promise(async (resolve) => {
          resolve(file)
        })
      )
    })
    newFiles?.forEach(async (file) => {
      allFilesPromises.push(
        new Promise(async (resolve) => {
          if (file.type.startsWith("image/")) {
            const resultFile = await this.compressFile(file)
            resolve(resultFile)
          } else {
            resolve(file)
          }
        })
      )
    })

    Promise.all(allFilesPromises).then((resolvedFiles) => {
      resolvedFiles.forEach((file) => {
        fileBuffer.items.add(file)
      })
      _this.uploadTarget.files = fileBuffer.files
      _this.currentFiles = fileBuffer.files

      if (_this.useActiveStorageDirectUpload) {
        const filesToUpload = Array.from(fileBuffer.files).filter((f) => !f.sgid)
        const expected = filesToUpload.length
        let completed = 0

        filesToUpload.forEach((file) => {
          // https://edgeguides.rubyonrails.org/active_storage_overview.html#custom-drag-and-drop-solutions
          const upload = new DirectUpload(file, _this.activeStorageDirectUploadUrl)

          upload.create((error, blob) => {
            completed += 1
            if (completed === expected) {
              this.dispatch("enableForm")
            }

            if (error) {
              console.error(error)
            } else {
              // When a Direct Upload completes, we get back a signed_id (sgid) that represents the blob.
              // We use a hidden field to provide this back to the server - that's what's happening here.
              // Elsewhere you'll note that we disable the `uploadTarget` when using Active Storage. That's
              // becuase we don't need to actually upload any files via the form input with this approach.
              // We just need to provide the sgids; the files are already uploaded to S3.

              _this.appendActiveStorageHiddenField(blob.signed_id)
              file.sgid = blob.signed_id
            }
          })
        })
      } else {
        this.dispatch("enableForm")
      }
    })
  }

  removeFile(event) {
    const fileName = event.target.dataset.filename

    const parentElementInUI = event.target.closest("li")
    parentElementInUI.remove()

    const removedActiveStorageFilesFromCurrentFiles = Array.from(this.currentFiles).filter(
      (file) => file.name === fileName && file.sgid
    )
    removedActiveStorageFilesFromCurrentFiles.forEach((f) => {
      this.element.querySelector(`input[type=hidden][data-active-storage][value="${f.sgid}"]`)?.remove()
    })
    const removedActiveStorageSGIDFromEvent = event.target.dataset.sgid
    if (removedActiveStorageSGIDFromEvent) {
      this.element
        .querySelector(`input[type=hidden][data-active-storage][value="${removedActiveStorageSGIDFromEvent}"]`)
        ?.remove()
    }

    // FIXME: there is a bug here if there are two attachments with the same filename.
    const updatedFiles = Array.from(this.currentFiles).filter((file) => file.name !== fileName)
    const fileBuffer = new DataTransfer()
    updatedFiles.forEach((file) => {
      fileBuffer.items.add(file)
    })
    this.uploadTarget.files = fileBuffer.files
    this.currentFiles = fileBuffer.files

    if (fileBuffer.files.length === 0) {
      this.hiddenFieldTarget.disabled = false
    }
  }

  // What is happening here:
  // 1. compressFile is a function that accepts a image that needs to be compressed.
  // 2. We encapsulate the Compressor functionality in a Promise as it is an asynchronous function.
  // 3. In the Compressor method, we mention the quality to reduce to 20% of original (A 9.9 MB image gets reduced to 2.6 MB)
  // 4. We get the the compressed image as a Blob in the 'success' callback, convert the Blob to a File.
  compressFile = async (image) =>
    await new Promise((resolve) => {
      if (image.type === "image/gif") {
        // GIF compression is not supported
        // See https://github.com/fengyuanchen/compressorjs/issues/78#issuecomment-590231623
        return resolve(image)
      } else {
        return new Compressor(image, {
          quality: 0.2,
          success: (compressedFile) => {
            if (compressedFile instanceof File) {
              return resolve(compressedFile)
            } else {
              const compressedFileFromBlob = new File([compressedFile], image.name, {
                type: compressedFile.type,
              })
              return resolve(compressedFileFromBlob)
            }
          },
        })
      }
    })

  appendActiveStorageHiddenField(sgid) {
    const hiddenField = document.createElement("input")
    hiddenField.setAttribute("type", "hidden")
    hiddenField.setAttribute("value", sgid)
    hiddenField.name = this.uploadTarget.name
    hiddenField.dataset.activeStorage = "1"
    this.uploadTarget.insertAdjacentElement("beforebegin", hiddenField)
  }

  get useActiveStorageDirectUpload() {
    return !!this.activeStorageDirectUploadUrl
  }

  get activeStorageDirectUploadUrl() {
    return this.uploadTarget.dataset.directUploadUrl
  }
}
