import { Controller } from "@hotwired/stimulus"

// To use this controller you need to:
// 1. Add "data-controller": "filter" to the wrapper element of the entire section (filters and items)
// 2. Add "data-filter-target": "filterItem" to each item that will be filtered
// 3. Add "data-keywords": "string of keywords separated by spaces" to each item that will be filtered
// 4. Add "data-filter-target": "filterField" to each field that will be used to filter the items (e.g. input, select, etc.)
// 5. Add "data-filter-target": "noResultsMessage" to the element that will be shown when there are no results

// For very large lists:
// 1. Add "data-filter-max-results-value": "XX" to the wrapper element to limit the number of results (generally ~100)
// 2. Add a "data-filter-target": "searchRequiredMessage" to the element that will be shown when there are too many results

export default class extends Controller {
  static targets = ["filterItem", "filterField", "noResultsMessage", "searchRequiredMessage"]
  static values = { maxResults: Number }

  #filterItemVisibilityCache = new Map()

  connect() {
    this.filterFieldTargets.forEach((input) => {
      input.addEventListener("input", (e) => this.filter(e))
      input.addEventListener("change", (e) => this.filter(e))
    })

    this.filterItemTargets.forEach((item) => {
      this.#filterItemVisibilityCache.set(item, item.style.display !== "none")
    })

    if (this.maxResultsValue > 0) {
      // This is a big list

      this.filter = debounce(this.filter.bind(this))
    }
  }

  #setTargetsVisible(targets, visible) {
    const style = visible ? "" : "none"
    targets.forEach((target) => {
      target.style.display = style
    })
  }

  #showResults(visibleItems) {
    this.filterItemTargets.forEach((item) => {
      const wasVisible = this.#filterItemVisibilityCache.get(item)
      const isVisible = visibleItems.has(item)
      if (wasVisible === isVisible) return

      this.#filterItemVisibilityCache.set(item, isVisible)

      if (isVisible) {
        item.removeAttribute("data-filtered-out")
        item.style.display = ""
      } else {
        item.setAttribute("data-filtered-out", "")
        item.style.display = "none"
      }
    })
  }

  filter() {
    const filterTerms = this.filterFieldTargets
      .map((field) => field.value.toLowerCase().replace(/\s+/g, "")) // remove whitespace
      .filter((term) => term.length > 0)

    const visibleItems = new Set(
      this.filterItemTargets.filter((item) => {
        const keywords = item.dataset.keywords.toLowerCase().replace(/\s+/g, "")
        return filterTerms.every((filterTerm) => keywords.includes(filterTerm))
      })
    )

    if (this.maxResultsValue > 0 && visibleItems.size > this.maxResultsValue) {
      this.#setTargetsVisible(this.noResultsMessageTargets, false)
      this.#setTargetsVisible(this.searchRequiredMessageTargets, true)

      this.#showResults(new Set())

      this.filterItemTargets.forEach((item) => {
        item.setAttribute("data-filtered-out", "")
        item.style.display = "none"
      })
    } else {
      this.#setTargetsVisible(this.searchRequiredMessageTargets, false)
      this.#setTargetsVisible(this.noResultsMessageTargets, visibleItems.size === 0)

      this.#showResults(visibleItems)
    }
  }
}

function debounce(fn) {
  let timeout

  return () => {
    const later = () => {
      clearTimeout(timeout)
      fn()
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, 300)
  }
}
