import { Controller } from "@hotwired/stimulus"
import { t as globalT } from "helpers/i18n"

export default class extends Controller {
  static targets = ["rate", "button"]

  showAllRates(event) {
    const trigger = event.currentTarget

    if (trigger.innerText === globalT("js.users.shared.show_all")) {
      this.buttonTargets.forEach((button) => {
        button.style.display = "none"
      })
      this.rateTargets.forEach((rate) => {
        rate.style.display = ""
      })
      trigger.innerText = globalT("js.users.shared.hide_all")
    } else if (trigger.innerText === globalT("js.users.shared.hide_all")) {
      this.buttonTargets.forEach((button) => {
        button.style.display = ""
      })
      this.rateTargets.forEach((rate) => {
        rate.style.display = "none"
      })
      trigger.innerText = globalT("js.users.shared.show_all")
    }
  }
}
