import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  static targets = ["cities"]

  getCities(event) {
    const input = event.target
    const city = input.options[input.selectedIndex]

    Turbo.visit(city.dataset.citiesUrl, { frame: "cities" })
  }
}
