import { Controller } from "@hotwired/stimulus"
import I18n from "helpers/i18n"

export default class extends Controller {
  static targets = ["inputContainer", "searchInput", "dropdownIcon", "dropdown", "option", "allTeamsOption"]

  static values = {
    parentController: String,
    teamLocationLocalStorageKey: String,
    isFocused: Boolean,
    lastSearchInput: String,
    currentTeam: String,
  }

  t(key, options) {
    options = { scope: "js.dashboard.widgets.inc", ...options }
    return I18n.t(key, options)
  }

  connect() {
    this.getCurrentSelectionFromLocalStorage()
    this.searchInputTarget.value = this.currentTeamValue || this.t("filter_teams")
  }

  toggleDropdown() {
    this.isFocusedValue = !this.isFocusedValue
    if (this.isFocusedValue === true) {
      this.searchInputTarget.focus()
      this.searchInputTarget.value = this.lastSearchInputValue || ""
      if (this.searchInputTarget.value === "") {
        this.resetDropdownOptions()
      }
    } else {
      this.searchInputTarget.value = this.currentTeamValue || this.t("filter_teams")
    }
    this.inputContainerTarget.setAttribute("isFocused", this.isFocusedValue)
    const selectedOption = this.dropdownTarget.querySelector("[selected='true']")
    if (selectedOption) {
      this.dropdownTarget.scroll({ top: selectedOption.offsetTop, behavior: "instant" })
    }
  }

  filterDropdown(e) {
    this.lastSearchInputValue = e.target.value
    this.resetDropdownOptions()

    const re = new RegExp(this.lastSearchInputValue, "i")
    this.optionTargets.forEach((option) => {
      if (!option.innerText.trim().match(re)) {
        option.parentNode.style.display = "none"
      }
    })
  }

  resetDropdownOptions() {
    this.optionTargets.forEach((option) => {
      option.parentNode.style.display = "flex"
    })
  }

  updateSelection(e) {
    e.stopPropagation()
    e.preventDefault()
    if (this.hasAllTeamsOptionTarget) {
      // Managers of individual teams don't have access to all teams
      this.allTeamsOptionTarget.setAttribute("selected", false)
    }
    this.currentTeamValue = e.target.innerText
    this.searchInputTarget.value = this.currentTeamValue
    this.lastSearchInputValue = ""

    const selectedLocationId = e.target.dataset?.location_id || ""
    const selectedDepartmentId = e.target.dataset?.department_id || ""
    this.selectedLocationIdValue = selectedLocationId
    this.selectedDepartmentIdValue = selectedDepartmentId
    this.setCurrentSelectionToLocalStorage()

    if (!selectedLocationId && !selectedDepartmentId && this.hasAllTeamsOptionTarget) {
      // All Teams
      this.allTeamsOptionTarget.setAttribute("selected", true)
    }
    this.optionTargets.forEach((option) => {
      option.parentNode.setAttribute(
        "selected",
        option.dataset?.location_id === selectedLocationId || option.dataset?.department_id === selectedDepartmentId
      )
    })

    this.toggleDropdown()
    this.dispatch("refreshWidget", { detail: { selectedLocationId, selectedDepartmentId } })
  }

  setCurrentSelectionToLocalStorage() {
    if (this.selectedLocationIdValue) {
      localStorage.setItem(this.teamLocationLocalStorageKeyValue, `location_id=${this.selectedLocationIdValue}`)
    } else if (this.selectedDepartmentIdValue) {
      localStorage.setItem(this.teamLocationLocalStorageKeyValue, `department_id=${this.selectedDepartmentIdValue}`)
    } else {
      localStorage.setItem(this.teamLocationLocalStorageKeyValue, "all_teams=true")
    }
  }

  getCurrentSelectionFromLocalStorage() {
    const teamLocationLocalStorageItem = localStorage.getItem(this.teamLocationLocalStorageKeyValue)
    if (teamLocationLocalStorageItem) {
      const departmentOrLocation = teamLocationLocalStorageItem.match(/^[^=]*/)
      const teamId = teamLocationLocalStorageItem.match(/=(.*)/)[1]
      const element = this.dropdownTarget.querySelector(`[data-${departmentOrLocation}="${teamId}"]`)
      if (element) {
        this.currentTeamValue = element.innerText
        element.parentNode.setAttribute("selected", true)
        return
      }
      if (this.hasAllTeamsOptionTarget) {
        // Managers of individual teams don't have access to all teams
        this.allTeamsOptionTarget.setAttribute("selected", true)
      }
    }
  }
}
