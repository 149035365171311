import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["maxHours", "accrualMethods", "accrualRate"]

  showHoursWorkedOptions() {
    this.show(this.maxHoursTarget)
    this.enable(this.maxHoursTarget)

    this.show(this.accrualRateTarget)
    this.enable(this.accrualRateTarget)

    this.hide(this.accrualMethodsTarget)
    this.disable(this.accrualMethodsTarget)
  }

  showFixedAmountOptions() {
    this.hide(this.maxHoursTarget)
    this.disable(this.maxHoursTarget)

    this.hide(this.accrualRateTarget)
    this.disable(this.accrualRateTarget)

    this.show(this.accrualMethodsTarget)
    this.enable(this.accrualMethodsTarget)
  }

  enable(element) {
    element.disabled = false
  }

  disable(element) {
    element.disabled = true
  }

  show(element) {
    element.style.display = ""
    element.ariaPressed = true
  }

  hide(element) {
    element.style.display = "none"
    element.ariaPressed = true
  }
}
