import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    departments: Object,
    restrictedTo: String,
  }

  static targets = ["restrictedToSelect", "locationWrapper", "teamWrapper"]

  connect() {
    if (this.restrictedToValue === "all_locations") {
      this.locationWrapperTarget.classList.add("hidden")
      this.teamWrapperTarget.classList.add("hidden")
    } else if (this.restrictedToValue === "by_location") {
      this.locationWrapperTarget.classList.remove("hidden")
      this.teamWrapperTarget.classList.add("hidden")
    } else if (this.restrictedToValue === "by_department") {
      this.locationWrapperTarget.classList.add("hidden")
      this.teamWrapperTarget.classList.remove("hidden")
    } else {
      this.locationWrapperTarget.classList.add("hidden")
      this.teamWrapperTarget.classList.add("hidden")
    }
  }

  onRestrictedToChange(event) {
    if (event.target.value === "all_locations") {
      this.locationWrapperTarget.classList.add("hidden")
      this.teamWrapperTarget.classList.add("hidden")
    } else if (event.target.value === "by_location") {
      this.locationWrapperTarget.classList.remove("hidden")
      this.teamWrapperTarget.classList.add("hidden")
    } else {
      this.locationWrapperTarget.classList.add("hidden")
      this.teamWrapperTarget.classList.remove("hidden")
    }
  }
}
