import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]
  static values = { option: String }

  connect() {
    const thisSelect = this.element.querySelector("select")
    thisSelect.addEventListener("change", (event) => this.toggleContent(event))
  }

  toggleContent(event) {
    if (event.target.value === this.optionValue) {
      this.contentTarget.classList.replace("grid-rows-[0fr]", "grid-rows-[1fr]")
    } else {
      this.contentTarget.classList.replace("grid-rows-[1fr]", "grid-rows-[0fr]")
    }
  }
}
