import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["name"]

  onTemplateChange(e) {
    const title = e.target.tagName === "H6" ? e.target.innerText : e.target.querySelector("h6").innerText
    this.nameTarget.value = title
  }
}
