// Used as an overflow menu. See Design Documentation for more details.

import { Controller } from "@hotwired/stimulus"
import { autoUpdate, computePosition, flip, offset } from "@floating-ui/dom"

export default class extends Controller {
  static targets = [
    "trigger",
    "menu",
    "reference",
    // Header kept at the closest position to the reference, inside the menu
    "header",
    "headerBottomContainer",
    "headerTopContainer",
  ]
  static values = { startOpen: Boolean }

  connect() {
    this.boundCloseOnOutsideClick = this.closeOnOutsideClick.bind(this)
    this.boundForceClose = this.forceClose.bind(this)

    document.addEventListener("turbo:before-morph-element", this.boundForceClose)
    document.addEventListener("click", this.boundCloseOnOutsideClick)
    if (this.startOpenValue) {
      this.open()
    }
  }

  disconnect() {
    document.removeEventListener("turbo:before-morph-element", this.boundForceClose)
    document.removeEventListener("click", this.boundCloseOnOutsideClick)
  }

  get reference() {
    return this.hasReferenceTarget ? this.referenceTarget : this.element
  }

  get isOpen() {
    return this.menuTarget.open
  }

  getItems() {
    return Array.from(this.menuTarget.querySelectorAll("[tabindex]:not([tabindex='-1'])"))
  }

  toggleMenu() {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  closeOnOutsideClick(e) {
    // Trix has this bug where when you click teh attach button, it adds an <input type=file id=undefined> to document.body,
    // then triggers a click on that. That would cause the modal to exit incorrectly here. So we explicitly ignore that case.
    // See https://github.com/basecamp/trix/pull/1135, https://github.com/basecamp/trix/issues/1039
    if (e.target.id === "undefined" && e.target.parentElement === document.body) return
    if (e.detail === 0) return

    if (this.isOpen && !this.element.contains(e.target)) {
      // Pass the event down, in case dropdownCloseDisabled has been set.
      this.close(e)
    }
  }

  async open() {
    const isDialogElement = this.menuTarget.tagName === "DIALOG"

    if (this.hasTriggerTarget) {
      this.triggerTarget.setAttribute("aria-expanded", "true")
    }

    if (isDialogElement) {
      this.menuTarget.style.opacity = 0
      this.menuTarget.style.transform = "translateY(-4px)"
    }

    await this.updateMenuPosition()

    if (this.menuTarget.showModal) {
      this.menuTarget.showModal()
    }

    if (isDialogElement) {
      this.menuTarget.animate([{ opacity: 1, transform: "translateY(0)" }], { duration: 100 }).finished.then(() => {
        this.menuTarget.style.opacity = ""
        this.menuTarget.style.transform = ""
      })
    }

    if (document.activeElement === this.menuTarget) {
      this.getItems().at(0)?.focus()
    }

    this.stopAutoUpdate = autoUpdate(this.reference, this.menuTarget, this.updateMenuPosition.bind(this))
  }

  close(e) {
    if (e?.dropdownCloseDisabled) return

    this.menuTarget
      .animate([{ opacity: 0, transform: "translateY(-4px)" }], { duration: 100 })
      .finished.then((animation) => {
        // animation.commitStyles()
        this.menuTarget.style.display = ""
        this.cleanupAfterClose()
        this.menuTarget.close()
      })
      .catch((e) => {
        console.error(e)
        this.cleanupAfterClose()
        this.menuTarget.close()
      })
    this.element.id && this.dispatch(`dropdown-menu-closed-${this.element.id}`)
  }

  forceClose() {
    if (this.isOpen) {
      if (this.hasMenuTarget) {
        this.menuTarget.style.display = ""
      }
      this.cleanupAfterClose()
      if (this.hasMenuTarget && this.menuTarget.close && typeof this.menuTarget.close === "function") {
        this.menuTarget.close()
      }
    }
  }

  preventClose(e) {
    e.dropdownCloseDisabled = true
  }

  cleanupAfterClose() {
    this.stopAutoUpdate?.()

    if (this.hasTriggerTarget) {
      this.triggerTarget.setAttribute("aria-expanded", "false")
    }
  }

  async updateMenuPosition() {
    const position = await computePosition(this.reference, this.menuTarget, {
      placement: "bottom-end",
      strategy: "fixed",
      middleware: [
        offset({
          mainAxis: 8,
        }),
        flip({
          padding: 16,
        }),
      ],
    })

    this.menuTarget.style.top = `${position.y}px`
    this.menuTarget.style.left = `${position.x}px`

    this.updatePlacement(position.placement.split("-", 1)[0])
  }

  updatePlacement(placement) {
    if (this.placement === placement) return
    this.placement = placement

    const focusedElement = document.activeElement

    if (placement === "bottom") {
      if (this.hasHeaderTopContainerTarget) {
        this.headerTopContainerTarget.append(this.headerTarget)
      }
    } else {
      if (this.hasHeaderBottomContainerTarget) {
        this.headerBottomContainerTarget.append(this.headerTarget)
      }
    }

    if (document.activeElement !== focusedElement) {
      focusedElement.focus()
    }
  }

  moveFocus(event) {
    const items = this.getItems()

    if (items.length === 0) return
    if (!["ArrowDown", "ArrowUp", "Home", "End"].includes(event.key)) return

    let focusedIndex = items.indexOf(event.target)

    if (focusedIndex === -1) {
      if (this.placement === "bottom") {
        focusedIndex = 0
      } else {
        focusedIndex = items.length - 1
      }
    }

    if (event.key === "ArrowDown") {
      focusedIndex++
    } else if (event.key === "ArrowUp") {
      focusedIndex--
    } else if (event.key === "Home") {
      focusedIndex = 0
    } else if (event.key === "End") {
      focusedIndex = items.length - 1
    }

    event.preventDefault()

    focusedIndex = focusedIndex % items.length
    if (focusedIndex < 0) focusedIndex += items.length

    const element = items[focusedIndex]
    element.focus()
  }
}
