import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mcq", "text", "freetext"]
  static values = {
    item: Object,
  }

  connect() {
    this.showHide({ target: { value: this.itemValue.item_type } })
  }

  showHide = (e) => {
    switch (e.target.value) {
      case "mcq":
        this.mcqTarget.classList.remove("hidden")
        this.textTarget.classList.add("hidden")
        break
      case "text":
        this.mcqTarget.classList.add("hidden")
        this.textTarget.classList.remove("hidden")
        break
      case "free_text":
        this.mcqTarget.classList.add("hidden")
        this.textTarget.classList.add("hidden")
        break
    }
  }
}
