import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "ratingScale", "allowComments"]
  static values = {
    item: Object,
  }

  connect() {
    this.inputTarget.style.resize = "none"
    this.inputTarget.style.minHeight = `${this.inputTarget.scrollHeight}px`

    if (this.itemValue.item_type === "rating_scale") {
      this.ratingScaleTarget.classList.remove("hidden")
    } else {
      this.ratingScaleTarget.classList.add("hidden")
    }

    if (["rating", "rating_scale"].includes(this.itemValue.item_type)) {
      this.allowCommentsTarget.classList.remove("hidden")
    } else {
      this.allowCommentsTarget.classList.add("hidden")
    }
  }

  onItemTypeChange = (e) => {
    if (e.target.value === "rating_scale") {
      this.ratingScaleTarget.classList.remove("hidden")
    } else {
      this.ratingScaleTarget.classList.add("hidden")
    }

    if (["rating", "rating_scale"].includes(e.target.value)) {
      this.allowCommentsTarget.classList.remove("hidden")
    } else {
      this.allowCommentsTarget.classList.add("hidden")
    }
  }

  resize(event) {
    event.target.style.height = "5px"
    event.target.style.height = `${event.target.scrollHeight}px`
  }
}
