import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "enrolmentCheckbox",
    "postponeAutomaticEnrolmentCheckbox",
    "postponeDateFieldContainer",
    "postponeDateField",
  ]

  connect() {
    if (this.postponeDateFieldTarget.value !== "") {
      this.enrolmentCheckboxTargets.forEach((target) => {
        target.checked = false
      })
      this.postponeAutomaticEnrolmentCheckboxTarget.checked = true
      this.togglePostponeDateField()
    }
  }

  uncheckOtherCheckboxes(e) {
    const targetCheckboxChecked = e.target.checked
    this.enrolmentCheckboxTargets.forEach((target) => {
      target.checked = false
    })
    e.target.checked = targetCheckboxChecked
    this.togglePostponeDateField()
  }

  togglePostponeDateField() {
    if (this.postponeAutomaticEnrolmentCheckboxTarget.checked) {
      this.postponeDateFieldContainerTarget.classList.remove("hidden")
    } else {
      this.postponeDateFieldContainerTarget.classList.add("hidden")
      this.postponeDateFieldTarget.value = ""
    }
  }
}
