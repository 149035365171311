import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["progressElement"]
  static values = {
    statusUrl: String,
    key: String,
    refreshInterval: Number,
  }

  #timeout

  connect() {
    this.#timeout = setTimeout(() => this.refreshProgress(), this.refreshIntervalValue)
  }

  disconnect() {
    clearTimeout(this.#timeout)
    this.#timeout = null
  }

  refreshProgress() {
    const url = new URL(this.statusUrlValue)
    url.searchParams.set("key", this.keyValue)

    fetch(url, {
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const error = data.error
        if (error) {
          this.dispatch(`error-${this.keyValue}`, { detail: { cache_key: this.keyValue } })
          return
        }
        const progress = data.progress
        this.progressElementTarget.setAttribute("value", progress)
        if (progress === 100) {
          this.dispatch(`complete-${this.keyValue}`, { detail: { cache_key: this.keyValue } })
        } else {
          this.#timeout = setTimeout(() => this.refreshProgress(), this.refreshIntervalValue)
        }
      })
  }
}
