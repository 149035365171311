import { Controller } from "@hotwired/stimulus"
import I18n from "helpers/i18n"
import * as Routes from "helpers/routes"

export default class extends Controller {
  initialize() {
    this.ssoLogin = false
    // button to Log in with SSO
    this.btnTo = document.getElementById("toggleToSSO")
    // button to Log in with password
    this.btnAway = document.getElementById("toggleAwayFromSSO")

    this.hide(this.btnTo)
    this.hide(this.btnAway)

    this.password = document.getElementById("password-wrapper") // the wrapper which includes label and input (aka the text input design component)
    this.pwdInput = document.getElementById("password") // the actual input field
    this.btnLogin = document.getElementById("login_button")
  }

  ssoEmailHandler() {
    const email = document.getElementById("email")
    const ssoController = this

    if (email.value.toLowerCase().match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g) != null) {
      Request.post(Routes.check_is_sso_user_path(), { auth_user: { email: email.value } }).then(function (response) {
        if (response.data.sso_user === true) {
          // internal login
          if (email.value.toLowerCase().match(/^[a-zA-Z0-9_.+-]+@(tanda.co|workforce.com)$/g) != null) {
            ssoController.ssoLoginOff()
            ssoController.show(ssoController.btnTo)
            ssoController.hide(ssoController.btnAway)
            ssoController.show(ssoController.password)

            if (email.value.toLowerCase().match(/^[a-zA-Z0-9_.+-]+@(tanda.co)$/g)) {
              ssoController.btnLogin.setAttribute(
                "value",
                I18n.t("js.devise.form.team_login", { company_name_title: "Tanda" })
              )
            } else if (email.value.toLowerCase().match(/^[a-zA-Z0-9_.+-]+@(workforce.com)$/g)) {
              ssoController.btnLogin.setAttribute(
                "value",
                I18n.t("js.devise.form.team_login", { company_name_title: "Workforce" })
              )
            } else {
              ssoController.btnLogin.setAttribute("value", I18n.t("js.devise.form.internal_login"))
            }
          } else {
            ssoController.showSSO()
          }
        }
      })
    } else {
      ssoController.ssoLoginOff()
      ssoController.disableSSO()
    }
  }

  // click "Log in with SSO"
  showSSO() {
    this.ssoLoginOn()
    this.hide(this.btnTo)
    this.show(this.btnAway)

    // don't submit password to SSO
    this.hide(this.password)
    this.pwdInput.disabled = true

    this.btnLogin.setAttribute("value", I18n.t("js.devise.form.sso_login"))
  }

  // click "Log in with password"
  showSession() {
    this.ssoLoginOff()
    this.show(this.btnTo)
    this.hide(this.btnAway)

    this.pwdInput.disabled = false
    this.show(this.password)

    this.btnLogin.setAttribute("value", I18n.t("js.devise.form.login"))
  }

  // this is if they shouldn't be able to login with SSO vs just switching between SSO and Password login option
  disableSSO() {
    this.pwdInput.disabled = false
    this.show(this.password)

    this.hide(this.btnTo)
    this.hide(this.btnAway)

    this.btnLogin.setAttribute("value", I18n.t("js.devise.form.login"))
  }

  show(element) {
    element.classList.remove("hidden")
    element.classList.display = "block"
  }

  hide(element) {
    element.classList.add("hidden")
    element.classList.display = "none"
  }

  ssoLoginOn() {
    this.ssoLogin = true
  }

  ssoLoginOff() {
    this.ssoLogin = false
  }
}
