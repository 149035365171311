import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["staticData", "editableData"]

  showEditableData() {
    this.staticDataTarget.classList.add("hidden")
    this.editableDataTarget.classList.remove("hidden")
  }

  showStaticData() {
    this.editableDataTarget.classList.add("hidden")
    this.staticDataTarget.classList.remove("hidden")
  }
}
