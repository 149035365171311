// @flow
import { StreamActions } from "@hotwired/turbo"
import IFrameController from "../stimulus/shared_controllers/iframe_controller"

// https://marcoroth.dev/posts/guide-to-custom-turbo-stream-actions
StreamActions.scrollIntoView = function () {
  requestAnimationFrame(() => {
    const element = document.getElementById(this.getAttribute("target"))
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  })
}

// Used by app/views/leave_requests/iframe.html.haml
// Sends a message to the parent that rendered the iframe, to tell it that the iframe can be dismissed.
StreamActions.dismissIframe = function () {
  IFrameController.dismiss()
}

// https://discuss.hotwired.dev/t/event-to-know-a-turbo-stream-has-been-rendered/1554/25
const afterRenderEvent = new Event("turbo:after-stream-render")
window.addEventListener("turbo:before-stream-render", (event) => {
  const originalRender = event.detail.render

  event.detail.render = function (streamElement) {
    originalRender(streamElement)
    window.dispatchEvent(afterRenderEvent)
  }
})
