import { Controller } from "@hotwired/stimulus"

// A controller to assist with the use of app/assets/webpack/web_components/wf-modal.js
export default class extends Controller {
  static targets = ["modal"]
  static values = { open: Boolean }

  connect() {
    if (this.openValue) {
      this.modalTarget.openModal()
    }
  }

  openModal() {
    this.modalTarget.openModal()
  }
}
