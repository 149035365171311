import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    ids: Array, // the ids of each toggle content
  }

  // if input fields exist, clear them when toggled away from
  // use case: Payroll User Deductions (see https://github.com/TandaHQ/payaus/pull/22522)
  clearInputs(event) {
    this.idsValue.forEach((id) => {
      const cid = document.getElementById(id)

      // if the content is hidden then input fields are not visible and should be cleared
      // prevents active toggle content from clearing input fields (mainly if user re-clicks on active toggle)
      if (cid.classList.contains("hidden")) {
        const contentInputFields = cid.querySelectorAll("input")
        if (contentInputFields.length > 0) {
          contentInputFields.forEach((input) => {
            input.value = ""
          })
        }
      }
    })
  }
}
