import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    addEventListener("turbo:morph-element", this.fixTemplateMorph)
    addEventListener("turbo:before-morph-element", this.fixActionTextMorph)
  }

  disconnect() {
    removeEventListener("turbo:morph-element", this.fixTemplateMorph)
    removeEventListener("turbo:before-morph-element", this.fixActionTextMorph)
  }

  // See https://github.com/hotwired/turbo/issues/1087#issuecomment-2051982922
  // This can be removed once the fix in https://github.com/bigskysoftware/idiomorph/pull/49 is upstreamed in Turbo
  fixTemplateMorph(event) {
    if (event.target instanceof HTMLTemplateElement && event.detail.newElement instanceof HTMLTemplateElement) {
      // eslint-disable-next-line no-unsanitized/property
      event.target.innerHTML = event.detail.newElement.innerHTML
    }
  }

  // See https://github.com/hotwired/turbo-rails/issues/533#issuecomment-2063633857
  fixActionTextMorph(event) {
    if (event.target.tagName === "TRIX-EDITOR" || event.target.tagName === "TRIX-TOOLBAR") {
      event.preventDefault()
    }
  }
}
