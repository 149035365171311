import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "revertButton", "saveButton", "editButton"]

  disableForm(e) {
    if (this.formTarget.checkValidity() === true) {
      this.revertButtonTarget.inert = true
      this.saveButtonTarget.inert = true
      this.editButtonTarget.inert = true
      this.formTarget.classList.add("opacity-50", "transition-opacity", "pointer-events-none")
    }
  }

  toggleForm(e) {
    e.preventDefault()
    const { locationId } = e.params

    this.toggleButtonVisibility()

    const [stats, inputs] = this.getTableValues(locationId)
    this.toggleInputs(stats, inputs)
    this.resetInputs(stats, inputs)
  }

  toggleButtonVisibility() {
    this.revertButtonTarget.classList.toggle("hidden")
    this.saveButtonTarget.classList.toggle("hidden")
    this.editButtonTarget.classList.toggle("hidden")
  }

  getTableValues(locationId) {
    const table = document.getElementById(`budgets-for-location-${locationId}`)

    const stats = table.querySelectorAll("[data-budget-stat]")
    const inputs = table.querySelectorAll("[data-budget-input]")

    return [stats, inputs]
  }

  toggleInputs(stats, inputs) {
    inputs.forEach((input, index) => {
      if (input.dataset.budgetEditable === "true") {
        input.classList.toggle("hidden")
        stats[index].classList.toggle("hidden")
      }
    })
  }

  resetInputs(stats, inputs) {
    inputs.forEach((input, currentIndex) => {
      input.value = stats[currentIndex].dataset.budgetStat
    })
  }
}
