import { Controller } from "@hotwired/stimulus"
import { t as globalT } from "helpers/i18n"

export default class extends Controller {
  static targets = [
    "start",
    "finish",
    "shiftLength",
    "paidShiftLength",
    "breakStart",
    "breakFinish",
    "paidBreak",
    "breakLength",
    "paidBreakLength",
    "unpaidBreakLength",
  ]

  connect() {
    this.handleOvernight()
  }

  handleOvernight() {
    this.preventEmptyShiftValues()
    if (this.startValue === "" || this.finishValue === "") return

    this.setShiftLength()
    if (this.hasPaidBreakLengthTarget) {
      this.setBreakLength()
    }
    this.handleShiftOvernight()
    this.handleBreakChange()
  }

  preventEmptyShiftValues() {
    let startValidity = ""
    let finishValidity = ""

    if (this.startValue === "") {
      this.startTarget.classList.add("!border-2")
      this.startTarget.classList.add("border-red-700")
      startValidity = globalT("js.users.new_user_profile.regular_hours.validations.enter_start_time")
    } else {
      this.startTarget.classList.remove("!border-2")
      this.startTarget.classList.remove("border-red-700")
      startValidity = ""
    }

    if (this.finishValue === "") {
      this.finishTarget.classList.add("!border-2")
      this.finishTarget.classList.add("border-red-700")
      finishValidity = globalT("js.users.new_user_profile.regular_hours.validations.enter_finish_time")
    } else {
      this.finishTarget.classList.remove("!border-2")
      this.finishTarget.classList.remove("border-red-700")
      finishValidity = ""
    }

    this.startTarget.setCustomValidity(startValidity)
    this.finishTarget.setCustomValidity(finishValidity)
  }

  handleShiftOvernight() {
    const { startTime, finishTime } = this.startAndFinishTimes(this.startValue, this.finishValue)

    if (finishTime <= startTime) {
      this.finishTarget.classList.add("!border-2")
      this.finishTarget.classList.add("border-blue-800")
    } else {
      this.finishTarget.classList.remove("!border-2")
      this.finishTarget.classList.remove("border-blue-800")
    }
  }

  handleBreakChange() {
    this.breakStartTargets.forEach((breakStartTarget, index) => {
      const breakFinishTarget = this.breakFinishTargets[index]

      if (!breakStartTarget.value || !breakFinishTarget.value || breakStartTarget.value === breakFinishTarget.value) {
        breakFinishTarget.classList.remove("!border-2")
        breakFinishTarget.classList.remove("border-blue-800")
      }

      const { startTime: breakStartTime, finishTime: breakFinishTime } = this.startAndFinishTimes(
        breakStartTarget.value,
        breakFinishTarget.value
      )

      this.keepBreaksInsideShift(breakStartTarget, breakFinishTarget, this.startValue, this.finishValue)

      if (breakFinishTime <= breakStartTime) {
        breakFinishTarget.classList.add("!border-2")
        breakFinishTarget.classList.add("border-blue-800")
      } else {
        breakFinishTarget.classList.remove("!border-2")
        breakFinishTarget.classList.remove("border-blue-800")
      }
    })
  }

  setShiftLength() {
    const { totalHours, totalPaidHours } = this.shiftLength

    this.shiftLengthTarget.innerText = totalHours

    if (this.hasPaidShiftLengthTarget) {
      this.paidShiftLengthTarget.innerText = totalPaidHours
    }
  }

  keepBreaksInsideShift(breakStartTarget, breakFinishTarget, shiftStartValue, shiftFinishValue) {
    const { startTime: breakStartTime, finishTime: breakFinishTime } = this.startAndFinishTimes(
      breakStartTarget.value,
      breakFinishTarget.value
    )
    const { startTime: shiftStartTime, finishTime: shiftFinishTime } = this.startAndFinishTimes(
      shiftStartValue,
      shiftFinishValue
    )

    const shiftIsOvernight = shiftFinishTime <= shiftStartTime
    const breakStartsBeforeShiftStarts = breakStartTime < shiftStartTime
    const breakFinishesBeforeShiftStarts = breakFinishTime < shiftStartTime
    const breakStartsAfterShiftFinishes = breakStartTime > shiftFinishTime
    const breakFinishesAfterShiftFinishes = breakFinishTime > shiftFinishTime

    if (
      (shiftIsOvernight && breakStartsBeforeShiftStarts && breakStartsAfterShiftFinishes) ||
      (shiftIsOvernight && breakFinishesBeforeShiftStarts && breakFinishesAfterShiftFinishes) ||
      (!shiftIsOvernight &&
        (breakStartsBeforeShiftStarts ||
          breakFinishesBeforeShiftStarts ||
          breakStartsAfterShiftFinishes ||
          breakFinishesAfterShiftFinishes))
    ) {
      breakStartTarget.value = shiftStartValue
      breakFinishTarget.value = shiftFinishValue
    }
  }

  setBreakLength() {
    const { breakLengths, paidBreakTotal, unpaidBreakTotal } = this.breakLength

    breakLengths.forEach((breakLength, index) => {
      this.breakLengthTargets[index].innerText = this.convertHoursToMinutes(breakLength)
    })

    this.paidBreakLengthTarget.innerText = this.convertHoursToMinutes(paidBreakTotal)
    this.unpaidBreakLengthTarget.innerText = this.convertHoursToMinutes(unpaidBreakTotal)
  }

  startAndFinishTimes(start, finish) {
    const startHour = parseInt(start.split(":")[0])
    const startMinute = parseInt(start.split(":")[1])
    const finishHour = parseInt(finish.split(":")[0])
    const finishMinute = parseInt(finish.split(":")[1])

    const startTime = startHour + startMinute / 60
    const finishTime = finishHour + finishMinute / 60

    return { startTime, finishTime }
  }

  convertHoursToMinutes(hours) {
    return Math.round(hours * 60) + "m"
  }

  roundedHours(hours) {
    return Math.round(Math.abs(hours) * 100) / 100
  }

  get startValue() {
    return this.startTarget.value
  }

  get finishValue() {
    return this.finishTarget.value
  }

  get breakLength() {
    if (!this.breakStartTargets || !this.breakFinishTargets) return

    let paidBreakTotal = 0
    let unpaidBreakTotal = 0

    const breakLengths = this.breakStartTargets.map((breakStartTarget, index) => {
      const breakFinishTarget = this.breakFinishTargets[index]

      if (!breakStartTarget.value || !breakFinishTarget.value || breakStartTarget.value === breakFinishTarget.value) {
        return 0
      }

      const { startTime, finishTime } = this.startAndFinishTimes(breakStartTarget.value, breakFinishTarget.value)
      const paidBreak = this.paidBreakTargets[index].checked
      const breakTotal =
        startTime >= finishTime ? Math.abs(startTime - finishTime - 24) : Math.abs(finishTime - startTime)

      if (paidBreak) {
        paidBreakTotal += breakTotal
      } else {
        unpaidBreakTotal += breakTotal
      }

      return breakTotal
    })

    return { breakLengths, paidBreakTotal, unpaidBreakTotal }
  }

  get shiftLength() {
    let totalHours = 0
    let totalPaidHours = 0

    if (this.startValue && this.finishValue) {
      const { startTime, finishTime } = this.startAndFinishTimes(this.startValue, this.finishValue)
      const unpaidBreakLength = this.breakLength.unpaidBreakTotal

      if (startTime >= finishTime) {
        totalHours = startTime - finishTime - 24
        totalPaidHours = Math.abs(totalHours) - unpaidBreakLength
      } else {
        totalHours = finishTime - startTime
        totalPaidHours = Math.abs(totalHours) - unpaidBreakLength
        totalPaidHours = Math.round(totalPaidHours * 100) / 100
      }

      totalHours = this.roundedHours(totalHours)
      totalPaidHours = this.roundedHours(totalPaidHours)
    }
    return { totalHours, totalPaidHours }
  }
}
