import { Controller } from "@hotwired/stimulus"
// import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  // "toggle" is the CSS class that we will apply to the target HTML element
  static classes = ["toggle"]
  // "element" is the target element (e.g. <button>, <input>) that will receive the "toggle" CSS class
  static targets = ["element"]

  addClass() {
    this.elementTargets.forEach((element) => element.classList.add(this.toggleClass))
  }

  removeClass() {
    this.elementTargets.forEach((element) => element.classList.remove(this.toggleClass))
  }
}
