import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["conditional"]

  change(event) {
    this.hideConditionalFields()
    const linkedField = this.conditionalTargets.find((field) => field.dataset.value === event.target.value)
    if (linkedField) {
      linkedField.classList.replace("grid-rows-[0fr]", "grid-rows-[1fr]")
      linkedField.disabled = false
    }
  }

  hideConditionalFields() {
    this.conditionalTargets.forEach((field) => {
      field.classList.replace("grid-rows-[1fr]", "grid-rows-[0fr]")
      field.disabled = true
    })
  }
}
