import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "item", "answerType", "multipleChoice", "linkToPayroll", "payrollEarningsRate"]
  static values = {
    item: Object,
  }

  connect() {
    this.inputTarget.style.resize = "none"
    this.inputTarget.style.minHeight = `${this.inputTarget.scrollHeight}px`

    this.showHideMultipleChoice(this.itemValue.item_type)

    const payrollItemType = this.itemValue.payroll_item_type

    if (payrollItemType && payrollItemType !== "not_payroll_applicable") {
      this.showAll([this.linkToPayrollTarget])

      if (payrollItemType === "allowance") {
        this.showAll([this.payrollEarningsRateTarget])
      }
    } else {
      this.hideAll([this.linkToPayrollTarget])
    }
  }

  onItemTypeChange = (e) => {
    this.showHideMultipleChoice(e.target.value)
  }

  onLinkToPayrollChange = (e) => {
    if (e.target.checked) {
      this.showAll([this.linkToPayrollTarget])
    } else {
      this.hideAll([this.linkToPayrollTarget])
    }

    this.checkUncheckRadio()
  }

  onPayrollItemTypeChange = (e) => {
    if (e.target.value === "reimbursement") {
      this.answerTypeTarget.value = "currency"
      this.hideAll([this.payrollEarningsRateTarget])
    } else if (e.target.value === "allowance") {
      this.answerTypeTarget.value = "number"
      this.showAll([this.payrollEarningsRateTarget])
    }

    this.showHideMultipleChoice(this.answerTypeTarget)
  }

  showHideMultipleChoice = (value) => {
    if (value === "multiple_choice") {
      this.showAll(this.multipleChoiceTargets)
    } else {
      this.hideAll(this.multipleChoiceTargets)
    }
  }

  checkUncheckRadio = () => {
    if (!this.linkToPayrollTarget.querySelector("input[type=radio]:checked")) {
      this.linkToPayrollTarget.querySelector("input[type=radio]").checked = true
      this.linkToPayrollTarget.querySelector("input[type=radio]").required = true
    } else {
      this.linkToPayrollTarget.querySelectorAll("input[type=radio]").forEach((input) => {
        input.checked = false
      })
      this.linkToPayrollTarget.querySelector("input[type=radio]").required = false
    }
  }

  showAll = (targets) => {
    targets.forEach((target) => {
      target.classList.remove("hidden")
    })
  }

  hideAll = (targets) => {
    targets.forEach((target) => {
      target.classList.add("hidden")
    })
  }

  resize(event) {
    event.target.style.height = "5px"
    event.target.style.height = `${event.target.scrollHeight}px`
  }
}
