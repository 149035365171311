import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  selfDestruct(e) {
    // This looks a bit weird but it's intentional
    // We use the 0 millisecond setTimeout so that
    // the current event loop can finish, which includes
    // submitting any forms, before we remove the element
    setTimeout(() => {
      this.element.remove()
    })
  }
}
