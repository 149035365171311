import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const dateInput = this.element.querySelector("input[type='date']")
    dateInput.addEventListener("input", this.checkDate.bind(this))
  }

  checkDate(e) {
    const date = new Date(e.target.value)
    const day = date.getDate().toString().padStart(2, "0")
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const maxDate = new Date("2500-12-01")

    if (date > maxDate) {
      e.target.value = `${new Date().getFullYear()}-${month}-${day}`
    }
  }
}
