// To use this controller you need to:
// 1. Add "data-controller": "search" to the wrapper element of the entire section (filters and items)
// 2. Add "data-search-target": "searchInput" to your search input text
// 3. Add "data-search-target": "searchSelect" to your dropdown

/**
 * Usage:
 * This controller will update the URL based on the search text or the selected dropdown value.
 * The search text will be appended as '?search=your_search_text'.
 * The selected dropdown value will update the URL by appending '?field_name=selected_value'
 * These appended search params will be submitted via turbo to the server and will update the turbo frame
 */

import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"

export default class extends Controller {
  static targets = ["form", "searchInput", "searchSelect"]

  connect() {
    this.search = debounce(this.search, 500).bind(this)

    // When there is a search keyword and the turbo frame is updated, the focus moves to the start of input instead of the end
    // autofocus=true or .focus() does not solve the problem
    // https://stackoverflow.com/questions/511088/use-javascript-to-place-cursor-at-end-of-text-in-text-input-element
    if (this.searchInputTarget.value) {
      setTimeout(() => {
        this.searchInputTarget.selectionStart = 10000
        this.searchInputTarget.selectionEnd = 10000
        this.searchInputTarget.focus()
      }, 0)
    }

    this.searchInputTarget.addEventListener("input", (e) => this.search(e))
    this.searchSelectTargets.forEach((input) => {
      input.addEventListener("change", (e) => this.search(e))
    })
  }

  search(e) {
    this.formTarget.requestSubmit()
  }
}
