import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["start", "finish"]

  updateFinish() {
    if (!this.startValue) return

    this.finishTarget.value = this.startValue
  }

  get startValue() {
    return this.startTarget.value
  }
}
