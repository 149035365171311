import { escapeHTML } from "helpers/dom"

export const flash = (message, type = "notice") => {
  const iconPath = type === "notice" ? "M5 13l4 4L19 7" : "M6 18L18 6M6 6l12 12"
  const flashIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="h-5 w-5" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="${iconPath}" />
    </svg>`
  const textClasses = {
    notice: "text-green-900 dark:text-darkmode-green-400",
    error: "text-red-600 dark:text-darkmode-red-400",
  }
  const flashDialog = `<dialog role="alert" class="flash animate__animated animate__faster animate__delay-3s animate__fadeOut overflow-hidden px-4 shadow-md rounded-lg py-3 pr-5 fixed top-[-80%]" data-turbo-cache="false">
      <div class="animate__animated animate__fadeInUp animate__faster flex items-center space-x-1.5 pointer-events-auto focus:outline-none">
        <div class="flex-shrink-0 ${textClasses[type]}">
          ${flashIcon}
        </div>
        <p class="text-base font-medium ${textClasses[type]}">
          ${escapeHTML(message)}
        </p>
      <div>
    </div>`

  const flashContainer = document.getElementById("flash")
  // eslint-disable-next-line no-unsanitized/property
  flashContainer.innerHTML = flashDialog

  const dialog = flashContainer.querySelector("dialog")

  if (type === "notice") {
    dialog.classList.add("notice")
  } else {
    dialog.classList.add("error")
  }

  dialog.showModal()
  dialog.addEventListener("click", () => {
    dialog.close()
  })
  dialog.addEventListener("animationend", () => {
    setTimeout(function () {
      dialog.remove()
    }, 4000)
  })
}
