import { Controller } from "@hotwired/stimulus"

const updateInputWithNewDateOptions = (inputElement, newDateOptions) => {
  inputElement.innerHTML = ""

  newDateOptions.forEach((option) => {
    const optionElement = document.createElement("option")
    optionElement.text = option[0]
    optionElement.value = option[1]
    inputElement.appendChild(optionElement)
  })
}

export default class extends Controller {
  static targets = ["options"]

  // If you change the date options format, make sure backend can accept new format
  updateOptions(event) {
    const newPayPeriod = event.target.value
    const dateOptionsByPayPeriod = JSON.parse(this.data.get("periodOptions"))
    const inputElement = this.optionsTarget

    updateInputWithNewDateOptions(inputElement, dateOptionsByPayPeriod[newPayPeriod])
  }
}
