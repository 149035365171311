import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "template"]

  clone() {
    const clone = this.templateTarget.content.cloneNode(true)
    this.containerTarget.appendChild(clone)
  }
}
