import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["debitAccountSelect"]

  toggleDebitAccount(event) {
    const paymentMethodSelected = event.target.value
    this.debitAccountSelectTarget.hidden = !(paymentMethodSelected === "direct_debit")
  }
}
