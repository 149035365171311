import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]

  toggle(event) {
    if (!event.currentTarget.checked) return

    const targetId = event.currentTarget.dataset.target
    const targetCheckbox = document.getElementById(targetId)

    if (targetCheckbox) {
      const contentTarget = this.contentTargets.find((target) => target.id === `${targetId}_content`)
      targetCheckbox.checked = false
      contentTarget.style.display = "none"
    }
  }
}
