import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["passwordField", "revealIcon", "hideIcon"]

  initialize() {
    this.field = this.passwordFieldTarget
    this.show(this.revealIconTarget.classList)
    this.hide(this.hideIconTarget.classList)
  }

  reveal() {
    this.field.type = "text" // reveal password
    this.hide(this.revealIconTarget)
    this.show(this.hideIconTarget) // now show the option to obscure the password
  }

  obscure() {
    this.field.type = "password" // obscure password
    this.hide(this.hideIconTarget)
    this.show(this.revealIconTarget) // now show the option to reveal the password
  }

  toggle() {
    if (this.field.type === "password") {
      this.reveal()
    } else {
      this.obscure()
    }
  }

  show(element) {
    element.classList.remove("hidden")
    element.classList.display = "block"
  }

  hide(element) {
    element.classList.add("hidden")
    element.classList.display = "none"
  }
}
