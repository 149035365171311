import { Controller } from "@hotwired/stimulus"
import CardValidator from "card-validator"
import I18n from "helpers/i18n"
import { flash } from "mobile_app/helpers/flash"
import { parseHTMLFragment } from "helpers/dom"

const CardType = {
  AMERICAN_EXPRESS: "american-express",
  MASTERCARD: "mastercard",
  VISA: "visa",
}

export default class extends Controller {
  static targets = [
    "form",
    "cardNumber",
    "cardName",
    "cardCvc",
    "expiryMonth",
    "expiryYear",
    "submit",
    "numberErrors",
    "expiryMonthErrors",
    "expiryYearErrors",
    "cardCvcErrors",
    "errorMessage",
    "nonce",
    "pricingMode",
  ]

  static values = {
    clientToken: String,
    isAnnual: Boolean,
  }

  encryptCardData() {
    const cardNumber = this.cardNumberTarget.value.replace(/\s+/g, "")
    const cardName = this.cardNameTarget.value
    const cardExpiryMonth = this.expiryMonthTarget.value.replace(/\s+/g, "")
    const cardExpiryYear = this.expiryYearTarget.value.replace(/\s+/g, "")
    const cardSecurity = this.cardCvcTarget.value.replace(/\s+/g, "")

    const client = new window.braintree.api.Client({ clientToken: this.clientTokenValue })

    return new Promise((resolve, reject) => {
      client.tokenizeCard(
        {
          number: cardNumber,
          cardholderName: cardName,
          expirationYear: cardExpiryYear,
          expirationMonth: cardExpiryMonth,
          cvv: cardSecurity,
        },
        (err, nonce) => {
          if (err) {
            return reject(err)
          }
          return resolve(nonce)
        }
      )
    })
  }

  handleSubmit(event) {
    // stop the form submission
    event.preventDefault()
    const canSubmit = this.checkValidations()
    if (canSubmit) {
      this.encryptCardData()
        .then((nonce) => {
          this.nonceTarget.value = nonce
          this.pricingModeTarget.value = this.isAnnualValue ? "annual" : "monthly"
          this.formTarget.submit()
        })
        .catch(() => {
          this.errorMessageTarget.classList.remove("hidden")
        })
    }
  }

  validateNumber() {
    this.numberErrorsTarget.innerText = ""
    const cardValidator = CardValidator.number(this.cardNumberTarget.value.replace(/\s+/g, ""))
    const cardType = (cardValidator.card && cardValidator.card.type) || null
    const normalisedCardType =
      cardType === CardType.MASTERCARD || cardType === CardType.VISA || cardType === CardType.AMERICAN_EXPRESS
        ? cardType
        : null
    if (normalisedCardType === null) {
      this.numberErrorsTarget.innerText = "Card type is invalid. We only accept Visa, Mastercard, and Amex"
      return false
    } else {
      return true
    }
  }

  styleNumber() {
    const cardValidator = CardValidator.number(this.cardNumberTarget.value)

    const prettyNumber = this.prettyCardNumber(this.cardNumberTarget.value, cardValidator.card)
    this.cardNumberTarget.value = prettyNumber
  }

  // https://github.com/braintree/credit-card-type#pretty-card-numbers
  prettyCardNumber(number, card) {
    if (!number || !number.length || !card || !card.gaps) {
      return number
    }
    // remove whitespace from our input
    const cardNumber = number.replace(/\s+/g, "")
    const offsets = [].concat(0, card.gaps, cardNumber.length)
    const components = []
    for (let i = 0; offsets[i] < cardNumber.length; i++) {
      const start = offsets[i]
      const end = Math.min(offsets[i + 1], cardNumber.length)
      components.push(cardNumber.substring(start, end))
    }

    return components.join(" ")
  }

  validateExpiryMonth() {
    this.expiryMonthErrorsTarget.innerText = ""
    const strippedCardExpiryMonth = this.expiryMonthTarget.value.replace(/\s+/g, "")
    const cardExpiryMonthError =
      strippedCardExpiryMonth.length !== 2
        ? "Card month expiry should be 2 digits. "
        : parseFloat(strippedCardExpiryMonth) < 1 || parseFloat(strippedCardExpiryMonth) > 12
        ? "Card month must be between 01 and 12. "
        : ""
    this.expiryMonthErrorsTarget.innerText = cardExpiryMonthError
    return cardExpiryMonthError === ""
  }

  validateExpiryYear() {
    this.expiryYearErrorsTarget.innerText = ""
    const strippedCardExpiryYear = this.expiryYearTarget.value.replace(/\s+/g, "")
    const currentYear = new Date().getFullYear()

    const cardExpiryYearError =
      strippedCardExpiryYear.length !== 4
        ? "Card year expiry should be 4 digits."
        : parseFloat(strippedCardExpiryYear) < currentYear
        ? I18n.t("js.payments.credit_card_form.expiry_year.range", { year: currentYear })
        : ""
    this.expiryYearErrorsTarget.innerText = cardExpiryYearError
    if (
      (parseFloat(strippedCardExpiryYear) === currentYear && !this.validateMonthYearOfExpiration()) ||
      cardExpiryYearError !== ""
    ) {
      return false
    } else if (cardExpiryYearError === "") {
      return true
    }
  }

  validateMonthYearOfExpiration() {
    const strippedCardExpiryMonth = this.expiryMonthTarget.value.replace(/\s+/g, "")
    const currentMonth = new Date().getMonth() + 1

    if (parseFloat(strippedCardExpiryMonth) < currentMonth) {
      this.expiryMonthErrorsTarget.appendChild(
        parseHTMLFragment(`
          <p class="text-red-600 text-sm"> Card month expiry should be in the future. </p>
          `)
      )
      return false
    } else {
      return true
    }
  }

  validateCvc() {
    const cardValidator = CardValidator.number(this.cardNumberTarget.value.replace(/\s+/g, ""))
    const cardType = (cardValidator.card && cardValidator.card.type) || null
    const normalisedCardType =
      cardType === CardType.MASTERCARD || cardType === CardType.VISA || cardType === CardType.AMERICAN_EXPRESS
        ? cardType
        : null

    this.cardCvcErrorsTarget.innerText = ""
    const cvvLength = this.cardCvcTarget.value.replace(/\s+/g, "").length
    let cardSecurityError = ""
    if (cvvLength < 3) {
      cardSecurityError = "CVV should be at least 3 digits"
    } else if (normalisedCardType === CardType.AMERICAN_EXPRESS && cvvLength !== 4) {
      cardSecurityError = "Amex CVV should be 4 digits"
    } else if (
      (normalisedCardType === CardType.MASTERCARD || normalisedCardType === CardType.VISA) &&
      cvvLength !== 3
    ) {
      cardSecurityError = "CVV should be 3 digits"
    }
    this.cardCvcErrorsTarget.innerText = cardSecurityError
    if (cardSecurityError === "") {
      return true
    } else {
      return false
    }
  }

  checkValidations() {
    const numberValid = this.validateNumber()
    const expiryMonthValid = this.validateExpiryMonth()
    const expiryYearValid = this.validateExpiryYear()
    const cvcValid = this.validateCvc()

    const canSubmit = numberValid && expiryMonthValid && expiryYearValid && cvcValid

    if (!canSubmit) {
      flash("Please check the form for errors", "error")
    }
    return canSubmit
  }
}
