import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "editButton",
    "cancelButton",
    "showFrame",
    "formContainer",
    "changeTypeSelectorTemplate",
    "changeTypeSelector",
    "editFrame",
  ]

  /**
   * @returns {import("../../../../stimulus/shared_controllers/refresh_form_controller").default}
   */
  get refreshController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "refresh-form")
  }

  get loadingClasses() {
    return this.refreshController.loadingClasses
  }

  async startEditing() {
    if (this.hasChangeTypeSelectorTarget) {
      this.changeTypeSelectorTarget.replaceWith(this.changeTypeSelectorTemplateTarget.content.cloneNode(true))
      this.changeTypeSelectorTarget.inert = true
      this.changeTypeSelectorTarget.classList.add(...this.loadingClasses)
      this.showFrameTarget.classList.add("hidden")
      this.formContainerTarget.classList.remove("hidden")
    } else {
      this.showFrameTarget.classList.add(...this.loadingClasses)
    }

    this.editButtonTarget.disabled = true

    await this.refreshController.refreshFormAndScrollIntoView()

    this.editButtonTarget.disabled = false
    this.cancelButtonTarget.classList.remove("hidden")
    this.editButtonTarget.classList.add("hidden")

    if (this.hasChangeTypeSelectorTarget) {
      this.changeTypeSelectorTarget.inert = false
      this.changeTypeSelectorTarget.classList.remove(...this.loadingClasses)
    } else {
      this.showFrameTarget.classList.remove(...this.loadingClasses)
      this.showFrameTarget.classList.add("hidden")
      this.formContainerTarget.classList.remove("hidden")
    }
  }

  cancelEditing() {
    this.showFrameTarget.classList.remove("hidden")
    this.formContainerTarget.classList.add("hidden")

    this.cancelButtonTarget.classList.add("hidden")
    this.editButtonTarget.classList.remove("hidden")

    this.editFrameTarget.src = ""
    this.editFrameTarget.innerHTML = ""
  }
}
