import { Controller } from "@hotwired/stimulus"

/* for toggler design component */
export default class extends Controller {
  static targets = ["picker"]

  static values = {
    ids: Array,
    defaultPicker: String,
  }

  initialize() {
    this.hideClass = "hidden"
    this.showClass = "block"
    this.activePickerClass = "active"

    // the element ids for toggle content
    this.contents = this.idsValue

    this.contents.forEach((id) => {
      const cid = document.getElementById(id)
      if (id === this.defaultPickerValue) {
        this.show(cid)
      } else {
        this.hide(cid)
      }
    })
  }

  show(target) {
    target.classList.remove(this.hideClass)
    target.classList.add(this.showClass)
  }

  hide(target) {
    target.classList.add(this.hideClass)
    target.classList.remove(this.showClass)
  }

  toggle(event) {
    // the toggle content to show
    const content = event.params.target

    // the active picker index
    const active = event.params.active

    // Toggle Pill Styling
    const pickerTarget = this.pickerTargets[active]
    this.pickerTargets.forEach((t) => {
      t.classList.remove(this.activePickerClass)
      t.ariaPressed = false
    })
    pickerTarget.classList.add(this.activePickerClass)
    pickerTarget.ariaPressed = true

    // Toggle Content
    const contentTarget = document.getElementById(content)
    this.contents.forEach((cid) => {
      const c = document.getElementById(cid)
      this.hide(c)
    })
    this.show(contentTarget)
  }
}
