// @flow

// https://github.com/TandaHQ/once-campfire/blob/2d30738faa0ccffbde33a228b07e9225c1702b58/app/javascript/helpers/dom_helpers.js#L44
// eslint-disable-next-line flowtype/no-weak-types
export async function keepScroll(container: any, top: boolean, fn: any) {
  pauseInertiaScroll(container)

  const scrollTop = container.scrollTop
  const scrollHeight = container.scrollHeight

  await fn()

  if (top) {
    container.scrollTop = scrollTop + (container.scrollHeight - scrollHeight)
  } else {
    container.scrollTop = scrollTop
  }
}

// https://github.com/TandaHQ/once-campfire/blob/2d30738faa0ccffbde33a228b07e9225c1702b58/app/javascript/helpers/dom_helpers.js#L59
// eslint-disable-next-line flowtype/no-weak-types
function pauseInertiaScroll(container: any) {
  container.style.overflow = "hidden"

  requestAnimationFrame(() => {
    container.style.overflow = ""
  })
}

// https://github.com/TandaHQ/once-campfire/blob/2d30738faa0ccffbde33a228b07e9225c1702b58/app/javascript/helpers/dom_helpers.js#L13C1-L14C1
export function parseHTMLFragment(html: string): DocumentFragment {
  const template = document.createElement("template")
  template.innerHTML = html
  return template.content
}

// The function name is important, as it's recognised by https://github.com/mozilla/eslint-plugin-no-unsanitized
export function escapeHTML(potentiallyDangerousHtml: string): string {
  const div = document.createElement("div")
  div.textContent = potentiallyDangerousHtml
  return div.innerHTML
}

// eslint-disable-next-line flowtype/no-weak-types
export function scrollToBottom(scroller: any, instant: boolean): void {
  requestAnimationFrame(() => {
    if (instant) {
      scroller.scrollTop = scroller.scrollHeight
    } else {
      scroller.scrollTo({
        left: 0,
        top: scroller.scrollHeight,
        behavior: "smooth",
      })
    }
  })
}
