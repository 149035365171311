import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["videoContainer", "idField"]

  connect() {
    this.update({ currentTarget: { value: this.idFieldTarget.value } })
  }

  update(event) {
    const url = event.currentTarget.value
    const videoId = this.#getVideoId(url)

    this.videoContainerTarget.innerHTML = ""

    if (videoId) {
      const iframe = document.createElement("iframe")
      iframe.src = `https://youtube.com/embed/${videoId}`
      iframe.width = 400
      iframe.height = 225
      this.videoContainerTarget.appendChild(iframe)

      this.idFieldTarget.value = videoId
    }
  }

  #getVideoId(url) {
    try {
      const parsedUrl = new URL(url)

      if (["youtube.com", "m.youtube.com", "www.youtube.com"].includes(parsedUrl.hostname)) {
        if (parsedUrl.pathname === "/watch") {
          return parsedUrl.searchParams.get("v") || false
        } else if (parsedUrl.pathname.startsWith("/embed/")) {
          return parsedUrl.pathname.substring("/embed/".length) || false
        } else return false
      } else if (parsedUrl.hostname === "youtu.be") {
        return parsedUrl.pathname.substring("/".length) || false
      }
    } catch {
      return false
    }
  }
}
