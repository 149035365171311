import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["payConditionsForm", "regularHoursForm", "reviewForm", "reviewFormSubmitButton"]
  static values = { usingContractTemplates: Boolean }

  connect() {
    document.addEventListener("turbo:submit-end", this.enableFormSubmit)
  }

  disconnect() {
    document.removeEventListener("turbo:submit-end", this.enableFormSubmit)
  }

  disableFormSubmit = (e) => {
    this.reviewFormSubmitButtonTarget.disabled = true
  }

  enableFormSubmit = (e) => {
    this.reviewFormSubmitButtonTarget.disabled = false
  }

  async submitAll(e) {
    if (!this.usingContractTemplatesValue) {
      this.disableFormSubmit()

      e.preventDefault()

      await this.submitForm(this.payConditionsFormTarget)
      await this.submitForm(this.regularHoursFormTarget)

      this.reviewFormTarget.requestSubmit()
    }
  }

  submitForm(form) {
    return new Promise((resolve) => {
      form.addEventListener("turbo:submit-end", resolve, { once: true })
      form.requestSubmit()
    })
  }
}
