import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    id: String,
  }

  trackUpdates(e) {
    this.dispatch(`${this.idValue}-dailyBreakdownUpdated`)
    const thisModal = document.getElementById(`${this.idValue}_leave_modal`)
    const closeBtn = thisModal.shadowRoot.querySelector(`.corner-close-btn`)
    closeBtn.style.display = "none"
  }
}
