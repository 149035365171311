import { Controller } from "@hotwired/stimulus"
import { flash } from "mobile_app/helpers/flash"

export default class extends Controller {
  static values = { url: String }

  async toClipboard() {
    flash("Copied!")
    navigator.clipboard.writeText(this.urlValue)
  }
}
