import { Controller } from "@hotwired/stimulus"
import { t as globalT } from "helpers/i18n"

export default class extends Controller {
  static targets = ["button", "details"]

  toggle(event) {
    const trigger = event.currentTarget
    const groupName = trigger.dataset.name
    const buttons = this.buttonTargets.filter((button) => button.dataset.name === groupName)
    const details = this.detailsTargets.filter((detail) => detail.dataset.name === groupName)

    if (buttons.length > 0) {
      if (buttons[0].innerText === globalT("js.users.shared.show_all")) {
        details.forEach((detail) => {
          detail.open = true
        })
        buttons.forEach((button) => {
          button.innerText = globalT("js.users.shared.hide_all")
        })
      } else if (buttons[0].innerText === globalT("js.users.shared.hide_all")) {
        details.forEach((detail) => {
          detail.open = false
        })
        buttons.forEach((button) => {
          button.innerText = globalT("js.users.shared.show_all")
        })
      }
    }
  }
}
