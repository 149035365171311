import { Controller } from "@hotwired/stimulus"
import * as autosize from "autosize"
import { querystringSet } from "helpers/navigation"
import Request from "helpers/request"
import { keepScroll, parseHTMLFragment, scrollToBottom } from "helpers/dom"

export default class extends Controller {
  static targets = ["reply", "submit", "form", "scroller"]
  static values = { url: String }
  static outlets = ["seen", "design-components--file-uploader"]

  connect() {
    scrollToBottom(this.scrollerTarget, true)

    this.timeout = setTimeout(this.poll.bind(this), 5 * 1000)

    autosize(this.replyTarget)
  }

  disconnect() {
    clearTimeout(this.timeout)

    autosize.destroy(this.replyTarget)
  }

  // Poor man's Action Cable.
  async poll() {
    const messages = this.allMessages()
    // If the room is empty, disable this, you'll have to manually get new messages.
    if (!messages.length) return

    const id = messages[messages.length - 1].dataset.chatMessageId
    const url = querystringSet(this.urlValue, "after", id)

    await this.updateMessages(url, false)

    this.timeout = setTimeout(this.poll.bind(this), 5 * 1000)
  }

  // Fires on scroll - if we have scrolled to the top, request and load more messages.
  async loadMoreIfScrolledToTop() {
    if (this.hasScrolledToTop()) {
      const id = this.allMessages()[0].dataset.chatMessageId
      const url = querystringSet(this.urlValue, "before", id)

      this.updateMessages(url, true)
    }
  }

  async loadMessagesBetweenMessages(parentMessageId, childMessageId) {
    const url = querystringSet(querystringSet(this.urlValue, "after", parentMessageId), "before", childMessageId)
    await this.updateMessages(url, true)
  }

  // When the message is submitted, immediately blank out the text input so they can start composing the next one.
  // If we wanted this to feel even more slick, we could add a client side `<template>`, impose the message into that, and update the DOM.
  submit() {
    this.replyTarget.value = ""
    autosize.update(this.replyTarget)
    this.dispatch("resetFilePicker")
    scrollToBottom(this.scrollerTarget, false)
  }

  // When the enter key is pressed, we submit. You can use shift + enter to type a multiline message.
  enter(event) {
    event.preventDefault()

    if (this.replyTarget.value.length) {
      this.formTarget.requestSubmit()
    }
  }

  clickFileUploader() {
    this.designComponentsFileUploaderOutlet.openFileSelector()
  }

  resetFileUploader() {
    this.designComponentsFileUploaderOutlet.removeAll()
  }

  async scrollToMessage(e) {
    e.preventDefault()

    const parentId = e.target.dataset.chatParentMessageId
    const childId = e.target.dataset.chatChildMessageId
    if (!this.allMessages().includes((message) => message.dataset.chatMessageId === parentId)) {
      await this.loadMessagesBetweenMessages(parentId, childId)
    }

    const message = this.allMessages().find((message) => message.dataset.chatMessageId === parentId)

    if (message) {
      message.scrollIntoView({ behavior: "smooth" })
    } else {
      console.error("Message not found")
    }
  }

  // PRIVATE

  allMessages() {
    return Array.from(this.scrollerTarget.querySelectorAll("[data-chat-message-id]"))
  }

  hasScrolledToTop() {
    const messages = this.allMessages()
    const rect = this.scrollerTarget.getBoundingClientRect()
    const topVisibleMessage = messages.find((e) => e.getBoundingClientRect().y >= rect.top)

    return topVisibleMessage === messages[0]
  }

  hasScrolledToBottom() {
    const messages = this.allMessages()
    const rect = this.scrollerTarget.getBoundingClientRect()
    const isScrolledToBottom = messages[messages.length - 1].getBoundingClientRect().bottom === rect.bottom

    return isScrolledToBottom
  }

  async updateMessages(url, top) {
    const resp = await Request.get(url)
    const html = parseHTMLFragment(resp.data)
    if (html.childElementCount === 0) return // nothing to render

    const wasAtBottom = this.hasScrolledToBottom()

    await keepScroll(this.scrollerTarget, top, () => {
      if (top) {
        this.scrollerTarget.prepend(html)
      } else {
        this.scrollerTarget.append(html)
      }

      // Remove duplicates due to race conditions with new message creation (or anything else).
      const ids = this.allMessages().map((message) => message.dataset.chatMessageId)
      const duplicates = ids.filter((item, index) => ids.indexOf(item) !== index)
      duplicates.forEach((id) => {
        this.allMessages()
          .find((message) => message.dataset.chatMessageId === id)
          .remove()
      })
    })

    if (!top && wasAtBottom) {
      scrollToBottom(this.scrollerTarget, false)
    }

    if (!top) {
      this.seenOutlet.markAsSeen()
    }
  }
}
