import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    newRecord: Boolean,
    manager: Boolean,
    employee: Boolean,
    temporaryEmployee: Boolean,
    canEditManagerCostVisibility: Boolean,
  }
  static targets = [
    "showCustomUserPermissions",
    "canSeeCosts",
    "enableLogin",
    "agencyStaff",
    "temporaryEmployeeType",
    "employeeRole",
    "nonEmployeeRole",
  ]

  connect() {
    const newRecord = this.newRecordValue
    if (newRecord) {
      this.initialNewRecordState()
    } else {
      this.toggleCustomUserPermissions()
    }
  }

  initialNewRecordState() {
    // The targets will only render if can?(:assign_roles, @user)
    if (!this.hasShowCustomUserPermissionsTarget) {
      return
    }
    this.showCustomUserPermissionsTarget.classList.remove("hidden")
    if (this.hasEnableLoginTarget) {
      this.enableLoginTarget.classList.remove("hidden")
    }
    if (this.hasCanSeeCostsTarget) {
      this.canSeeCostsTarget.classList.add("hidden")
    }
  }

  managerChanged(event) {
    this.managerValue = event.target.checked
    this.toggleCustomUserPermissions()
  }

  employeeChanged(event) {
    this.employeeValue = event.target.checked
    this.toggleCustomUserPermissions()
  }

  temporaryEmployeeChanged(event) {
    this.temporaryEmployeeValue = event.target.checked
    const temporaryEmployee = this.temporaryEmployeeValue
    this.toggleTemporaryEmployeeType(temporaryEmployee)
  }

  toggleCustomUserPermissions() {
    const managerValueWithSetting = this.canEditManagerCostVisibilityValue && this.managerValue
    const managerOrEmployee = managerValueWithSetting || this.employeeValue

    this.toggleShowAnyPermission(managerOrEmployee)
    this.toggleShowMangerCanSeeCostsPermission(managerValueWithSetting)
    this.toggleShowEmployeeEnableLoginPermission(this.employeeValue)
  }

  toggleShowAnyPermission(managerOrEmployee) {
    // The target will only render if can?(:assign_roles, @user)
    if (!this.hasShowCustomUserPermissionsTarget) return

    if (managerOrEmployee) {
      this.showCustomUserPermissionsTarget.classList.remove("hidden")
    } else {
      this.showCustomUserPermissionsTarget.classList.add("hidden")
    }
  }

  toggleShowMangerCanSeeCostsPermission(managerValueWithSetting) {
    // The target will only render if can?(:assign_roles, @user)
    if (!this.hasCanSeeCostsTarget) return

    if (managerValueWithSetting) {
      this.canSeeCostsTarget.classList.remove("hidden")
    } else {
      this.canSeeCostsTarget.classList.add("hidden")
    }
  }

  toggleShowEmployeeEnableLoginPermission(employeeValue) {
    // The target will only render if can?(:assign_roles, @user)
    if (!this.hasEnableLoginTarget) return

    if (employeeValue) {
      this.enableLoginTarget.classList.remove("hidden")
    } else {
      this.enableLoginTarget.classList.add("hidden")
    }
  }

  toggleTemporaryEmployeeType(temporaryEmployee) {
    if (temporaryEmployee) {
      this.temporaryEmployeeTypeTarget.value = "no_agency"
      this.nonEmployeeRoleTargets.forEach((target) => {
        target.checked = false
        target.setAttribute("disabled", true)
      })
      this.employeeRoleTarget.checked = true
      this.employeeRoleTarget.setAttribute("disabled", true)
    } else {
      this.temporaryEmployeeTypeTarget.value = ""
      this.nonEmployeeRoleTargets.forEach((target) => {
        target.removeAttribute("disabled")
      })
      this.employeeRoleTarget.removeAttribute("disabled")
    }
  }
}
