import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["message"]
  static values = { url: String }

  connect() {
    this.updateParams()
  }

  updateParams() {
    const all_hidden_content_inputs = this.element.querySelectorAll(
      "input[type=hidden][name^='communication_message[content]']"
    )
    all_hidden_content_inputs.forEach((input) => {
      input.value = this.messageTarget.value
    })
  }
}
