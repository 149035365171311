import { Controller } from "@hotwired/stimulus"
import I18n from "helpers/i18n"

export default class extends Controller {
  static targets = ["dateField"]

  validate() {
    const dateField = this.dateFieldTarget
    const { value, min, max } = dateField

    if (value) {
      if (min && value < min) {
        dateField.setCustomValidity(
          I18n.t("js.employment_contracts.errors.date_must_be_after", {
            date: this.#formatDate(min),
          })
        )
      } else if (max && value > max) {
        dateField.setCustomValidity(
          I18n.t("js.employment_contracts.errors.date_must_be_before", {
            date: this.#formatDate(max),
          })
        )
      } else {
        dateField.setCustomValidity("")
      }
    } else {
      dateField.setCustomValidity(I18n.t("js.employment_contracts.errors.please_enter_date"))
    }

    dateField.reportValidity()
  }

  #formatDate(dateString) {
    return new Date(dateString).toLocaleDateString(undefined, { day: "numeric", month: "long", year: "numeric" })
  }
}
