import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // This controller handles field interpolation and contract completion
  // for a **single** user contract

  static values = { content: String, amendment: String }
  static targets = [
    "customFieldInput",
    "renderedContent",
    "amendmentHeaderTemplate",
    "warningMessage",
    "completedMessage",
    "currentContractComplete",
    "currentContractProgressBar",
    "currentContractProgressBarLabel",
  ]

  connect() {
    this.originalContent = this.contentValue
    this.updateDataOnInterpolation()
  }

  interpolate(event) {
    this.updateDataOnInterpolation()

    if (event.target.value !== "") {
      event.target.style.border = ""
    } else {
      event.target.style.border = "solid #dd6255"
    }
  }

  updateDataOnInterpolation() {
    const shortcodeInputs = this.customFieldInputTargets.map((target) => ({
      shortcode: target.getAttribute("data-field"),
      input: target.value,
    }))

    this.contentValue = this.originalContent

    const populatedShortcodeInputs = shortcodeInputs.filter(({ input }) => input !== "")
    const progressBar = this.currentContractProgressBarTarget

    if (populatedShortcodeInputs.length < shortcodeInputs.length) {
      this.warningMessageTarget.classList.remove("hidden")
      this.completedMessageTarget.classList.add("hidden")

      if (this.currentContractCompleteTarget.value === "true") {
        progressBar.value -= 1
        this.currentContractCompleteTarget.value = false
      }
    } else {
      this.warningMessageTarget.classList.add("hidden")
      this.completedMessageTarget.classList.remove("hidden")

      if (this.currentContractCompleteTarget.value === "false") {
        progressBar.value += 1
        this.currentContractCompleteTarget.value = true
      }
    }

    // no user entered text here (hopefully)
    // eslint-disable-next-line no-unsanitized/property
    this.currentContractProgressBarLabelTarget.innerHTML = this.currentContractProgressBarLabelTarget.innerHTML.replace(
      /\d+\/\d+/,
      `${progressBar.value}/${progressBar.max}`
    )

    populatedShortcodeInputs.forEach(({ shortcode, input }) => {
      this.contentValue = this.contentValue.replaceAll(shortcode, input)
    })

    // action text content
    // eslint-disable-next-line no-unsanitized/property
    this.renderedContentTarget.innerHTML = this.contentValue
  }

  updateAmendment(event) {
    // action text content
    // eslint-disable-next-line no-unsanitized/property
    this.renderedContentTarget.innerHTML = this.contentValue

    const amendmentInput = event.target.value

    if (amendmentInput !== "") {
      this.renderedContentTarget.appendChild(this.amendmentHeaderTemplateTarget.content.cloneNode(true))
      this.renderedContentTarget.appendChild(document.createTextNode(amendmentInput))
    }
  }

  resetInputs() {
    this.contentValue = this.originalContent
    // action text content
    // eslint-disable-next-line no-unsanitized/property
    this.renderedContentTarget.innerHTML = this.contentValue

    this.customFieldInputTargets.forEach((target) => {
      target.value = ""
      target.style.border = "solid #dd6255"
    })
    this.warningMessageTarget.classList.remove("hidden")
    this.completedMessageTarget.classList.add("hidden")
    this.currentContractCompleteTarget.value = false
  }
}
