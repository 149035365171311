import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "actions", "toggleAll"]

  rowChecked(event) {
    this.toggleAllTarget.checked = this.allChecked()
    this.showBulkActions()
    this.saveSelectedRows()
  }

  toggleAll(event) {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = event.target.checked
    })
    this.showBulkActions()
    this.saveSelectedRows()
  }

  allChecked() {
    return this.checkboxTargets.every((checkbox) => checkbox.checked)
  }

  atLeastOneChecked() {
    return this.checkboxTargets.some((checkbox) => checkbox.checked)
  }

  showBulkActions() {
    this.actionsTarget.hidden = !this.atLeastOneChecked()
  }

  saveSelectedRows() {
    this.element.dataset.selectedRows = JSON.stringify(
      this.checkboxTargets.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.name)
    )
  }
}
