import { Controller } from "@hotwired/stimulus"
import "form-request-submit-polyfill"

// This controller will automatically refresh a page on a periodic basis.
// A good use case is the ATO's STP submission workflow. Their API doesn't provide a typical request/response workflow
// We use a tool to periodically poll their API for a response in a background job. Their API can take *upto* 60 minutes to give a response.
// This will refresh until we get that response.
export default class extends Controller {
  connect() {
    this.startAutoRefresh()
  }

  startAutoRefresh() {
    this.refreshInterval = setInterval(() => {
      location.reload()
    }, 30000)
  }

  stopAutoRefresh() {
    clearInterval(this.refreshInterval)
  }

  disconnect() {
    this.stopAutoRefresh()
  }
}
