import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hiddenPill", "button"]

  showPills() {
    this.hiddenPillTargets.forEach((pill) => {
      pill.classList.remove("hidden")
    })
    this.buttonTarget.classList.add("hidden")
  }
}
