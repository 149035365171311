// @flow

import { find } from "lodash"

// http://stackoverflow.com/a/3788235/641293
export const querystringGet = (key: string): void | string => {
  const re = new RegExp("(?:\\?|&)" + key + "=(.*?)(?=&|$)", "gi")
  const match = re.exec(document.location.search)

  // The old API used to return undefined.
  // To avoid any incompatibility this should return undefined until someone is brave enough to make it return null
  if (!match) {
    return
  }

  return match[1]
}

export const getDeserialisedQueryString = (): { [key: string]: string } =>
  document.location.search
    .replace("?", "")
    .split("&")
    .reduce((json_obj, token) => {
      const [key, value] = token.split("=")
      json_obj[key] = value
      return json_obj
    }, {})

// http://stackoverflow.com/a/6021027/641293
export const querystringSet = (uri: string, key: string, value: ?string, skipEncode: ?boolean): string => {
  if (value && !skipEncode) {
    value = encodeURIComponent(value)
  }

  const re = new RegExp("([?|&])" + key + "=.*?(&|$)", "i")
  const separator = uri.indexOf("?") !== -1 ? "&" : "?"
  let u

  if (uri.match(re)) {
    if (value) {
      return uri.replace(re, "$1" + key + "=" + value + "$2")
    } else {
      u = uri.replace(re, "$2") // if no value is passed, remove the querystring param
    }

    if (u.indexOf("?") === -1 && u.indexOf("&") !== -1) {
      // if we have removed the first param (and the ?), fix it
      u = u.replace("&", "?") // there is probably a more elegant way to do this
    }

    return u
  } else {
    if (key && value) {
      return uri + separator + key + "=" + value
    } else {
      return uri // this way, you can pass null as value and it's not added to the querystring
    }
  }
}

// for each pair in the new querystring params, add it to the existing url if it's not already there
export const querystringMerge = (existingUrl: string, newQuerystringParams: string): string => {
  let existingQuerystring = []
  if (existingUrl.indexOf("?") > -1) {
    existingQuerystring = existingUrl
      .substring(existingUrl.indexOf("?") + 1)
      .split("&")
      .map((s) => s.split("="))
  }

  newQuerystringParams
    .split("&")
    .map((s) => s.split("="))
    .forEach((pair) => {
      const matching_pair = find(existingQuerystring, (existing_pair) => existing_pair[0] === pair[0])
      if (!matching_pair) {
        existingQuerystring.push(pair)
      }
    })

  if (existingUrl.indexOf("?") > -1) {
    return (
      existingUrl.substring(0, existingUrl.indexOf("?") + 1) +
      existingQuerystring.map((pair) => pair.join("=")).join("&")
    )
  } else {
    return existingUrl + "?" + existingQuerystring.map((pair) => pair.join("=")).join("&")
  }
}
