import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.myUrl = new URL(document.URL)
  }

  static values = { currentUserId: String }

  connect() {
    this.saveParams()
  }

  saveParams() {
    // For UX and perfomance reasons we don't save search queries or pay rate settings in the cookie
    if (this.myUrl.searchParams.has("query")) {
      this.myUrl.searchParams.delete("query")
    }
    const params = this.myUrl.search.toString()
    // Cookie lasts 2 weeks
    const cookieName = `staff_filters_${this.currentUserIdValue}`
    document.cookie = `${cookieName}=${params}; path=/; max-age=1209600`
  }

  clear() {
    const cookieName = `staff_filters_${this.currentUserIdValue}`
    document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
    // hard redirect is necessary, turbo redirect gets confused.
    window.location.href = this.myUrl.pathname.toString()
  }
}
