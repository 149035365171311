/*

https://www.stimulus-components.com/docs/stimulus-sortable/
Ported into our codebase due to compatability issues.

*/

import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import Request from "helpers/request"

let SORTABLE_CONTROLLER_IS_DRAGGING = false

export default class extends Controller {
  static values = {
    resourceName: String,
    paramName: {
      type: String,
      default: "position",
    },
    responseKind: {
      type: String,
      default: "html",
    },
    animation: Number,
    handle: String,
  }

  connect() {
    const options = {
      animation: this.animationValue || 150,
      handle: this.handleValue || undefined,
      onStart: this.onStart,
      onEnd: this.onEnd,
      onUpdate: this.onUpdate,
    }
    this.sortable = new Sortable(this.element, options)
  }

  disconnect() {
    // We only want to destroy the Sortable if we are unloading the page or removing the element entirely (eg. from a Turbo Stream) response
    // *not* if it's being temporarily removed while doing a drag and drop.
    if (!SORTABLE_CONTROLLER_IS_DRAGGING) {
      this.sortable.destroy()
      this.sortable = undefined
    }
  }

  onStart = () => {
    SORTABLE_CONTROLLER_IS_DRAGGING = true
  }
  onEnd = () => {
    SORTABLE_CONTROLLER_IS_DRAGGING = false
  }

  onUpdate = async ({ item, newIndex }) => {
    if (!item.dataset.sortableUpdateUrlValue) return

    const param = this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue

    const data = new FormData()
    data.append(param, newIndex + 1)

    await Request.put(item.dataset.sortableUpdateUrlValue, data)
  }
}
