// @flow
import { Controller } from "@hotwired/stimulus"
import { t as globalT } from "helpers/i18n"

export default class extends Controller {
  static targets: Array<string> = ["openingBalanceTitle", "startingBalanceTitle", "leaveType", "showStartingBalance"]

  updateStartingBalanceTitle() {
    const selectedOption = this.leaveTypeTarget.options[this.leaveTypeTarget.selectedIndex]
    const leaveInDays = selectedOption.dataset.leaveInDays
    const showStartingBalance = this.showStartingBalanceTarget.value

    if (showStartingBalance === "true") {
      this.startingBalanceTitleTarget.textContent =
        leaveInDays === "true"
          ? globalT("js.users.payroll_user_profile.leave_balance.starting_balance_in_days")
          : globalT("js.users.payroll_user_profile.leave_balance.starting_balance_in_hours")
    } else {
      this.openingBalanceTitleTarget.textContent =
        leaveInDays === "true"
          ? globalT("js.users.payroll_user_profile.leave_balance.opening_balance_in_days")
          : globalT("js.users.payroll_user_profile.leave_balance.opening_balance_in_hours")
    }
  }
}
