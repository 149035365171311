import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["rateCharacteristic", "blendedOvertime", "multiplier"]

  toggleBlendedOvertime() {
    if (this.blendedOvertimeTarget.checked) {
      this.rateCharacteristicTargets.forEach((rateCharacteristic) => {
        if (rateCharacteristic === this.multiplierTarget) {
          rateCharacteristic.value = 1
        } else {
          rateCharacteristic.value = ""
          rateCharacteristic.disabled = true
        }
      })
    } else if (this.blendedOvertimeTarget.checked === false) {
      this.rateCharacteristicTargets.forEach((rateCharacteristic) => {
        if (rateCharacteristic === this.multiplierTarget) {
          rateCharacteristic.value = ""
        }
        rateCharacteristic.disabled = false
      })
    }
  }
}
