import { Controller } from "@hotwired/stimulus"
import { flash } from "mobile_app/helpers/flash"

export default class extends Controller {
  static values = { message: String, type: String }

  connect() {
    flash(this.messageValue, this.typeValue)
    this.element.remove()
  }
}
