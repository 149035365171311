import { Controller } from "@hotwired/stimulus"
import I18n from "helpers/i18n"

export default class extends Controller {
  static targets = ["numberInput", "submit"]
  static values = {
    input: String,
    preventNegative: Boolean,
  }

  validate() {
    if (isNaN(this.numberInputTarget.value)) {
      this.numberInputTarget.setCustomValidity(I18n.t("js.mobile_app.invalid_number"))
    } else {
      if (this.preventNegativeValue && this.numberInputTarget.value < 0) {
        this.numberInputTarget.setCustomValidity(I18n.t("js.mobile_app.invalid_number"))
      } else {
        this.numberInputTarget.setCustomValidity("")
      }
    }
  }

  onChange = (e) => {
    if (this.inputValue === e.target.value) {
      this.submitTarget.setAttribute("disabled", "true")
    } else {
      this.submitTarget.removeAttribute("disabled")
    }
  }
}
