import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  myUrl = new URL(document.URL)

  connect() {
    const params = this.myUrl.searchParams.toString()
    localStorage.setItem("leave_filters", params)
  }

  clear() {
    localStorage.removeItem("leave_filters")
    Turbo.visit(this.myUrl.pathname.toString())
  }
}
