import { Controller } from "@hotwired/stimulus"
import { escapeHTML } from "helpers/dom"

export default class extends Controller {
  static targets = [
    "dialog",
    "image",
    "close",
    "mainLightboxFigure",
    "mainLightboxImage",
    "imagePreviewList",
    "previewImage",
    "header",
    "title",
    "description",
    "prevNavigation",
    "nextNavigation",
  ]
  imageSetArray = []
  isOpen = false

  connect() {
    this.imageTargets.forEach((image) => {
      image.addEventListener("click", (event) => {
        this.populateModal(event.currentTarget.dataset.lightboxSet)
        this.updateMainImage(this.createImageObject(event.currentTarget))
        document.body.classList.add("overflow-hidden")
        this.dialogTarget.showModal()
        this.isOpen = true
      })
    })
    this.dialogTarget.addEventListener("close", (event) => {
      this.imagePreviewListTarget.innerHTML = ""
      document.body.classList.remove("overflow-hidden")
    })
    document.addEventListener("keydown", this.watchArrowKeys.bind(this))
  }

  watchArrowKeys(event) {
    if (!this.isOpen) return
    if (event.key === "ArrowRight") {
      this.nextImage()
    }
    if (event.key === "ArrowLeft") {
      this.prevImage()
    }
  }

  disconnect() {
    document.removeEventListener("keydown", this.watchArrowKeys)
  }

  createImageObject = (thisElement) => ({
    src: thisElement.tagName === "IMG" ? thisElement.src : thisElement.dataset.src,
    title: thisElement.dataset.lightboxTitle || "",
    description: thisElement.dataset.lightboxDescription || "",
  })

  updateMainImage(imgObject) {
    this.mainLightboxImageTarget.src = imgObject.src
    this.mainLightboxFigureTarget.style.backgroundImage = `url(${imgObject.src})`

    if (imgObject.title || imgObject.description) {
      this.headerTarget.classList.remove("hidden")
      this.titleTarget.innerText = imgObject.title || ""
      this.descriptionTarget.innerText = imgObject.description || ""
    } else {
      this.headerTarget.classList.add("hidden")
    }
    this.highlightPreviewImage()
    this.mainLightboxImageTarget.focus()
  }

  highlightPreviewImage() {
    this.previewImageTargets.forEach((image) => {
      if (image.src === this.mainLightboxImageTarget.src) {
        image.classList.add("ring-2", "ring-blue-500")
      } else {
        image.classList.remove("ring-2", "ring-blue-500")
      }
    })
  }

  updateMainImageFromPreview(event) {
    const image = this.imageSetArray.find((img) => img.src === event.currentTarget.src)
    this.updateMainImage(image)
  }

  nextImage() {
    const currentIndex = this.imageSetArray.map((item) => item.src).indexOf(this.mainLightboxImageTarget.src)
    const nextIndex = currentIndex + 1
    if (nextIndex < this.imageSetArray.length) {
      this.updateMainImage(this.imageSetArray[nextIndex])
    } else {
      this.updateMainImage(this.imageSetArray[0])
    }
  }

  prevImage() {
    const currentIndex = this.imageSetArray.map((item) => item.src).indexOf(this.mainLightboxImageTarget.src)
    const prevIndex = currentIndex - 1
    if (prevIndex >= 0) {
      this.updateMainImage(this.imageSetArray[prevIndex])
    } else {
      this.updateMainImage(this.imageSetArray[this.imageSetArray.length - 1])
    }
  }

  populateModal(imageSet) {
    const imagesInSet = this.imageTargets.filter((image) => image.dataset.lightboxSet === imageSet)
    this.imageSetArray = imagesInSet.map((img) => this.createImageObject(img))
    if (imagesInSet.length < 2) {
      this.prevNavigationTarget.classList.add("hidden")
      this.nextNavigationTarget.classList.add("hidden")
      return
    } else {
      this.prevNavigationTarget.classList.remove("hidden")
      this.nextNavigationTarget.classList.remove("hidden")
    }
    imagesInSet.forEach((image) => {
      const getImage = () => {
        if (image.src) {
          return image.src
        } else {
          return image.dataset.src
        }
      }

      // fine because everything is escaped
      // eslint-disable-next-line no-unsanitized/method
      this.imagePreviewListTarget.insertAdjacentHTML(
        "beforeend",
        `
          <img src="${getImage()}"
               class="h-12 w-12 md:h-20 md:w-20 object-cover cursor-pointer rounded-sm"
               role="button"
               data-design-components--lightbox-target="previewImage"
               data-action="click->design-components--lightbox#updateMainImageFromPreview"
               data-lightbox-title="${escapeHTML(image.dataset.lightboxTitle || "")}"
               data-lightbox-description="${escapeHTML(image.dataset.lightboxDescription || "")}"
            />
        `
      )
    })
    this.previewImageTarget.focus()
  }

  closeModal() {
    this.imagePreviewListTarget.innerHTML = ""
    this.dialogTarget.close()
    this.isOpen = false
    document.body.classList.remove("overflow-hidden")
  }

  zoom(e) {
    // This matches the "lg" size in Tailwind.
    if (window.innerWidth < 1024) return

    const zoomer = e.currentTarget
    const offsetX = e.offsetX || e.touches[0].pageX
    const offsetY = e.offsetY || e.touches[0].pageY
    const x = (offsetX / zoomer.offsetWidth) * 100
    const y = (offsetY / zoomer.offsetHeight) * 100
    zoomer.style.backgroundPosition = `${x}% ${y}%`
  }
}
