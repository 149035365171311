import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["superCheckbox", "superConfig"]

  connect() {
    if (this.superCheckboxTarget.checked) {
      this.superConfigTarget.classList.remove("hidden")
    }
  }

  toggleSuper(e) {
    if (e.target.checked) {
      this.superConfigTarget.classList.remove("hidden")
    } else {
      this.superConfigTarget.classList.add("hidden")
    }
  }
}
