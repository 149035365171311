import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "loanPlanFields",
    "loanPlanCheckboxOne",
    "loanPlanCheckboxTwo",
    "loanPlanCheckboxThree",
    "loanPlanCheckboxFour",
    "loanPlanCheckboxFive",
  ]
  static values = { loanPlans: Array }

  connect() {
    this.reapplyState()

    document.addEventListener("turbo:load", () => {
      if (this.hasLoanPlanFieldsTarget) {
        this.reapplyState()
      }
    })
  }

  reapplyState() {
    if (this.loanPlansValue.length > 0) {
      this.loanPlanFieldsTarget.classList.remove("hidden")
    }
    this.setCheckbox()
  }

  toggle(event) {
    if (event.target.checked) {
      this.loanPlanFieldsTarget.classList.remove("hidden")
      this.setCheckbox()
    } else {
      this.loanPlanFieldsTarget.classList.add("hidden")
      this.loanPlanCheckboxOneTarget.checked = false
      this.loanPlanCheckboxTwoTarget.checked = false
      this.loanPlanCheckboxThreeTarget.checked = false
      this.loanPlanCheckboxFourTarget.checked = false
      this.loanPlanCheckboxFiveTarget.checked = false
    }
  }

  setCheckbox() {
    this.loanPlansValue.forEach((loanPlan) => {
      if (loanPlan === "uk_loan_plan_1") {
        this.loanPlanCheckboxOneTarget.checked = true
      } else if (loanPlan === "uk_loan_plan_2") {
        this.loanPlanCheckboxTwoTarget.checked = true
      } else if (loanPlan === "uk_loan_plan_3") {
        this.loanPlanCheckboxThreeTarget.checked = true
      } else if (loanPlan === "uk_loan_plan_4") {
        this.loanPlanCheckboxFourTarget.checked = true
      } else if (loanPlan === "uk_loan_plan_5") {
        this.loanPlanCheckboxFiveTarget.checked = true
      }
    })
  }
}
