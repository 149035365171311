import { Controller } from "@hotwired/stimulus"
// you can implement this controller  at the top of your dom element and
// then assign multiple targets and use data-reload-frame-id-param: "id" with the action you want
// to use to specify the id of the frame you want to reload
export default class extends Controller {
  // You may be tempted to make this "more clever" using targets and adding extra stuff,
  // please don't. Targetting using getElementById always works, but if we use a target it
  // might not exist if it's lazy loaded.

  reload(event) {
    const id = event.params.id
    document.getElementById(id).reload()
  }
}
