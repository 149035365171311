import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // This controller handles navigation and contract completion
  // for **all** user contracts on the page

  static targets = [
    "contractsSection",
    "nonContractsSection",
    "hiddenUpdateContractsButton",
    "reviewContractsButton",
    "reviewContractsSpinner",
    "contractContent",
    "contractComplete",
    "contractProgressBar",
    "prevButton",
    "nextButton",
    "finishButton",
    "confirmModalButton",
  ]
  static values = { currentIndex: Number }

  connect() {
    this.currentIndexValue = 0
    this.#updateContractProgressBar()
    this.initialiseContractsModal(null)
  }

  toggleContractsSection() {
    if (this.contractsSectionTarget.classList.contains("hidden")) {
      this.#toggleContractsSectionAttributes(true)
      this.contractsSectionTarget.scrollIntoView({ block: "center" })
    } else {
      this.nonContractsSectionTarget.scrollIntoView({ block: "start" })

      // not all browsers support scrollend event
      if (document.onscrollend !== undefined) {
        document.addEventListener(
          "scrollend",
          () => {
            this.#toggleContractsSectionAttributes(false)
          },
          { once: true }
        )
      } else {
        this.#toggleContractsSectionAttributes(false)
      }
    }
  }

  updateSelectedContracts() {
    this.hiddenUpdateContractsButtonTarget.click()
  }

  disableReviewContractsButton() {
    this.confirmModalButtonTarget.classList.add("hidden")
    if (this.hasReviewContractsButtonTarget) this.reviewContractsButtonTarget.classList.remove("hidden")
    if (this.hasReviewContractsButtonTarget) this.reviewContractsButtonTarget.disabled = true
    this.reviewContractsSpinnerTarget.classList.remove("hidden")
  }

  initialiseContractsModal(event) {
    const contractsLength = !this.hasHiddenUpdateContractsButtonTarget
      ? this.contractContentTargets.length
      : new FormData(this.hiddenUpdateContractsButtonTarget.form)
          .getAll("employment_condition_set[contract_templates][]")
          .filter((contract) => contract !== "").length

    this.reviewContractsSpinnerTarget.classList.add("hidden")

    if (!contractsLength) {
      if (this.hasReviewContractsButtonTarget) this.reviewContractsButtonTarget.disabled = true
    } else if (contractsLength === 1) {
      if (this.hasReviewContractsButtonTarget) this.reviewContractsButtonTarget.disabled = false

      // previous and next buttons are hidden by default for a single contract
      this.#toggleContractPaginationButtons(false, false, true)
    } else {
      if (this.hasReviewContractsButtonTarget) this.reviewContractsButtonTarget.disabled = false

      this.#toggleContractPaginationButtons(false, true, false)
    }

    this.#updateContractProgressBar()
    this.currentIndexValue = 0
    this.#toggleContractContents(0)
  }

  nextContract() {
    if (this.currentIndexValue < this.contractContentTargets.length - 1) {
      this.currentIndexValue++

      if (this.currentIndexValue === this.contractContentTargets.length - 1) {
        this.#toggleContractPaginationButtons(true, false, true)
      } else {
        this.#toggleContractPaginationButtons(true, true, false)
      }

      this.#toggleContractContents(this.currentIndexValue)
      this.#updateContractProgressBar()
    }
  }

  prevContract() {
    if (this.currentIndexValue > 0) {
      this.currentIndexValue--

      if (this.currentIndexValue === 0) {
        this.#toggleContractPaginationButtons(false, true, false)
      } else {
        this.#toggleContractPaginationButtons(true, true, false)
      }

      this.#toggleContractContents(this.currentIndexValue)
      this.#updateContractProgressBar()
    }
  }

  finish() {
    this.#updateContractProgressBar()

    if (this.#completedContractsLength() === this.contractCompleteTargets.length) {
      this.confirmModalButtonTarget.classList.remove("hidden")
      if (this.hasReviewContractsButtonTarget) this.reviewContractsButtonTarget.classList.add("hidden")
    }
  }

  #toggleContractContents(index) {
    this.contractContentTargets.forEach((content, i) => {
      if (i === index) {
        content.style.display = "grid"
      } else {
        content.style.display = "none"
      }
    })
  }

  #toggleContractsSectionAttributes(contractsSectionOpen) {
    this.nonContractsSectionTarget.querySelectorAll("button").forEach((button) => {
      button.disabled = contractsSectionOpen
    })

    this.nonContractsSectionTarget.querySelectorAll("input", "select").forEach((input) => {
      input.readOnly = contractsSectionOpen
    })

    this.nonContractsSectionTarget.querySelectorAll("a[class*='btn']").forEach((a) => {
      a.classList.toggle("hidden", contractsSectionOpen)
    })
    this.nonContractsSectionTarget.classList.toggle("bg-gray-50", contractsSectionOpen)
    this.contractsSectionTarget.classList.toggle("hidden", !contractsSectionOpen)
    this.contractsSectionTarget.disabled = !contractsSectionOpen

    if (this.hasReviewContractsButtonTarget) {
      this.reviewContractsButtonTarget.classList.toggle("hidden", !contractsSectionOpen)
    }
    this.confirmModalButtonTarget.classList.toggle("hidden", contractsSectionOpen)

    if (contractsSectionOpen) this.updateSelectedContracts()
    this.#updateContractProgressBar()
  }

  #toggleContractPaginationButtons(prevVisible, nextVisible, finishVisible) {
    this.prevButtonTarget.classList.toggle("hidden", !prevVisible)
    this.nextButtonTarget.classList.toggle("hidden", !nextVisible)
    if (this.hasFinishButtonTarget) this.finishButtonTarget.classList.toggle("hidden", !finishVisible)
  }

  #updateContractProgressBar() {
    const max = this.contractCompleteTargets.length
    const value = this.#completedContractsLength()
    const shouldHide = value === 0 && max === 0

    this.contractProgressBarTargets.forEach((progressBar) => {
      const label = progressBar.parentElement

      if (shouldHide) {
        label.classList.add("hidden")
      } else {
        progressBar.max = max
        progressBar.value = value
        label.classList.remove("hidden")
        // no user entered text here (hopefully)
        // eslint-disable-next-line no-unsanitized/property
        label.innerHTML = label.innerHTML.replace(/\d+\/\d+/, `${value}/${max}`)
      }
    })
  }

  #completedContractsLength() {
    return this.contractCompleteTargets.filter((contract) => contract.value === "true").length
  }
}
