import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["leaveModal", "warning"]
  static values = {
    id: String,
    status: String,
  }

  connect() {
    if (this.statusValue === "pending") {
      document.addEventListener(`${this.idValue}_leave_modal-modalClosePrevented`, (event) => {
        this.focusBreakdownTab()
        this.emphasiseWarning()
      })
    }
  }

  focusBreakdownTab() {
    const tabs = [...this.element.querySelectorAll(".leave-modal-nav button")]
    const tabContent = [...this.element.querySelectorAll(".leave-modal-tab-content")]
    const breakdownTab = this.element.querySelector(".leave-modal-tab-content#breakdown")
    const breakdownTabButton = this.element.querySelector(".leave-modal-nav button[data-id='breakdown']")

    tabs.forEach((tab) => {
      tab.classList.remove("active", "bg-white", "ring-1")
    })
    tabContent.forEach((content) => {
      content.classList.add("hidden")
    })
    breakdownTab.classList.remove("hidden")
    breakdownTabButton.classList.add("active", "bg-white", "ring-1")
  }

  emphasiseWarning() {
    this.warningTarget.classList.add("animate-bounce", "animate__fastest")
    setTimeout(() => {
      this.warningTarget.classList.remove("animate-bounce", "animate__fastest")
    }, 500)
  }

  discardChanges() {
    this.leaveModalTarget.setAttribute("backdropClose", true)
    this.leaveModalTarget.close()
    this.warningTarget.classList.add("hidden")
    const leaveTurboFrame = document.querySelector(`#leave_request_${this.idValue}`)
    leaveTurboFrame.reload()
  }

  logBreakdownUpdated() {
    if (this.statusValue === "pending") {
      this.leaveModalTarget.setAttribute("backdropClose", false)
      this.warningTarget.classList.remove("hidden")
    }
  }
}
