import { Controller } from "@hotwired/stimulus"
import { t as globalT } from "helpers/i18n"

export default class extends Controller {
  static targets = ["leaveType", "lengthTitle", "leaveInDays"]

  connect() {
    this.leaveTypeChanged()
  }

  leaveTypeChanged() {
    const selectedOption = this.leaveTypeTarget.options[this.leaveTypeTarget.selectedIndex]
    const leaveInDays = selectedOption.dataset.leaveInDays
    const label = this.lengthTitleTarget.querySelector("label")

    label.textContent =
      leaveInDays === "true"
        ? globalT("js.users.new_user_profile.leave_balance.days")
        : globalT("js.users.new_user_profile.leave_balance.hours")

    this.leaveInDaysTarget.value = leaveInDays
  }
}
