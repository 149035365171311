import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]

  toggle() {
    this.contentTarget.disabled = !this.contentTarget.disabled
  }

  enable() {
    this.contentTarget.disabled = false
  }

  disable() {
    this.contentTarget.disabled = true
  }
}
