import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["editName", "textToHide"]

  onEditClick(event) {
    this.editNameTarget.classList.remove("hidden")
    this.textToHideTarget.classList.add("hidden")

    this.editNameTarget.focus()
  }
}
