import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "conditional", "helperText"]

  connect() {
    this.updateConditionalTargets()
  }

  anyChecked() {
    return this.checkboxTargets.some((checkbox) => checkbox.checked)
  }

  stayChecked(event) {
    if (!this.anyChecked()) {
      event.target.checked = true
      this.helperTextTarget.classList.remove("text-gray-600")
      this.helperTextTarget.classList.add("text-red-600")
      setTimeout(() => {
        this.helperTextTarget.classList.add("text-gray-600")
        this.helperTextTarget.classList.remove("text-red-600")
      }, 1500)
    }
  }

  updateConditionalTargets() {
    for (const checkbox of this.checkboxTargets) {
      const conditionalTarget = this.conditionalTargets.find((element) => element.dataset.id === checkbox.id)
      if (!conditionalTarget) continue

      conditionalTarget.disabled = !checkbox.checked
    }
  }
}
