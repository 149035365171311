/* @flow */

import ordinal from "ordinal-js"

const I18n = window.I18n

const with_ordinals = (scope: string, value: number | string | Date, options: { [string]: mixed } = {}): string => {
  if (scope.indexOf("ordinal") > -1) {
    const d = I18n.parseDate(value)
    options.ordinal = options.ordinal || ordinal.toOrdinal(d.getDate())
  }

  return I18n.l(scope, value, options)
}

export const translate: (key: string, values: mixed) => string = I18n.translate.bind(I18n)
export const localize = with_ordinals
export const pluralize: (str: string) => string = I18n.pluralize.bind(I18n)
export const l = with_ordinals
export const p: string = I18n.p.bind(I18n)
export const t = (key: string, values: mixed = {}): string =>
  I18n.translate(key, Object.assign({}, I18n.defaults, values))

// patch I18n with our upgraded t function
Object.assign(I18n, { t })

export default I18n
