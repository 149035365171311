import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  static targets = [
    "radioButton",
    "formSubmit",
    "overlappingTagsWarning",
    "everyoneCheckbox",
    "leaveAveragingCheckbox",
    "leaveViewInDaysCheckbox",
    "automateBehaviourCheckbox",
    "configureBehaviourCheckbox",
    "automateContent",
    "configureContent",
  ]

  static values = { url: String }

  toggleRadioButton(e) {
    const radioGroupButtons = this.radioButtonTargets.filter(
      (checkbox) => checkbox.dataset.radioGroup === e.target.dataset.radioGroup
    )

    radioGroupButtons.forEach((checkbox) => {
      const hiddenField = document.getElementById("hidden_" + checkbox.value)
      if (hiddenField) hiddenField.value = checkbox.checked
      const hiddenChildren = document.querySelectorAll("div#" + checkbox.dataset.id)
      hiddenChildren.forEach((child) => {
        child.style.display = child.id !== e.target.dataset.id ? "none" : ""
      })
    })
  }

  preventSubmit() {
    const shouldDisable = this.everyoneCheckboxTarget.value === "false" && this.overlapInAwardTags()

    this.overlappingTagsWarningTarget.style.display = this.overlapInAwardTags() ? "" : "none"
    this.formSubmitTarget.disabled = shouldDisable
  }

  overlapInAwardTags() {
    const selectedPositiveTags = this.tagNamesAndValues(document.getElementsByName("award[tags][]"))
    const selectedNegativeTags = this.tagNamesAndValues(document.getElementsByName("award[negative_tags][]"))

    return selectedPositiveTags.filter((tag) => selectedNegativeTags.includes(tag)).length !== 0
  }

  tagNamesAndValues(tags) {
    return Object.values(tags)
      .map((tag) => {
        if (tag.checked) return tag.value
      })
      .filter((name) => name)
  }

  disableLeaveViewInDays() {
    if (this.leaveAveragingCheckboxTarget && this.leaveViewInDaysCheckboxTarget) {
      if (this.leaveAveragingCheckboxTarget.checked) {
        this.leaveViewInDaysCheckboxTarget.checked = false
        this.leaveViewInDaysCheckboxTarget.disabled = true
        this.leaveViewInDaysCheckboxTarget.style.display = "none"
      } else {
        this.leaveViewInDaysCheckboxTarget.disabled = false
      }
    }
  }

  replacePayrollLeaveTypeInfo(event) {
    const included_in_list = Array.from(event.target.list.options).some((option) => option.value === event.target.value)
    if (!included_in_list) return

    fetch(`${this.urlValue}?award[export_name]=${encodeURIComponent(event.target.value)}`, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html)
      })
  }

  toggleAutomate() {
    if (this.automateBehaviourCheckboxTarget.checked) {
      this.configureBehaviourCheckboxTarget.checked = false
      this.configureContentTarget.style.display = "none"
    }
  }

  toggleConfigure() {
    if (this.configureBehaviourCheckboxTarget.checked) {
      this.automateBehaviourCheckboxTarget.checked = false
      this.automateContentTarget.style.display = "none"
    }
  }
}
