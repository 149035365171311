import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]

  apply({ params: { attributes } }) {
    if (this.hasElementTarget) {
      for (const [name, value] of Object.entries(attributes)) {
        this.elementTarget.setAttribute(name, JSON.stringify(value))
      }
    }
  }
}
