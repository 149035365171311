import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["successCheck", "failCheck", "score", "wrapper", "passIcon", "failIcon", "text"]

  get failCount() {
    return this.failCheckTargets.length
  }

  get successCount() {
    return this.successCheckTargets.length
  }

  get totalCount() {
    return this.failCount + this.successCount
  }

  connect() {
    this.scoreTarget.textContent = `${this.successCount}/${this.totalCount}`
    if (this.successCount < this.totalCount) {
      this.wrapperTarget.classList.add("bg-red-100")
      this.textTargets.forEach((target) => {
        target.classList.add("text-red-800")
      })
      this.failIconTarget.classList.remove("hidden")
    } else {
      this.wrapperTarget.classList.add("bg-green-100")
      this.textTargets.forEach((target) => {
        target.classList.add("text-green-800")
      })
      this.passIconTarget.classList.remove("hidden")
    }
  }
}
