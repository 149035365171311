import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  visit(event) {
    const path = event.target.value
    if (path) {
      Turbo.visit(path)
    }
  }
}
