import { Controller } from "@hotwired/stimulus"
import Request from "helpers/request"

// the IntersectionObserver stuff is based on https://github.com/hotwired/turbo/blob/89be8e4c206ea877183b7ac4a89f898363a14f0f/src/observers/appearance_observer.js
// if there's more use cases for it, make this more generic
export default class extends Controller {
  static values = { url: String }

  connect() {
    this.intersectionObserver = new IntersectionObserver(this.intersect)

    if (!this.started) {
      this.started = true
      this.intersectionObserver.observe(this.element)
    }
  }

  disconnect() {
    if (this.started) {
      this.started = false
      this.intersectionObserver.unobserve(this.element)
    }
  }

  markAsSeen() {
    Request.post(this.urlValue)
    this.disconnect()
  }

  intersect = (entries) => {
    const lastEntry = entries.slice(-1)[0]
    if (lastEntry?.isIntersecting) {
      this.markAsSeen()
    }
  }
}
