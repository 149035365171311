import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "picker"]
  static classes = ["show", "hide", "active"]

  show() {
    this.contentTarget.classList.remove(this.hideClasses)
    this.contentTarget.classList.add(this.showClasses)
  }

  hide() {
    this.contentTarget.classList.remove(this.showClasses)
    this.contentTarget.classList.add(this.hideClasses)
  }

  toggleOn(event) {
    this.contentTargets.forEach((t) => t.classList.add(...this.hideClasses))
    document.getElementById(event.params.toggle).classList.remove(...this.hideClasses)
    document.getElementById(event.params.toggle).classList.add(...this.showClasses)
  }

  toggleAll(event) {
    if (event.target.checked) {
      this.contentTargets.forEach((t) => t.classList.add("hidden"))
    } else if (!event.target.checked) {
      this.contentTargets.forEach((t) => t.classList.remove("hidden"))
    }
  }

  toggle(event) {
    this.pickerTargets.forEach((t) => t.classList.remove(...this.activeClasses))
    event.currentTarget.classList.add(...this.activeClasses)

    if (event.params.show) {
      this.show()
    } else {
      this.hide()
    }
  }
}
