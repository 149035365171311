import { Controller } from "@hotwired/stimulus"

const BASE_WIDTH = 640 // Width of the canvas when scale is 1
const ASPECT_RATIO = 3
const FINAL_RENDER_SCALE = 3 // Scale to render the final image at

/**
 * @interface PathPoint
 * @property {[number, number]} point
 */

/**
 * @interface Segment
 * @property {[number,  number]} from
 * @property {[number,  number]} to
 */

export default class extends Controller {
  static classes = ["image"]
  static targets = ["canvas", "canvasWrapper", "fileField", "signedPill", "unsavedPill", "saveButton"]
  static values = {
    submit: Boolean,
  }

  /**
   * @type {PathPoint[][]}
   */
  paths = []

  /**
   * @type {Segment[]}
   */
  path = []

  ctx = this.canvasTarget.getContext("2d")

  wrapperWidth = this.canvasWrapperTarget.clientWidth

  // Pointer ID -> PathPoint[]
  pointerPaths = new Map()

  get isDarkMode() {
    return localStorage.getItem("DARK_MODE") === "1"
  }

  get scale() {
    const canvasWidth = this.wrapperWidth * window.devicePixelRatio
    return canvasWidth / BASE_WIDTH
  }

  get backgroundColour() {
    return this.isDarkMode ? "#10212d" : "white"
  }

  get foregroundColour() {
    return this.isDarkMode ? "white" : "#222B35"
  }

  #updateCanvasSize = () => {
    this.canvasTarget.width = this.wrapperWidth * window.devicePixelRatio
    this.canvasTarget.height = (this.wrapperWidth * window.devicePixelRatio) / ASPECT_RATIO

    this.#render()
  }

  connect() {
    this.#setupCanvas(this.ctx)

    const updateCanvasSize = (width) => {
      this.wrapperWidth = width
      this.#updateCanvasSize()
    }

    if (typeof ResizeObserver !== "undefined") {
      this.resizeObserver = new ResizeObserver(([entry]) => {
        updateCanvasSize(entry.contentRect.width)
      })
    }

    updateCanvasSize(this.canvasWrapperTarget.clientWidth)

    this.resizeObserver?.observe(this.canvasWrapperTarget)
    window.addEventListener("resize", this.#updateCanvasSize)
  }

  disconnect() {
    this.resizeObserver?.disconnect()
    window.removeEventListener("resize", this.#updateCanvasSize)
  }

  handlePointerDown(e) {
    e.preventDefault()
    // can't do this in touch events
    e.target.setPointerCapture(e.pointerId)

    // Touch is handled by handleTouchStart
    if (e.pointerType === "touch") return
    const path = [
      {
        point: this.getCursorPosition(e),
      },
    ]

    this.pointerPaths.set(e.pointerId, path)
  }

  handleTouchStart(e) {
    e.preventDefault()

    const { top: clientOffsetY, left: clientOffsetX } = this.canvasTarget.getBoundingClientRect()

    for (const touch of e.changedTouches) {
      const position = {
        offsetX: touch.clientX - clientOffsetX,
        offsetY: touch.clientY - clientOffsetY,
      }

      const path = [
        {
          point: this.getCursorPosition(position),
        },
      ]

      this.pointerPaths.set(touch.identifier, path)
    }
  }

  #endPath(id) {
    const pointerPath = this.pointerPaths.get(id)
    if (!pointerPath) return

    this.pointerPaths.delete(id)
    this.paths.push(pointerPath)

    if (pointerPath.length === 1) {
      // Add a random point a small distance away so a small line can be drawn
      pointerPath.push({
        point: [pointerPath[0].point[0] + Math.random() * 2 - 1, pointerPath[0].point[1] + Math.random() * 2 - 1],
      })
      this.updateAndRender()
    }
  }

  handlePointerUp(e) {
    e.preventDefault()

    // Touch is handled by handleTouchEnd
    if (e.pointerType === "touch") return

    this.#endPath(e.pointerId)
  }

  handleTouchEnd(e) {
    e.preventDefault()

    for (const touch of e.changedTouches) {
      this.#endPath(touch.identifier)
    }
  }

  handlePointerCancel(e) {
    this.pointerPaths.delete(e.pointerId)

    this.updateAndRender()
  }

  handlePointerMove(e) {
    e.preventDefault()

    // Touch is handled by handleTouchMove
    if (e.pointerType === "touch") return

    const pointerPath = this.pointerPaths.get(e.pointerId)
    if (!pointerPath) return

    const coalescedEvents = e.getCoalescedEvents ? e.getCoalescedEvents() : [e]
    const predictedEvent = e.getPredictedEvents?.().at(-1)

    for (const event of coalescedEvents) {
      this.#updatePath(event, pointerPath)
    }

    if (predictedEvent) {
      // Render the predicted event until the next event is received
      const tempPath = pointerPath.slice()
      this.#updatePath(predictedEvent, tempPath)
      this.updateAndRender()
      this.#updatePath(e, pointerPath)
    } else {
      this.updateAndRender()
    }
  }

  handleTouchMove(e) {
    e.preventDefault()

    const { top: clientOffsetY, left: clientOffsetX } = this.canvasTarget.getBoundingClientRect()

    for (const touch of e.changedTouches) {
      const pointerPath = this.pointerPaths.get(touch.identifier)
      if (!pointerPath) continue

      const position = { offsetX: touch.clientX - clientOffsetX, offsetY: touch.clientY - clientOffsetY }
      this.#updatePath(position, pointerPath)
    }

    this.updateAndRender()
  }

  clearCanvas() {
    if (this.canvasTarget.classList.contains("hidden")) {
      this.canvasTarget.classList.remove("hidden")
    }

    this.ctx.fillStyle = this.backgroundColour
    this.ctx.fillRect(0, 0, this.canvasTarget.width, this.canvasTarget.height)

    this.signedPillTarget.classList.add("hidden")
    this.unsavedPillTarget.classList.remove("hidden")
    this.saveButtonTarget.classList.remove("hidden")

    URL.revokeObjectURL(this.signatureImage?.src)
    this.signatureImage?.remove()
    this.fileFieldTarget.value = ""

    this.paths = []
    this.#calculateRenderingPath()
  }

  async save() {
    // if we have no paths, we shouldn't save
    if (this.paths.length === 0) {
      this.fileFieldTarget.reportValidity()
      return
    }

    const blob = await this.#getSignatureBlob()
    const file = new File([blob], "signature.png", { type: "image/png" })
    this.fileFieldTarget.files = this.#getFileList(file)

    this.signatureImage = new Image(this.canvasTarget.width, this.canvasTarget.height)
    this.signatureImage.classList.add("dark:invert")
    this.signatureImage.src = URL.createObjectURL(file)
    this.signatureImage.classList.add(...this.imageClasses)
    this.canvasWrapperTarget.appendChild(this.signatureImage)

    this.canvasTarget.classList.add("hidden")

    this.saveButtonTarget.classList.add("hidden")
    this.signedPillTarget.classList.remove("hidden")
    this.unsavedPillTarget.classList.add("hidden")

    if (this.submitValue) {
      this.fileFieldTarget.form.requestSubmit()
    }
  }

  getCursorPosition(e) {
    const positionX = (e.offsetX / this.scale) * window.devicePixelRatio
    const positionY = (e.offsetY / this.scale) * window.devicePixelRatio
    return [positionX, positionY]
  }

  updateAndRender() {
    this.#calculateRenderingPath()
    this.#render()
  }

  #setupCanvas(ctx, scale = this.scale) {
    ctx.lineCap = "round"
    ctx.lineJoin = "round"
    ctx.lineWidth = 3 * scale
  }

  /**
   * Appends the mouse position to the provided path in place
   *
   * `e` must have `offsetX` and `offsetY` properties
   */
  #updatePath(e, path) {
    const mousePos = this.getCursorPosition(e)

    path.push({
      point: mousePos,
    })
  }

  #calculateRenderingPath() {
    this.path = [...this.paths, ...this.pointerPaths.values()].flatMap(pointsToSegments)
  }

  #renderTo(ctx, scale, blackOnTransparent = false) {
    if (blackOnTransparent) {
      ctx.strokeStyle = "black"

      ctx.clearRect(0, 0, BASE_WIDTH * scale, (BASE_WIDTH / ASPECT_RATIO) * scale)
    } else {
      ctx.fillStyle = this.backgroundColour
      ctx.strokeStyle = this.foregroundColour

      ctx.fillRect(0, 0, BASE_WIDTH * scale, (BASE_WIDTH / ASPECT_RATIO) * scale)
    }

    for (const segment of this.path) {
      ctx.beginPath()
      ctx.moveTo(segment.from[0] * scale, segment.from[1] * scale)
      ctx.lineTo(segment.to[0] * scale, segment.to[1] * scale)
      ctx.stroke()
    }
  }

  #render() {
    this.#setupCanvas(this.ctx)
    this.#renderTo(this.ctx, this.scale)
  }

  #getSignatureBlob() {
    return renderBlob(BASE_WIDTH * FINAL_RENDER_SCALE, (BASE_WIDTH / ASPECT_RATIO) * FINAL_RENDER_SCALE, (canvas) => {
      const ctx = canvas.getContext("2d")
      this.#setupCanvas(ctx, FINAL_RENDER_SCALE)
      this.#renderTo(ctx, FINAL_RENDER_SCALE, true)
    })
  }

  #getFileList(...files) {
    const dataTransfer = new DataTransfer()
    files.forEach((file) => dataTransfer.items.add(file))
    return dataTransfer.files
  }
}

function renderBlob(width, height, render) {
  if (typeof OffscreenCanvas === "undefined") {
    const canvas = document.createElement("canvas")
    canvas.width = width
    canvas.height = height
    render(canvas)
    return new Promise((resolve) => canvas.toBlob(resolve))
  } else {
    const canvas = new OffscreenCanvas(width, height)
    render(canvas)
    return canvas.convertToBlob({ type: "image/png" })
  }
}

function pointsToSegments(points) {
  const segments = []

  for (let i = 0; i < points.length - 1; i++) {
    segments.push({
      from: points[i].point,
      to: points[i + 1].point,
    })
  }

  return segments
}
