import { Controller } from "@hotwired/stimulus"

/*
 * This controller may be used to automatically recalculate a tax rate from one
 * or more component rates.
 *
 * This target use case is for tax profiles which store a SUI tax rate that is
 * broken down into a base rate and one or more auxiliary fees.
 */
export default class extends Controller {
  static targets = ["componentRate", "totalRate"]
  static values = { precision: { type: Number, default: 2 } }

  recalculate() {
    const initialValue = 0
    const totalRate = this.componentRateTargets.reduce((sum, input) => sum + parseFloat(input.value), initialValue)

    this.totalRateTarget.value = totalRate.toFixed(this.precisionValue)
  }
}
